<template lang="pug">
viz-card(
  title="Layers"
  :style="{minHeight: '120px'}"
  :close-button="mq.lgMinus"
  @closeCard="$store.commit('activeProject/closeMobileTab')"
  :scroll-top="cardScrollTop"
)
  .layer-wrap(
    v-for="(material, materialIndex) in activeVariation.scene.materials"
    :key="material.id"
  )
    viz-layer(
      v-for="(layer, layerIndex) in material.layers"
      :key="layer.id"
      :is-active="(layerIndex === activeLayerIndex) && (materialIndex === activeMaterialIndex)"
      :material="material"
      :material-index="materialIndex"
      :layer="layer"
      :layer-index="layerIndex"
      @changeActiveLayerTop="changeActiveLayerTop"
    )
</template>

<script>
import VizCard from './vizCard'
import VizLayer from './vizLayer'
export default {
  name: 'vizLayers',

  inject: ["mq"],

  components: { VizLayer, VizCard },

  data () {
    return {
      cardScrollTop: 0
    }
  },

  computed: {
    activeVariation () {
      return this.$store.state.activeProject.activeVariation
    },
    material () {
      return this.$store.state.activeProject.activeMaterial
    },
    activeMaterialIndex () {
      return this.activeVariation.activeMaterialIndex
    },
    activeLayerIndex () {
      return this.material.activeLayerIndex
    }
  },

  methods: {
    changeActiveLayerTop (topPosition) {
      this.cardScrollTop = topPosition
    }
  }
}
</script>

<style lang="scss" scoped>
  .layer-wrap:first-child {
    .layer:first-child {
      margin-top: 0;
    }
  }
</style>
