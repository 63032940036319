<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="project-unsaved"
  hide-close-icon
  @modal-close="next(false)"
)
  template(
    #default
  )
    div Do you want to save your duck?

  template(
    #buttons
  )
    base-button(
      color="white-outline"
      @click="onClose"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Don’t save
    base-button(
      @click="onSave"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Save
</template>

<script>
import BaseButton from '@/components/BaseButton'
import StandardModal from '@/components/modals/StandardModal'
export default {
  name: "HasUnsavedChanges",

  inject: ["mq"],
  components: { StandardModal, BaseButton },

  props: {
    next: Function
  },

  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    onSave () {
      this.$store.dispatch('activeProject/saveProject')
      this.next()
    },
    onClose () {
      this.next()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
