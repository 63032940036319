export const setRoughnessTexture = function(materialId, layerId, value){

  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId);

  material
    .layers[Number(layerId)]
    .settings
    .texture
    .roughnessTexture.value.value = Number(value);

};
