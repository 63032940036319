<template lang="pug">
TheHeader(
  :isLoggedIn="isLoggedIn"
  v-if="!((onPlayerPage && !mq.xlPlus) || onAboutPage)"
)

#content.d-flex.flex-column.content(
  :class="{'content_player-page': onPlayerPage, 'content_about-page': onAboutPage}"
)
  .content__inner
    router-view

TheFooter(
  v-if="!onPlayerPage || mq.xlPlus"
  :dark="onAboutPage"
)
join-now-modal
publish-project
delete-project
reject-project
modals-container
mobile-how-create
unable-to-create
user-old-browser
ducks-limit
</template>

<script>
import TheHeader from './components/TheHeader'
import TheFooter from './components/TheFooter'
import JoinNowModal from "@/components/modals/JoinNowModal";
import PublishProject from "@/components/modals/PublishProject";
import DeleteProject from "@/components/modals/DeleteProject";
import RejectProject from "@/components/modals/RejectProject";
import MobileHowCreate from "@/components/modals/MobileHowCreate";
import UnableToCreate from "@/components/modals/UnableToCreate";
import DucksLimit from "@/components/modals/DucksLimit";
import UserOldBrowser from "@/components/modals/UserOldBrowser";

export default {
  name: 'App',

  inject: ["mq"],

  components: {
    UserOldBrowser,
    DucksLimit,
    UnableToCreate,
    MobileHowCreate,
    RejectProject,
    DeleteProject,
    PublishProject,
    JoinNowModal,
    TheHeader,
    TheFooter
  },
  data: () => ({
  }),
  computed: {
    isLoggedIn: function(){
      return this.$store.getters['auth/isAuthenticated']
    },
    onPlayerPage () {
      return this.$route.matched[0]?.components?.default?.name === 'VizPlayer'
    },
    onAboutPage () {
      return this.$route.matched[0]?.components?.default?.name === 'About'
    }
  },
  // inject: ['$viz3d'],
  created () {
    if (this.isLoggedIn) {
      if (!this.$cookie.getCookie('userToken')) {
        this.$store.dispatch('auth/LogOut')
      } else {
        this.$store.dispatch('auth/updateUser')

        setInterval(() => {
          this.$store.dispatch('auth/updateUser')
        }, 600000)
      }
    }
  }
}
</script>
<style lang="scss">
  @import 'assets/styles/app.scss';
</style>
