<template lang="pug">
viz-aside-cards-wrap
  viz-author
  viz-likes
  viz-card(
    title="Share"
  )
    viz-share
    .project-link
      copy-current-link(
        @on-copy-url="onCopy"
      )
        base-button.project-link__btn(
          color="gray"
          width="100%"
        ) {{ copyUrlText }}
</template>

<script>
import VizAsideCardsWrap from './vizAsideCardsWrap'
import VizAuthor from './vizAuthor'
import VizLikes from './vizLikes'
import VizShare from '@/components/player/vizShare'
import VizCard from "@/components/player/vizCard";
import BaseButton from "@/components/BaseButton";
import CopyCurrentLink from "@/components/CopyCurrentLink";
export default {
  name: 'vizViewPlayerSettings',
  components: {CopyCurrentLink, BaseButton, VizCard, VizShare, VizLikes, VizAuthor, VizAsideCardsWrap },

  data () {
    return {
      copyUrlText: 'Or copy link'
    }
  },

  methods: {
    onCopy () {
      this.copyUrlText = 'Done'
      setTimeout(() => {
        this.copyUrlText = 'Or copy link'
      }, 2000)
      
      // Hubspot track
      window._hsq = window._hsq || []; 
      _hsq.push(['trackCustomBehavioralEvent', {name: 'pe20302049_shared_duck', 
        properties: { 
          yi_timestamp: new Date().toISOString().split('T')[0],
          VID: this.$store.getters['auth/isAuthenticated'] ? this.$store.getters['auth/user'].yid : '',
          duck_url: location.href,
          social_media_platform: 'copy_link'
        }
      }]);
    }
  }
}
</script>

<style lang="scss" scoped>
.project-link {
  margin-top: 16px;
  &__btn {
    width: 100%;
  }
}
</style>
