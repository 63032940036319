<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="old-browser"
  @modalClose="isOpen = false"
)
  h2.standard-modal__title Update your browser
  div This page only supports Safari versions 14 or higher. Please update your browser or switch to another browser to display this page.

  template(
    #buttons
  )
    base-button(
      @click="isOpen = false"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Ok
</template>

<script>
import StandardModal from "@/components/modals/StandardModal";
import BaseButton from "@/components/BaseButton";
export default {
  name: "UserOldBrowser",
  inject: ["mq"],
  components: {BaseButton, StandardModal},
  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
