<template lang="pug">
vue-sticky(
  :offset="20"
)
  .container
    the-navigation.navigation-about(
      dark
    )
section.hero-block.section-block
  .container
    h1.hero-block__title Stand out with <br/>your duck design!
    .hero-block__subtitle Design your own duck for the chance <br/>to win an iPad Pro, an iPad Air, or a one-year Yellow Images membership!

  infinite-slide-bar.infinity-ducks(
    duration="70s"
  )
    .infinity-ducks__row
      .infinity-ducks__card(
        v-for="duck in ducks"
        :key="duck"
      )
        img.infinity-ducks__img(
          :src='duck.src'
          :srcset='duck.srcset'
        )

.container
  section.section-block.video-block
    h2.title-h2(
      data-aos="fade-in"
    ) How does it work?
    .video-block__subtitle(
      data-aos="fade-in"
    ) Use our innovative 3D Visualizer tool to <span class="video-block__subtitle-thin">create your own unique</span> <span class="video-block__subtitle-border">3D duck model</span> with ease.
    .video-block__video
      iframe(
        width="560"
        height="315"
        src="https://www.youtube.com/embed/gije_n7FWyY?controls=0&disablekb=1&autoplay=1&fs=0&playlist=gije_n7FWyY&loop=1&modestbranding=1&mute=1"
        title="How does it work?"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      )

  section.section-block.rules
    h2.title-h2(
      data-aos="fade-in"
    ) Ready to win your iPad Pro? <br/>Here are the rules:

    Flicking.row.row_scroller.rules__list(
      :options="{ bound: true, align: 'prev', bounce: 0 }"
      data-aos="fade-in"
      ref="rules"
      @wheel="onWheel($event, this.$refs.rules)"
    )
      .col-auto(
        v-for="(rule, ruleIndex) in rules"
        :key="ruleIndex"
      )
        .rules__item
          h3.rules__title(
            v-html="rule.title"
          )
          .rules__content(
            v-for="(text, textIndex) in rule.text"
            :key="textIndex"
            v-html="text"
          )
          .rules__footer
            .rules__number {{ ruleIndex + 1 }}
            BaseIcon.rules__icon(
              :data="rule.icon.component"
              :width="rule.icon.size.width"
              :height="rule.icon.size.height"
              color="#ffffff"
            )

    .text-center
      base-button(
        size="large"
        color="blue"
        href="https://s3-eu-west-1.amazonaws.com/yi-files/promo/YellowImages_Create_your_own_duck_Rules.pdf"
        target="_blank"
      ) Download presentation
      .rules__pdf-data
        div PDF
        .rules__pdf-dot
        div 3,2 Mb

  section.section-block.section-vote
    .section-vote__cards-list
      .section-vote__card-wrap(
        data-aos="fade-in"
      )
        animated-gradient.section-vote__card(
          :colors=`[
            [247, 76, 226],
            [255, 154, 122],
            [255, 47, 110],
            [247, 76, 226],
            [255, 154, 122],
            [255, 47, 110]
          ]`
        )
          .section-vote__card-content-wrap
            h3.section-vote__card-title Sign in or create your Yellow Images account
            .section-vote__card-content Use your Facebook or Google account to register in just a few clicks.
          .section-vote__footer
            base-button(
              size="large"
              to="/login"
            ) Sign in
            .section-vote__icons-wrap
              .section-vote__icon
                BaseIcon(
                  data="@icon/about/apple.svg"
                  width="17"
                  height="20"
                )
              .section-vote__icon
                BaseIcon(
                  data="@icon/about/google.svg"
                  width="21"
                  height="22"
                )
              .section-vote__icon
                BaseIcon(
                  data="@icon/about/fb.svg"
                  width="11"
                  height="22"
                )

      .section-vote__card-wrap(
        data-aos="fade-in"
      )
        animated-gradient.section-vote__card(
          :colors=`[
            [255, 67, 224],
            [151, 186, 250],
            [98, 255, 216],
            [255, 67, 224],
            [151, 186, 250],
            [98, 255, 216]
          ]`
        )
          .section-vote__card-content-wrap
            h3.section-vote__card-title Place your vote
            .section-vote__card-content Registered users can vote for their favorite duck by tapping the heart icon next to the entry.
          .section-vote__footer
            base-button(
              size="large"
              to="/"
            ) Browse gallery
            .section-vote__icons-wrap
              .section-vote__icon
                BaseIcon(
                  data="@icon/heart.svg"
                  width="24"
                  height="24"
                )

  section.section-block.prizes
    h2.title-h2(
      data-aos="fade-in"
    ) Don't DUCK out on this opportunity <br/>to win these cool prizes!

    img.prizes__image(
      width="1320"
      src="https://cdn.yellowimages.com/3dvisualizer/about/ipad_mockup.png"
      srcset="https://cdn.yellowimages.com/3dvisualizer/about/ipad_mockup_x2.png 2x"
      alt="ipad"
      data-aos="fade-in"
    )

    Flicking.row.row_scroller.places(
      :options="{ bound: true, align: 'prev', bounce: 0 }"
      ref="places"
      @wheel="onWheel($event, this.$refs.places)"
    )
      .col-auto(
        v-for="(prize, prizeIndex) in prizes"
        :key="prizeIndex"
        data-aos="fade-up"
        :data-aos-delay="prizeIndex * 200"
      )
        .places__item
          .places__title {{ prize.title }}
          ul.places__list
            li.places__list-item(
              v-for="(listItem, cIndex) in prize.list"
              :key="cIndex"
              v-html="listItem"
            )

  section.section-action
    h2.title-h2(
      data-aos="fade-in"
    ) Don't wait! All contest entries must be <br/>submitted by 12:00pm PST December 1, 2022
    .section-action__block
      img.section-action__duck.section-action__duck_first(
        src="/images/about/ready-set-go/rsg-duck-1.png"
        srcset="/images/about/ready-set-go/rsg-duck-1@2x.png 2x"
        alt="duck 1"
      )
      img.section-action__duck.section-action__duck_second(
        src="/images/about/ready-set-go/rsg-duck-2.png"
        srcset="/images/about/ready-set-go/rsg-duck-2@2x.png 2x"
        alt="duck 2"
      )
      img.section-action__duck.section-action__duck_third(
        src="/images/about/ready-set-go/rsg-duck-3.png"
        srcset="/images/about/ready-set-go/rsg-duck-3@2x.png 2x"
        alt="duck 3"
      )
      img.section-action__duck.section-action__duck_fourth(
        src="/images/about/ready-set-go/rsg-duck-4.png"
        srcset="/images/about/ready-set-go/rsg-duck-4@2x.png 2x"
        alt="duck 4"
      )
      .section-action__title Ready, <br/>set, go!
      base-button(
        size="large"
        @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
      ) Create your duck

</template>

<script>
import rules1 from '@/assets/icons/about/rules-step-1.svg'
import rules2 from '@/assets/icons/about/rules-step-2.svg'
import rules3 from '@/assets/icons/about/rules-step-3.svg'
import rules4 from '@/assets/icons/about/rules-step-4.svg'

import BaseButton from "@/components/BaseButton";
import TheNavigation from "@/components/TheNavigation";

import InfiniteSlideBar from "@/components/InfiniteSlideBar";
import VueSticky from "@/components/utils/VueSticky";
import AnimatedGradient from "@/components/AnimatedGradient";

import Flicking from "@egjs/vue3-flicking"

import { useHead } from '@vueuse/head'

import { ROUTE_HEAD } from '@/data/serverRoutersData'

export default {
  name: "About",
  inject: ["mq"],
  components: {
    TheNavigation,
    BaseButton,
    AnimatedGradient,
    VueSticky,
    InfiniteSlideBar,
    Flicking
  },
  data () {
    return {
      ducks: [
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-1.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-1@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-2.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-2@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-3.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-3@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-4.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-4@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-5.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-5@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-6.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-6@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-7.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-7@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-8.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-8@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-9.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-9@2x.jpg 2x'
        },
        {
          src: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-10.jpg',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/about/slides/duck-10@2x.jpg 2x'
        }
      ],
      rules: [
        {
          title: 'Sign up for a Yellow Images account',
          text: [
            'Sign in to your Yellow Images account, or sign up using Facebook or Google.'
          ],
          icon: {
            component: rules1,
            size: {
              width: '24',
              height: '24'
            }
          }
        },
        {
          title: 'Create your own duck design',
          text: [
            'Open the 3D Visualizer, download the PSD template, and create your own design! You can also use a premade image.'
          ],
          icon: {
            component: rules2,
            size: {
              width: '24',
              height: '24'
            }
          }
        },
        {
          title: 'Upload your final design',
          text: [
            'Save your final design as a JPEG or PNG with a 3000x3000 resolution. Don\'t forget to give your duck a name when you submit it for voting!',
            '<b>Each user is allowed up to 3 different entries.</b>'
          ],
          icon: {
            component: rules3,
            size: {
              width: '24',
              height: '24'
            }
          }
        },
        {
          title: 'Share with friends',
          text: [
            'Your submission will be processed and published within 24 hours.',
            '<b>Please note that any offensive designs will not be published, out of respect to the creative community.',
            'Use the social media share buttons to share your duck design with friends and gather votes!'
          ],
          icon: {
            component: rules4,
            size: {
              width: '26',
              height: '24'
            }
          }
        }
      ],
      prizes: [
        {
          title: '1st place',
          list: [
            '<b>iPad Pro with Apple Pencil<b>',
            '12-month Yellow Images membership',
            'Commemorative duck souvenir with your winning design'
          ]
        },
        {
          title: '2nd place',
          list: [
            '<b>iPad Air with Apple Pencil</b>',
            '12-month Yellow Images membership',
            'Commemorative duck souvenir with your winning design'
          ]
        },
        {
          title: '3rd place',
          list: [
            '12-month Yellow Images membership',
            'Commemorative duck souvenir with your winning design'
          ]
        }
      ]
    }
  },
  mounted() {
    // TODO: костыль для AOS
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 2000)
  },
  setup () {
    useHead({
      title: ROUTE_HEAD.about.title
    })
  },
  methods: {
    onWheel(e, flick){
      if (e.deltaX !== 0) {
        e.preventDefault();
        flick.control.controller.axes.setTo({ flick: flick.camera.position + e.deltaX }, 0);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-wrap {
  width: 100%;
  position: sticky;
  top: 20px;
  z-index: 100;
}
.navigation-about {
  margin-top: 0;
  margin-bottom: 0;
}
.hero-block {
  text-align: center;
  padding-top: 60px;
  @include media-breakpoint-up(md) {
    padding-top: 80px;
  }
  &__title {
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    background: -webkit-linear-gradient(0deg, #84FAB0, #8FD3F4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin-bottom: 6px;
    padding-bottom: 10px;
    br {
      display: none;
    }
    @include media-breakpoint-up(md) {
      font-size: 70px;
      line-height: 80px;
      letter-spacing: -0.4px;
      margin-bottom: 32px;
      br {
        display: block;
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 80px;
      line-height: 90px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 120px;
      line-height: 130px;
      margin-bottom: 30px;
    }
  }
  &__subtitle {
    //semi-bold
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.33;
    max-width: 420px;
    margin: auto;
    @include media-breakpoint-up(md) {
      font-size: 24px;
      max-width: 560px;
    }
  }
}
.infinity-ducks {
  margin-top: 40px;
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 80px;
  }

  &__row {
    display: flex;
  }
  &__card {
    margin-right: 14px;
    width: 254px;
    height: 306px;
    flex-shrink: 0;
    border-radius: 26px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 314px;
      height: 380px;
      margin-right: 18px;
    }
    @include media-breakpoint-up(xl) {
      width: 334px;
      height: 404px;
    }
    @include media-breakpoint-up(xxl) {
      width: 382px;
      height: 462px;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    object-position: center;
    object-fit: cover;
  }
}
.title-h2 {
  font-weight: bold;
  font-size: 16px;
  color: #A1A1A6;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 32px;
  @include media-breakpoint-up(md) {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 1.2;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }
}
.section-block {
  margin-bottom: 80px;
  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }
}
.video-block {
  &__subtitle {
    font-weight: bold;
    font-size: 26px;
    line-height: 1.2;
    text-align: center;
    max-width: 570px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      br {
        display: none;
      }
    }
    @include media-breakpoint-up(md) {
      font-size: 31px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 36px;
      max-width: 670px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 48px;
      max-width: 900px;
      margin: -20px auto 0;
    }
    &-thin {
      font-weight: normal;
    }
    &-border {
      display: inline-block;
      border-bottom: 2px solid $white;
    }
  }
  &__video {
    border-radius: 12px;
    max-width: 816px;
    //height: 459px;
    margin: 40px auto 0;
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    &::before {
      content: '';
      padding-top: 61.35%;
      display: block;
    }
    @include media-breakpoint-up(md) {
      margin: 60px auto 0;
      border-radius: 26px;
    }
    @include media-breakpoint-up(xl) {
      margin: 80px auto 0;
      max-width: 1163px;
    }
    @include media-breakpoint-up(xxl) {
      max-width: 1320px;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
    iframe {
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      transform: scale(1.009);
      @include media-breakpoint-down(xl) {
        pointer-events: auto;
      }
    }
  }
}
.rules {
  &__list {
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    &:deep .flicking-camera {
      @include media-breakpoint-down(xl) {
        flex-wrap: nowrap;
      }
    }
  }

  .col-auto {
    width: 25%;

    @include media-breakpoint-down(xl) {
      width: 354px;
    }
  }

  &__item {
    height: 100%;
    width: 100%;
    border: 2px solid #FFFFFF;
    border-radius: 26px;
    padding: 24px 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-right: 16px;
    @include media-breakpoint-up(md) {
      padding: 30px 24px;
      margin-right: 20px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0;
    }
    @include media-breakpoint-up(xxl) {
      padding: 30px;
    }
  }
  &__title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 24px;
    max-width: 295px;
    @include media-breakpoint-up(md) {
      font-size: 36px;
      margin-bottom: 32px;
    }
  }
  &__content {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 14px;
    color: $gray-dark;
    line-height: 1.5;

    &:deep b {
      color: $white;
    }
  }
  &__footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  &__number {
    background: $white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 24px;
    font-weight: bold;
    font-size: 11px;
    color: $black;
    text-align: center;
    margin-right: 8px;
  }
  &__pdf-data {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    color: $gray-dark;
    letter-spacing: -0.53px;
  }
  &__pdf-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 8px;
    background: $gray-dark;
  }
}
.section-vote {
  &__cards-list {
    display: block;
    margin: 0 -10px -16px;
    @include media-breakpoint-up(md) {
      display: flex;
      margin: 0 -10px;
    }
  }
  &__card-wrap {
    padding: 0 10px;
    width: 100%;
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      width: 50%;
      margin: 0;
    }
  }

  &__card {
    padding: 24px 24px 32px;
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @include media-breakpoint-up(md) {
      height: 410px;
    }
    @include media-breakpoint-up(lg) {
      padding: 44px 40px 40px;
      height: 440px;
      transition: 0.4s ease;
      &:hover {
        transform: translateY(-10px);
      }
    }
    @include media-breakpoint-up(xl) {
      padding: 60px;
      height: 428px;
    }
  }
  &__card-title {
    font-weight: bold;
    font-size: 26px;
    line-height: 1.22;
    margin-bottom: 12px;
    @include media-breakpoint-up(md) {
      font-size: 29px;
      margin-bottom: 24px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 36px;
    }
  }
  &__card-content-wrap {
    max-width: 420px;
  }
  &__card-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.42;
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 21px;
    }
  }
  &__footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    padding-top: 32px;
    flex-direction: column-reverse;
    @include media-breakpoint-up(xl) {
      align-items: center;
      flex-direction: row;
      padding: 0;
      flex-grow: 0;
    }
    .button {
      width: 100%;
      margin-top: 60px;
      @include media-breakpoint-up(md) {
        margin: 0;
        width: 214px;
      }
    }
  }
  &__icons-wrap {
    display: flex;
  }
  &__icon {
    margin-left: 8px;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.prizes {
  &__image {
    display: block;
    margin: 0 auto 48px;
    width: 816px;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      margin: 0 auto 100px;
    }
    @include media-breakpoint-up(xl) {
      width: 1163px;
      margin: 0 auto 120px;
    }
    @include media-breakpoint-up(xxl) {
      width: 1320px;
    }
  }
}
.places {
  &:deep .flicking-camera {
    @include media-breakpoint-down(lg) {
      flex-wrap: nowrap;
    }
  }
  .col-auto {
    width: 33.333%;

    @include media-breakpoint-down(lg) {
      width: 334px;
    }

    &:nth-child(1) .places__title {
      background: -webkit-linear-gradient(-25deg, #00F6F4, #9282FF);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-child(2) .places__title {
      background: -webkit-linear-gradient(-25deg, #FFAB74, #E3215B);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:nth-child(3) .places__title {
      background: -webkit-linear-gradient(-25deg, #3BE85D, #00F6F4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__item {
    background: #201F22;
    border-radius: 26px;
    padding: 32px 24px;
    height: 100%;
    width: 100%;
    text-align: left;
    @include media-breakpoint-up(md) {
      padding: 40px 32px 48px;
    }
    @include media-breakpoint-up(xl) {
      padding: 40px 40px 60px;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 32px;
    letter-spacing: -0.2px;
    display: inline-block;
    margin-bottom: 40px;
    @include media-breakpoint-up(md) {
      font-size: 40px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 60px;
      margin-bottom: 60px;
    }
  }
  &__list {
    padding-left: 22px;
    margin: 0;
    list-style: disc;
  }
  &__list-item {
    font-weight: 500;
    font-size: 14px;
    color: $gray-dark;
    letter-spacing: -0.3px;
    line-height: 1.42;
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
      font-size: 16px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 21px;
      letter-spacing: -0.4px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:deep b {
      color: $white;
    }
  }
}
.section-action {
  margin-bottom: 60px;
  @include media-breakpoint-up(xxl) {
    margin-bottom: 100px;
  }
  &__block {
    height: 447px;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-image: url("/images/about/ready-go-xs.png");

    @include media-breakpoint-up(xs) {
      background-image: url("/images/about/ready-go.png");
    }
    @include media-breakpoint-up(xl) {
      height: 636px;
      background-image: url("/images/about/ready-set-go/rsg-bg.jpg");
    }
    @include media-breakpoint-up(xxl) {
      height: 720px;
    }
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    text-align: center;
    z-index: -1;
  }
  &__title {
    font-weight: bold;
    font-size: 48px;
    color: $black;
    line-height: 0.95;
    margin-bottom: 60px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      font-size: 72px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 100px;
      margin-bottom: 80px;
    }
  }
  .button {
    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }
  &__duck {
    display: none;
    @include media-breakpoint-up(xl) {
      position: absolute;
      display: block;
      transition: 0.4s ease;
    }
    &_first {
      left: calc(50% - 742px);
      bottom: calc(50% - 286px);
      width: 420px;
      @include media-breakpoint-up(xxl) {
        left: calc(50% - 835px);
        bottom: calc(50% - 319px);
        width: auto;
      }
    }
    &_second {
      left: calc(50% - 348px);
      top: calc(50% - 288px);
      width: 135px;
      @include media-breakpoint-up(xxl) {
        left: calc(50% - 389px);
        top: calc(50% - 323px);
        width: auto;
      }
    }
    &_third {
      left: calc(50% - 136px);
      top: calc(50% - 300px);
      width: 81px;
      @include media-breakpoint-up(xxl) {
        left: calc(50% - 149px);
        top: calc(50% - 335px);
        width: auto;
      }
    }
    &_fourth {
      right: calc(50% - 910px);
      bottom: calc(50% - 518px);
      width: 690px;
      @include media-breakpoint-up(xxl) {
        right: calc(50% - 1025px);
        bottom: calc(50% - 581px);
        width: auto;
      }
    }
  }
  &__block:hover &__duck_first {
    transform: translate(70px, 0);
  }
  &__block:hover &__duck_second {
    transform: translate(27px, 13px);
  }
  &__block:hover &__duck_third {
    transform: translate(13px, 23px);
  }
  &__block:hover &__duck_fourth {
    transform: translate(-70px, -41px);
  }
}
.mobile-card-carousel {
  margin-right: -20px;
}
@keyframes infinity-ducks_xxl {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -4096px 0;
  }
}
@keyframes infinity-ducks_xl {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -3536px 0;
  }
}
@keyframes infinity-ducks_md {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -3326px 0;
  }
}
@keyframes infinity-ducks {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -2679px 0;
  }
}
</style>
