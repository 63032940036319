<template lang="pug">
li.accordion__item
  .accordion__trigger(
    :class="{'accordion__trigger_active': visible}"
    @click="open"
  )
    slot(
      name="accordion-trigger"
    )
    button.accordion__trigger-btn(
      :class="{'accordion__trigger-btn_active': visible}"
    )
      BaseIcon(
        data="@icon/close.svg"
        width="13"
        height="13"
      )

  transition(
    name="accordion"
    @enter="start"
    @after-enter="end"
    @before-leave="start"
    @after-leave="end"
  )
    .accordion__content(
      v-show="visible"
    )
      slot
      .accordion__content-separator
</template>

<script>
export default {
  name: "accordion-item",
  inject: ["Accordion"],
  data() {
    return {
      index: null
    }
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active
    }
  },
  methods: {
    open () {
      if (this.visible) {
        this.Accordion.active = null
      } else {
        this.Accordion.active = this.index
      }
    },
    start (el) {
      el.style.height = el.scrollHeight + "px"
    },
    end (el) {
      el.style.height = ""
    }
  },
  created () {
    this.index = this.Accordion.count++
  }
}
</script>

<style lang="scss" scoped>
.accordion__item {
  padding: 0 24px;
  position: relative;
  background: $gray;
  border-radius: 16px;
  margin-bottom: 16px;
  @include media-breakpoint-up(md) {
    border-radius: 26px;
    margin-bottom: 24px;
    padding: 0 40px;
  }
}

.accordion__trigger {
  cursor: pointer;
  padding: 30px 55px 30px 0;
  font-size: 18px;
  letter-spacing: -0.91px;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
  @include media-breakpoint-up(md) {
    padding: 40px 55px 40px 0;
    font-size: 24px;
  }
}

.accordion__trigger-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 50%;
  right: 0;
  top: calc(50% - 20px);
  background: $white;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: rotate(45deg);
  transition: 0.3s;
  &_active {
    transform: none;
  }
  .svg-icon {
    display: block;
  }
}

.accordion__content {
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 1.8;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.accordion__content-separator {
  padding-bottom: 32px;
  @include media-breakpoint-up(md) {
    padding-bottom: 45px;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height .3s ease, opacity .3s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
