<template>
  <span :aria-hidden="!title"
        :aria-label="title"
        class="material-design-icon"
        role="img"
        v-bind="$attrs"
        @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 13 13">
      <path d="M3.81317 4.18683C3.36407 3.73772 2.63593 3.73772 2.18683 4.18683C1.73772 4.63593 1.73772 5.36407 2.18683 5.81317L3.81317 4.18683ZM6.50391 8.50391L5.69073 9.31708L6.50391 10.1303L7.31708 9.31708L6.50391 8.50391ZM10.821 5.81317C11.2701 5.36407 11.2701 4.63593 10.821 4.18683C10.3719 3.73772 9.64374 3.73772 9.19464 4.18683L10.821 5.81317ZM2.18683 5.81317L5.69073 9.31708L7.31708 7.69073L3.81317 4.18683L2.18683 5.81317ZM9.19464 4.18683L5.69073 7.69073L7.31708 9.31708L10.821 5.81317L9.19464 4.18683Z">
        <title v-if="title">{{ title }}</title>
      </path>
      </svg>
  </span>
</template>

<script>
export default {
  name: "SelectArrow",
  props: {
    title: {
      type: String,
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 13
    }
  }
}
</script>
