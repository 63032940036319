import { Viz3D } from '@/3d-library/src/app3d/src'

export default {
  install: function (app, options) {
    // app.config.globalProperties.$viz3d = Viz3D
    app.provide('$viz3d', Viz3D);
  }
}

export const $viz3d = Viz3D
