<template lang="pug">
.browse.d-flex.flex-wap
  .browse__col
    .browse__list
      .browse__header PRODUCTS
      a.browse__item(
        v-for="market in markets"
        :href="market.url"
      )
        .browse__icon.market-icon(
          :class="'market-icon_' + market.key"
        )
        | {{ market.title }}
  .browse__col
    .browse__list
      .browse__header DEALS
      a.browse__item(
        href="https://yellowimages.com/yellow-ticket"
      ) Subscription 90% OFF
      a.browse__item(
        href="https://yellowimages.com/make-a-deposit"
      ) Store Credits & Bonuses
      a.browse__item(
        href="https://yellowimages.com/on-demand"
      ) 
        | On-Demand Packs

    .browse__list
      .browse__header HELP
      a.browse__item(
        href="https://help.yellowimages.com/hc/en-us"
      ) Help Center
      a.browse__item(
        href="https://yellowimages.com/tutorials"
      ) Tutorials
      a.browse__item(
        href="https://yellowimages.com/academy"
      ) Author Academy

  .browse__col
    a.browse__item(
      href="https://yellowimages.com/become-an-author"
    )
      b Become an Author
    a.browse__item(
      href="https://yellowimages.com/become-an-affiliate"
    )
      b Affiliate Program
    a.browse__item(
      href="https://yellowimages.com/invite-a-friend"
    )
      b Invite Your Friends
    a.browse__item(
      href="https://yellowimages.com/contact-us"
    )
      b Contact us

</template>

<style lang="scss" scoped>


  .browse {
    padding: 10px 0;

    &__col {
      min-height: 1px;
      padding: 0 10px;
      flex: 1 1 auto;

      &:nth-child(1) {
        flex: 0 0 37.2%;
      }
      &:nth-child(2) {
        flex: 0 0 32.3%;
      }
      &:nth-child(3) {
        flex: 0 0 30.5%;
      }
    }

    &__list {
      margin: 31px 0 0 0;

      &:first-child {
        margin: 0;
      }
    }

    &__header {
      font-weight: $font-weight-bold;
      letter-spacing: -0.7px;
      margin: 0 0 20px;
      line-height: 1.4;
      padding: 9px 9px 0;
    }

    &__item {
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 5px 9px;
      border-radius: 6px;
      color: $black;
      transition: background 0.2s;
      font-size: 12px;
      line-height: 2.5;
      letter-spacing: -0.46px;

      transition: background .2s;
      &:hover {
        background: $gray;
        color: $black;
      }

      // sup {
      //   border-radius: 999px;
      //   display: inline-block;
      //   text-transform: uppercase;
      //   font-weight: 700;
      //   font-size: 8px;
      //   line-height: 1.5;
      //   letter-spacing: -.4px;
      //   color: #fff;
      //   background: #ff3d00;
      //   padding: 1px 6px;
      //   top: 0;
      //   margin: 0 0 15px 3px;
      // }
    }

    &__icon {
      flex: 0 0 26px;
      width: 26px;
      height: 26px;
      margin: 0 12px 0 0;
    }

  }
</style>

<script>

export default {
  name: 'TheHeaderBrowse',
  props: {
    markets: Array
  }
}
</script>
