export const setLayerColor = function(materialId, layerId, color){

  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId);

  material
    .layers[Number(layerId)]
    .settings
    .texture
    .color.value.value.setHex('0x'+color.slice(1));

};
