import {
  Vector3,
} from './../../../../../build/three.module.js';

const render = function(){

  // this.camera.lookAt(new Vector3(0, 0, 0));

  // визуализация сцены
  if(this.renderer) this.renderer.render(this.scene, this.camera);

};

export {
  render,
};
