import {
  MathUtils,
} from '../../../../../../build/three.module.js';
export const setTransformDesign = function(materialId, layerId, data){

  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId);

  material
    .layers[Number(layerId)]
    .settings
    .designMap.__texture.uv.setUvTransform(
      data.tX, data.tY, 
      data.scale, data.scale, 
      MathUtils.degToRad(data.rotation)
    ); //, 0.2, 0.2);

};
