import {initNodeMaterial} from '../../../modules/materials/initNodeMaterial/index.js';

async function setTexture(materialId, layerIndex, textureData, presetId, callback){

  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId);
  const preset = textureData.data.presets.find(preset => preset.id === presetId);

  this.dataScene.scene.images.find(img => {
    if(img.__texture) delete img.__texture;
  });

  const images = this.dataScene.scene.images;

  textureData.files_list.forEach(file => {
    if(!images.find(f => f.id === file.id)){
      images.push({
        id: file.id,
        name: file.original_name,
        type: "texture",
        url: file.path,
      });
    }
  });

  let designMap = JSON.parse(JSON.stringify(material.layers[Number(layerIndex)].settings.designMap));
  if(designMap && designMap.__texture) delete designMap.__texture;
  if(!designMap.id) designMap = null;

  material.layers[Number(layerIndex)] = {
    id: material.layers[Number(layerIndex)].id, // "32423wfwe191",
    name: material.layers[Number(layerIndex)].name,
    presetId: material.layers[Number(layerIndex)].presetId,
    visible: true,
    mask: [],
    settings: {
      color: {
        name: "Color",
        type: "color",
        visible: 1,
        position: 1,
        value: "#ebebeb",
        method: "setColor",
        groups: [
          1
        ]
      },
      titleTexture: "",
      enabled: false,
      textureId: material.layers[Number(layerIndex)].settings.textureId || '', //"12",
      uvTextureMap: {
        channel: 0,
        scale: 1,
        offset: {
          x: 0,
          y: 0
        },
        rotation: 0,
        repeat: {
          x: 1,
          y: 1
        },
        wrap: {
          s: "RepeatWrapping",
          t: "RepeatWrapping"
        }
      },
      designMap: designMap,
      designMapOpacity: {
        name: "Design Map",
        type: "slider",
        visible: 1,
        position: 1,
        value:  1,
        step: 0.01,
        method: "setDesignMap",
        groups: [
          1
        ],
        scope: {
          min: 0,
          max: 1
        }
      },
      uvDesignMap: {
        channel: 0,
        scale: 1,
        offset: {
          x: 0,
          y: 0
        },
        rotation: 0,
        repeat: {
          x: 1,
          y: 1
        },
        wrap: {
          s: "RepeatWrapping",
          t: "RepeatWrapping"
        }
      },
      texture: preset
    }
  };

  delete this.materials.ReadyNodeMaterials[material.id];
  this.materials.ReadyNodeMaterials[material.id] = await initNodeMaterial.bind(this)(material);

  for(let i=0, len=this.dataScene.scene.models[0].meshes.length; i<len; i++){
    const meshLink = this.dataScene.scene.models[0].meshes[i];
    if(meshLink.materialId === material.id){
      const mesh = this.scene.getObjectByName(meshLink.meshName);
      mesh.material = this.materials.ReadyNodeMaterials[material.id];
    }
  }

  if(callback) callback();

}

export {
  setTexture
};
