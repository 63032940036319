import { $vfm } from 'vue-final-modal'

const state = {
}

const getters = {
}

const actions = {
  /**
   * Установка или снятие лайка с проекта
   * @param commit
   * @param rootGetters
   * @param {Object} payload
   * @param {String} payload.id id проекта
   * @param {Boolean} payload.isLike лайкнут ли проект
   */
  onProjectLike ({ commit, rootGetters }, payload) {
    if (rootGetters['auth/isAuthenticated']) {
      this.$http
        .post(`/v1/like/projects/${payload.id}`, JSON.stringify({is_like: payload.isLike})).then()

      commit('projects/changeProjectLikeCount', payload, { root: true })
      commit('activeProject/changeActiveProjectLikeCount', payload, { root: true })
    } else {
      // Если пользователь не авторизован, то показываем попап
      $vfm.show('join-now', { regRequiredType: 'like-project' })
    }
  }

}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
