export const animate = function(){

  //this.renderer.clear();
  //this.renderer.setRenderTarget( null );

  if(this.renderer){

    this.render();
    this.controls.orbitControl.update();

    window.requestAnimationFrame(this.animate.bind(this));

    if(this.dev_tools && this.dev_tools.stats) this.dev_tools.stats.update();

  }

};
