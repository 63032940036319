<template lang="pug">
.sticky-wrap(
  ref="stickyWrap"
  :style="{height: `${elementHeight}px`}"
)
  .sticky-element(
    :class="{'sticky-element_active': isActive}"
    :style="{top: `${offset}px`}"
    ref="stickyElement"
  )
    slot
</template>

<script>
export default {
  name: "VueSticky",
  data () {
    return {
      isActive: false,
      elementHeight: null
    }
  },
  props: {
    offset: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.stickOnScroll)
    this.elementHeight = this.$refs.stickyElement.clientHeight
  },
  unmounted() {
    window.removeEventListener('scroll', this.stickOnScroll)
  },
  methods: {
    stickOnScroll () {
      this.isActive = this.$refs.stickyWrap.getBoundingClientRect().top - this.offset <= 0
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-element {
  &_active {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}
</style>
