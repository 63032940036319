const state = () => ({
  textureCatalog: [],
  isGlobal: false
})

const getters = {
  getAllProjectTextures (state) {
    if (state.isGlobal) {
      return state.textureCatalog
    } else {
      const projectTextures = _.cloneDeep(state.textureCatalog[0].textures)

      for (let i = 1; i < state.textureCatalog.length; i++) {
        _.forEach(state.textureCatalog[i].textures, (texture) => {
          const textureInArray = _.find(projectTextures, ['id', texture.id])
          if (!textureInArray) {
            projectTextures.push(texture)
          }
        })
      }

      return projectTextures
    }
  },
  getTexturesMaterial: (state) => (materialName) => {
    if (state.isGlobal) {
      return state.textureCatalog
    } else {
      return _.find(state.textureCatalog, ['name', materialName])?.textures
    }
  }
}

const actions = {
  async getTextureCatalog ({ state, commit, rootState, rootGetters }, projectId) {
    let texturesUrl

    if (projectId) {
      commit('changeTextureCatalogGlobal', false)
      texturesUrl = `/v1/showcase/projects/${projectId}/textures?type=standart`
    } else {
      commit('changeTextureCatalogGlobal', true)
      texturesUrl = '/v1/showcase/products/textures?type=standart'
    }

    return await this.$http.get(texturesUrl)
      .then(response => {
        if (state.isGlobal) {
          commit('SET_GLOBAL_TEXTURE_CATALOG', response.data.body)
        } else {
          const activeTextures = rootGetters['activeProject/getActiveTexturesId']
          commit('SET_TEXTURE_CATALOG', { textures: response.data.body, activeTextures })
        }
      })
  },
  async getMaterialNames ({commit}) {
    await this.$http({
      url: 'materials.json',
      baseURL: 'https://yi-data3d-stage.s3-eu-west-1.amazonaws.com/materials',
      transformRequest: (data, headers) => {
        delete headers.common
      }
    })
      .then(response => {
        commit('setMaterialNames', response.data.materials)
      })
  },
  getTextureIconById ({ state, getters }, textureId) {
    let allTexture
    if (state.isGlobal) {
      allTexture = state.textureCatalog
    } else {
      allTexture = getters.getAllProjectTextures
    }
    const currentTexture = _.find(allTexture, ['id', textureId])

    return currentTexture ? currentTexture.data.presets[0].previewUrl : ''
  }
}

const mutations = {
  SET_GLOBAL_TEXTURE_CATALOG (state, textures) {
    const textureList = _.cloneDeep(textures)

    _.forEach(textureList, (texture, textureIndex) => {
      // Берем только активный пресет
      texture.data.presets = _.filter(texture.data.presets, ['default', 1])

      // Добавляем во все пресеты превью изображение
      _.forEach(texture.data.presets, (preset) => {
        const previewPreset = _.find(texture.files_list, ['id', preset.preview])

        if (!previewPreset) {
          preset.previewUrl = null
        } else {
          preset.previewUrl = previewPreset.path
        }
      })
    })


    state.textureCatalog = textureList
  },
  SET_TEXTURE_CATALOG (state, {textures, activeTextures}) {
    const textureList = _.cloneDeep(textures.items)

    _.forEach(textureList, (material, materialIndex) => {
      let activeTextureIndex

      _.forEach(material.textures, (texture, textureIndex) => {
        // Берем только активный пресет
        texture.data.presets = _.filter(texture.data.presets, ['default', 1])

        // Добавляем во все пресеты превью изображение
        _.forEach(texture.data.presets, (preset) => {
          const previewPreset = _.find(texture.files_list, ['id', preset.preview])

          if (!previewPreset) {
            preset.previewUrl = null
          } else {
            preset.previewUrl = previewPreset.path
          }
        })

        // Запоминаем активную текстуру
        if (texture.id === activeTextures[materialIndex]) {
          activeTextureIndex = textureIndex
        }

      })

      //Переносим активную текстуру в начало списка текстур
      if (activeTextureIndex) {
        const activeTextureCopy = _.cloneDeep(material.textures[activeTextureIndex])

        material.textures.splice(activeTextureIndex, 1)
        material.textures.unshift(activeTextureCopy)
      }
    })

    state.textureCatalog = textureList
  },
  setMaterialNames (state, materials) {
    if (state.isGlobal) {
      _.forEach(state.textureCatalog, texture => {
        const materialNameData = _.find(materials, ['id', texture.id])

        if (materialNameData) {
          texture.enName = materialNameData.en
        }
      })
    } else {
      _.forEach(state.textureCatalog, textureCatalog => {
        _.forEach(textureCatalog.textures, texture => {
          const materialNameData = _.find(materials, ['id', texture.id])

          if (materialNameData) {
            texture.enName = materialNameData.en
          }
        })
      })
    }
  },
  changeTextureCatalogGlobal (state, isGlobal) {
    state.isGlobal = isGlobal
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
