<template lang="pug">
button.like-btn(
  @click="onLike"
  @mouseover="isHover = true"
  @mouseleave="isHover = false"
  :class="{'like-btn_liked': liked, 'like-btn_hover': isHover, 'like-btn_big': isBig}"
)
  heart(
    :size="24"
    fill-color="#000000"
  )
  span.like-btn__likes-count {{ likeCount }}
</template>

<script>
import Heart from 'vue-material-design-icons/Heart.vue'
export default {
  name: "vizLikeBtn",
  components: { Heart },
  emits: ['onLike'],

  data () {
    return {
      isHover: false
    }
  },

  props: {
    likeCount: Number,
    liked: Boolean,
    isBig: Boolean
  },

  methods: {
    onLike () {
      this.isHover = false
      this.$emit('onLike')
    }
  }
}
</script>

<style lang="scss" scoped>
.like-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 0;
  width: 52px;
  height: 52px;
  background: $white;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
  transition: 0.2s ease;
  position: relative;
  &:focus-visible {
    outline: none;
  }
  &__likes-count {
    font-weight: bold;
    font-size: 10px;
    color: #FFFFFF;
    letter-spacing: -0.38px;
    position: absolute;
    bottom: 8px;
    line-height: 1.3;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
    transition: .2s ease;
  }
  &_big {
    width: 64px;
    height: 64px;
  }
  &_big &__likes-count {
    bottom: 13px;
  }
  &:deep {
    .material-design-icon {
      pointer-events: none;
    }
    svg {
      display: block;
      position: relative;
      top: 2px;
      transition: .2s ease;
    }
    path {
      transition: 0.2s ease;
    }
  }
  &_hover {
    background: $black;
    &:deep path {
      fill: $white;
    }
  }
  &_liked {
    background: $blue;
    box-shadow: 0 10px 20px 0 rgba(64,64,255,0.24);
    .like-btn__likes-count {
      opacity: 1;
    }
    &:deep {
      svg {
        top: -5px;
      }
      path {
        fill: $white;
      }
    }
    &.like-btn_hover{
      background: $black;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
    }
  }
}
</style>
