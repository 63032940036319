export const setDesignMap = function(materialId, layerId, value){

  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId);
  if(material){

    const layer = material.layers[Number(layerId)];
    if(layer){
      layer
        .settings
        .designMapOpacity.value.value = Number(value);
    }else{
      console.error(`no find layer:`, layer);
    }

  }else{
    console.error(`no find material:`, material);
  }

};
