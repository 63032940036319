const state = () => ({
  history: [],
  currentHistoryStepIndex: 0,
  snapshots: [],
  currentSnapshotIndex: 0
})

const getters = {}

const actions = {
  snapshotBack ({ state, dispatch }) {
    if (state.currentSnapshotIndex > 0) {
      dispatch('snapshotStep', 'snapshotBack')
    }
  },

  snapshotForward ({ state, dispatch }) {
    if (state.currentSnapshotIndex < state.snapshots.length - 1) {
      dispatch('snapshotStep', 'snapshotForward')
    }
  },

  async snapshotStep ({ state, commit, rootState }, direction) {
    // await rootState.activeProject.isSavingFile

    commit(direction)

    const materialIndex = state.snapshots[state.currentSnapshotIndex].materialIndex
    const layerIndex = state.snapshots[state.currentSnapshotIndex].layerIndex


    const snapshot = state.snapshots[state.currentSnapshotIndex]

    rootState.activeProject.activeVariation.sceneInstance.api.rerenderScene(_.cloneDeep(snapshot.scene))

    commit(
      'activeProject/setSceneData',
      {
        scene: _.cloneDeep(snapshot.scene),
        materialIndex,
        layerIndex
      },
      {
        root: true
      }
    )

    commit('activeProject/addedUnsavedChanges', null, { root: true })
  },

  addSnapshot ({ state, commit, rootState }, scene) {
    const snapshotData = {
      scene: _.cloneDeep(scene),
      materialIndex: rootState.activeProject.activeVariation.activeMaterialIndex,
      layerIndex: rootState.activeProject.activeMaterial.activeLayerIndex
    }

    commit('addSnapshot', snapshotData)
  }
}

const mutations = {
  clearHistory (state) {
    state.history = []
    state.currentHistoryStepIndex = 0
    state.snapshots = []
    state.currentSnapshotIndex = 0
  },

  addSnapshot (state, data) {
    state.snapshots.splice(state.currentSnapshotIndex + 1, state.snapshots.length, data)
    state.currentSnapshotIndex = state.snapshots.length - 1
  },

  snapshotBack (state) {
    state.currentSnapshotIndex -= 1
  },

  snapshotForward (state) {
    state.currentSnapshotIndex += 1
  },

  addDesignUrl (state, {materialIndex, layerIndex, response, uploadTime}) {
    if (response.ok && response?.body && response.body.path) {
      _.forEach(state.snapshots, snapshot => {
        const layer = snapshot.scene.materials[materialIndex].layers[layerIndex]
        if (layer && !layer.settings.designMap.designUrl) {

          if (!layer.settings.designMap.uploadTime || (uploadTime > layer.settings.designMap.uploadTime)) {
            layer.settings.designMap.designUrl = response.body.path
            layer.settings.designMap.uploadTime = uploadTime
          }
        }
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
