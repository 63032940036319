import {initNodeMaterial} from '../../../modules/materials/initNodeMaterial/index.js';

export const removeLayer = async function(materialId, layerId){

  // поискнужного материала
  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId) || null;

  if(material){

    if(Number(layerId) !== 0){
      const newArrLayers = [];

      for(let i=0, len=material.layers.length; i<len; i++){
        if(i !== layerId){
          newArrLayers.push(material.layers[i]);
        }
      }

      material.layers = newArrLayers;

      this.materials.ReadyNodeMaterials[material.id] = await initNodeMaterial.bind(this)(material);

      for(let i=0, len=this.dataScene.scene.models[0].meshes.length; i<len; i++){
        const meshLink = this.dataScene.scene.models[0].meshes[i];
        if(meshLink.materialId === material.id){
          const mesh = this.scene.getObjectByName(meshLink.meshName);
          mesh.material = this.materials.ReadyNodeMaterials[material.id];
        }
      }
    }

  }else{
    console.error(`No find material: ${materialId}`);
  }

};
