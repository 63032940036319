<template lang="pug">
.container
  TheNavigation
  h1 Help page
</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue'

export default {
  name: 'Rules',
  components: {
    TheNavigation
  },
  // data() {
  //   return {
  //   }
  // }
}
</script>

<style lang="scss" scoped>

</style>

