<template lang="pug">
vue-final-modal(
  v-slot="{ params }"
  v-bind="$attrs"
  classes="modal-container"
  :content-class="fullScreen ? 'modal-content-fullscreen' : 'modal-content'"
  @closed="$emit('modalClose')"
)
  .modal-head(
    :class="{'modal-head_fullscreen': fullScreen}"
  )
    slot(
      name="head"
      :params="params"
    )
    button.close-modal(
      v-if="fullScreen"
      @click="$emit('modalClose')"
    )
      BaseIcon(
        data="@icon/close.svg"
        width="14"
        height="14"
      )
  .modal-scrollable-content
    slot(
      :params="params"
    )
  .modal-footer.modal-footer_fullscreen(
    :class="{'modal-footer_fullscreen': fullScreen}"
  )
    slot(
      name="footer"
      :params="params"
    )
</template>

<script>
export default {
  name: 'BaseModal',

  inheritAttrs: false,
  emits: ['modalClose'],

  props: {
    fullScreen: Boolean
  }
}
</script>

<style lang="scss" scoped>
  :deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      &.vfm-enter-active,
      &.vfm-leave-active {
        transition: 0.3s ease;

        .modal-content {
          transition: 0.3s ease;
          transform: translateY(0);
        }
      }
      &.vfm-enter-from,
      &.vfm-leave-to {
        opacity: 1;

        .modal-content {
          transform: translateY(100%);
        }
      }
    }
  }
  :deep(.modal-content) {
    position: relative;
    max-height: 90%;
    margin: auto 0 0 0;
    padding: 24px 16px 20px;
    background: #FFFFFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      margin: 0 1rem;
      border-radius: 16px;
      padding: 52px 32px 32px;
    }
  }
  :deep(.modal-content-fullscreen) {
    position: relative;
    height: 100%;
    width: 100%;
    background: $gray;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  .modal-scrollable-content {
    padding: 16px;
    margin: -16px;
    overflow: auto;
  }
  .modal-head, .modal-footer {
    flex-shrink: 0;
  }

  .modal-head {
    &_fullscreen {
      margin: -16px -16px 0 -16px;
      padding: 25px 16px;
      position: relative;
      background: $gray;
    }
  }
  .close-modal {
    position: absolute;
    right: 16px;
    top: 14px;
    width: 42px;
    height: 42px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    background: $white;
    color: $black;
    transition: color .2s;

    @include media-breakpoint-up(md) {
      &:hover {
        color:$blue;
      }
    }
  }
</style>
