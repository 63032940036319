<template lang="pug">
base-modal(
  v-bind="$attrs"
  :content-style="{maxWidth: '400px'}"
  v-slot="{ params }"
  @modal-close="$emit('modalClose')"
)
  .standard-modal
    button.close-modal-mobile(
      v-if="!hideCloseIcon"
      v-hammer:swipe.down="onMobileClose"
      v-hammer:tap="onMobileClose"
    )
    button.close-modal(
      v-if="!hideCloseIcon"
      @click="$emit('modalClose')"
    )
      BaseIcon(
        data="@icon/close.svg"
        width="11"
        height="11"
      )
    .standard-modal__content
      slot(
        :params="params"
      )
    .standard-modal__buttons(
      v-if="$slots.buttons"
    )
      slot(
        name="buttons"
        :params="params"
      )
</template>

<script>
import BaseModal from '@/components/BaseModal'
export default {
  name: 'StandardModal',
  components: { BaseModal },
  emits: ['modalClose'],
  props: {
    hideCloseIcon: Boolean
  },
  methods: {
    onMobileClose (e) {
      this.$emit('modalClose')
    }
  }
}
</script>

<style lang="scss" scoped>
  .standard-modal {
    &__content {
      font-size: 16px;
      text-align: center;
      line-height: 1.5;
    }
    &__buttons {
      display: flex;
      justify-content: center;
      margin: 32px -10px 0;
      :deep {
        .button {
          margin: 0 10px;
          min-width: 120px;
        }
      }
    }
    :deep &__title {
      margin-bottom: 7px;
      margin-top: 0;
    }
  }
  .close-modal {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    background: $gray;
    :deep {
      svg {
        display: block;
      }
      path {
        transition: 0.2s;
      }
    }
    &:hover {
      :deep path {
        fill: $blue;
      }
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .close-modal-mobile {
    display: block;
    width: 36px;
    height: 4px;
    border-radius: 6px;
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    background: #E2E5E9;
    &::before {
      content: '';
      display: block;
      width: 100px;
      left: calc(50% - 50px);
      position: absolute;
      top: -10px;
      height: 24px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
</style>
