import {
  UnsignedByteType,
  LinearFilter,
  PMREMGenerator
} from './../../../../../build/three.module.js';

import {HDRCubeTextureLoader} from './../../../../jsm/loaders/HDRCubeTextureLoader.js';

import {
  TextureNode
} from './../../../../jsm/nodes/Nodes.js';

export const environments = async function(resolve){

  const _this = this;

  if(
    _this.dataScene.scene.hdr && 
    _this.dataScene.scene.hdr.data && 
    _this.dataScene.scene.hdr.data.files
  ){

    const hdr = _this.dataScene.scene.hdr.data.files;

    _this.environments = {
      active: 0,
      maps: []
    };

    {
      let hdrCubeMap;
      let hdrCubeRenderTarget;

      const dataHDR = _this.dataScene.scene.hdr;
      const pmremGenerator = new PMREMGenerator(_this.renderer);
      pmremGenerator.compileCubemapShader();
      _this.environments.maps[0] = new TextureNode();
      const hdrUrls = [
        hdr.px.path,
        hdr.nx.path,
        hdr.py.path,
        hdr.ny.path,
        hdr.pz.path,
        hdr.nz.path,
      ];
      hdrCubeMap = new HDRCubeTextureLoader()
        .setPath(dataHDR.url)
        .setDataType(UnsignedByteType)
        .load(hdrUrls, function(){

          hdrCubeRenderTarget = pmremGenerator.fromCubemap(hdrCubeMap);
          pmremGenerator.dispose();

          _this.environments.maps[0].value = hdrCubeRenderTarget.texture;

          hdrCubeMap.magFilter = LinearFilter;
          hdrCubeMap.needsUpdate = true;

          resolve(1);
        });
    }

  }else{

    _this.environments = {
      active: 0,
      maps: []
    };

    {
      let hdrCubeMap;
      let hdrCubeRenderTarget;

      const dataHDR = _this.dataScene.scene.hdr;
      const pmremGenerator = new PMREMGenerator(_this.renderer);
      pmremGenerator.compileCubemapShader();
      _this.environments.maps[0] = new TextureNode();
      const hdrUrls = [
        'px.hdr',
        'nx.hdr',
        'py.hdr',
        'ny.hdr',
        'pz.hdr',
        'nz.hdr',
      ];
      hdrCubeMap = new HDRCubeTextureLoader()
        .setPath(dataHDR.url)
        .setDataType(UnsignedByteType)
        .load(hdrUrls, function(){

          hdrCubeRenderTarget = pmremGenerator.fromCubemap(hdrCubeMap);
          pmremGenerator.dispose();

          _this.environments.maps[0].value = hdrCubeRenderTarget.texture;

          hdrCubeMap.magFilter = LinearFilter;
          hdrCubeMap.needsUpdate = true;

          resolve(1);
        });
    }

  }

};
