//import * as THREE from './../../../../../../build/three.module.js';
import {GUI} from './../../../../../jsm/libs/dat.gui.module.js';

export const _gui = function(){

  const _this = this;

  const gui = new GUI();
  gui.width = 351;
  gui.close();

  {

    const sceneSettings = gui.addFolder("Scene settings");
    sceneSettings.open();

    {

      const envFolder = sceneSettings.addFolder("Environment");
      //envFolder.add({
      //  envMap: '0. STUDIOS_METAL_043_1024-32'
      //}, 'envMap', [
      //  '0. STUDIOS_METAL_043_1024-32',
      //  //'1. object-hdri-v9',
      //  //'2. back-32',
      //  //'3. aerodynamics_workshop_2k',
      //]).name('ENV').onChange(function(value){
      //  const i = Number(value.split('. ')[0]);
      //  if(_this.environments.maps[i]){
      //    _this.environments.active = i;
      //    _this.materials.PhysicalMaterial_Global.envMap = _this.environments.maps[i].map;
      //    _this.materials.PhysicalMaterial_Global.needsUpdate = true;
      //  }
      //});
      envFolder.add({
        exposure: _this.renderer.toneMappingExposure, // 0-2, 0.01
      }, "exposure").min(0).max(4).step(0.01).onChange(function(value){
        _this.renderer.toneMappingExposure = value;
        _this.render();
      });
      //envFolder.add({
      //  rotation: 0
      //}, "rotation").min(0).max(360).step(1).onChange(function(value){
      //  const radians = value * Math.PI / 180;
      //  _this.controls.orbitControl.target.applyAxisAngle(_this.camera.up, radians - _this.objScene.rotation.y);
      //  //_this.camera.position.applyAxisAngle(_this.camera.up, radians - _this.objScene.rotation.y);
      //  //const myAxis = new THREE.Vector3(0, 1, 0);
      //  _this.EnvRotation.rotation.y = radians;
      //  //_this.objScene.rotateOnWorldAxis(myAxis, radians - _this.objScene.rotation.y);
      //});
      envFolder.close();

    }

  }

  {

    const lightsSettings = gui.addFolder("Lights");
    lightsSettings.open();

    {
      const ambientLight = _this.scene.getObjectByName("AmbientLight");
      const alParams = {
        'light color': ambientLight.color.getHex(),
        intensity: ambientLight.intensity,
      };
      const ambientLightFolder = lightsSettings.addFolder("AmbientLight");
      ambientLightFolder.addColor(alParams, 'light color').onChange(function(val){
        ambientLight.color.setHex(val);
        _this.render();
      });
      ambientLightFolder.add(alParams, "intensity").min(0).max(7).step(0.001).onChange(function(value){
        ambientLight.intensity = value;
      });
    }

    {
      const hemisphereLight = _this.scene.getObjectByName("HemisphereLight");
      const hlParams = {
        'Sky color': hemisphereLight.color.getHex(),
        'Ground color': hemisphereLight.groundColor.getHex(),
        intensity: hemisphereLight.intensity,
      };
      const hemisphereLightFolder = lightsSettings.addFolder("HemisphereLight");
      hemisphereLightFolder.addColor(hlParams, 'Sky color').onChange(function(val){
        hemisphereLight.color.setHex(val);
        _this.render();
      });
      hemisphereLightFolder.addColor(hlParams, 'Ground color').onChange(function(val){
        hemisphereLight.groundColor.setHex(val);
        _this.render();
      });
      hemisphereLightFolder.add(hlParams, "intensity").min(0).max(1).step(0.001).onChange(function(value){
        hemisphereLight.intensity = value;
      });
    }

    {
      const pointLight = _this.scene.getObjectByName("PointLight");
      const plParams = {
        'Color': pointLight.color.getHex(),
        intensity: pointLight.intensity,
        distance: pointLight.distance,
        decay: pointLight.decay,
        sizeShadow: pointLight.shadow.mapSize.width,
        'Shadow near': pointLight.shadow.camera.near,
        'Shadow far': pointLight.shadow.camera.far,
        'power (lm)': pointLight.power,
        'Position X': pointLight.position.x,
        'Position Y': pointLight.position.y,
        'Position Z': pointLight.position.z,
      };
      const pointLightFolder = lightsSettings.addFolder("PointLight");
      pointLightFolder.addColor(plParams, 'Color').onChange(function(val){
        pointLight.color.setHex(val);
        _this.render();
      });
      pointLightFolder.add(plParams, "sizeShadow", [256, 512, 1024, 2048, 4096]).name('Size shadow map').onChange(function(value){
        pointLight.shadow.mapSize.width = value;
        pointLight.shadow.mapSize.height = value;
        pointLight.shadow.map.dispose();
        pointLight.shadow.map = null;
      });
      pointLightFolder.add(plParams, "intensity").min(0).max(300).step(0.01).onChange(function(value){
        pointLight.intensity = value;
      });
      pointLightFolder.add(plParams, 'power (lm)').min(1).max(1500).step(1).onChange(function(value){
        pointLight.power = value;
      });
      pointLightFolder.add(plParams, "distance").min(0).max(500).step(0.1).onChange(function(value){
        pointLight.distance = value;
      });
      pointLightFolder.add(plParams, "decay").min(0).max(5).step(0.001).onChange(function(value){
        pointLight.decay = value;
      });
      pointLightFolder.add(plParams, "Position X").min(0).max(70).step(0.1).onChange(function(value){
        pointLight.position.x = value;
      });
      pointLightFolder.add(plParams, "Position Y").min(0).max(70).step(0.1).onChange(function(value){
        pointLight.position.y = value;
      });
      pointLightFolder.add(plParams, "Position Z").min(0).max(70).step(0.1).onChange(function(value){
        pointLight.position.z = value;
      });
    }

  }

  /*{

    _this.configurator.ControlDiffuseMap = gui.addFolder("Diffuse map");
    _this.configurator.ControlDiffuseMap.open();
    const ControlDiffuseMap = _this.configurator.ControlDiffuseMap;

    {
      const CNVcontrol = ControlDiffuseMap.addFolder("Canvas control");
      for(let i=0, len=_this.configurator.layersMap.length; i<len; i++){
        let layer = CNVcontrol.addFolder("layer "+i);
        layer.addColor({color: '#ffffff'}, 'color').onChange(function(val){
          const layer = _this.configurator.layersMap[i];
          const img = _this.configurator.images[i];
          layer.config.color = val; //.color.setHex(val);
          new Promise((res, rej) => {
            bakeCanvasLayer.bind(_this)(layer, img, res);
          }).then(() => {
            bakeCanvasDiffuse.bind(_this)();
          });
        });
      }
    }

    {
      const SVGcontrol = ControlDiffuseMap.addFolder("SVG control");

      const layer0 = $(deffuseMapSVG).find('#layer0');

      SVGcontrol.addColor({color: '#ffffff'}, 'color').onChange(function(val){
        if(layer0[0].tagName === 'path'){

          _this.materials.PhysicalMaterial_Global.map = null;
          _this.materials.PhysicalMaterial_Global.needsUpdate = true;

          layer0.attr({'fill': val});

          _this.materials.PhysicalMaterial_Global.map = new THREE.CanvasTexture( _this.configurator.svg.canvas.canvas );
          _this.materials.PhysicalMaterial_Global.needsUpdate = true;

        }
      });
    }

  }*/

};
