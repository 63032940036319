<template lang="pug">
.layer(
  ref="layer"
  :class="{'layer_active': isActive}"
)
  .layer__item.layer__item_main(
    v-if="layerIndex === 0"
    @click="activateMainLayer"
  )
    .layer__image-wrap
      img(
        v-if="designPreview"
        :src="designPreview"
        :alt="material.name"
      )
    .layer__name {{ material.name }}
    .layer__actions-wrap(
      v-if="material.layers.length === 1"
    )
      mq-responsive(
        target="lg-"
      )
        viz-input-design.layer__action
          BaseIcon(
            data="@icon/plus.svg"
            width="14"
            height="14"
          )

  .layer__item.layer__item_design(
    v-else
    @click="activateDesignLayer"
  )
    subdirectory-arrow-right.layer__inside-icon(
      fill-color="#856404"
      :size="12"
    )
    .layer__image-wrap
      img(
        v-if="designPreview"
        :src="designPreview"
        :alt="layer.name"
      )
    .layer__name Design {{ layerIndex }}
    .layer__actions-wrap
      button.layer__action.layer__action_visibility(
        @click.stop="removeLayer"
      )
        delete-outline(
          :size="14"
        )
</template>

<script>
import EyeOutline from 'vue-material-design-icons/EyeOutline.vue'
import DeleteOutline from 'vue-material-design-icons/DeleteOutline.vue'
import EyeOffOutline from 'vue-material-design-icons/EyeOffOutline.vue'
import SubdirectoryArrowRight from 'vue-material-design-icons/SubdirectoryArrowRight.vue'
import VizInputDesign from "@/components/player/vizInputDesign";
import { MqResponsive } from "vue3-mq"
import isBase64 from "@/functions/isBase64";
import getImageLink from "@/functions/getImageLink";
export default {
  name: 'vizLayer',

  components: {VizInputDesign, EyeOutline, DeleteOutline, EyeOffOutline, SubdirectoryArrowRight, MqResponsive },
  emits: ['changeActiveLayerTop'],

  props: {
    isActive: Boolean,
    materialIndex: Number,
    material: Object,
    layerIndex: Number,
    layer: Object
  },

  computed: {
    designPreview () {
      if (this.layerIndex === 0) {
        const imagesList = this.$store.state.activeProject.activeVariation.scene.images
        if (this.layerIndex === 0 && this.material.iconList) {
          const previewIndex = _.findIndex(imagesList, ['id', this.material.iconList])
          return imagesList[previewIndex].url
        }
      } else {
        if (this.layer.settings.designMap) {
          if (!isBase64(this.layer.settings.designMap.url)) {
            return getImageLink(this.layer.settings.designMap.url, '@2', 'webp')
          } else {
            return this.layer.settings.designMap.url
          }
        } else {
          return ''
        }
      }
    }
  },

  mounted() {
    if (this.isActive) {
      this.$emit('changeActiveLayerTop', this.$refs.layer.offsetTop)
    }
  },

  methods: {
    activateMainLayer () {
      this.$store.commit('activeProject/changeActiveMaterial', this.materialIndex)
      this.$store.commit('activeProject/clearAdditionalSetting')
    },
    activateDesignLayer () {
      this.$store.commit('activeProject/changeActiveMaterial', this.materialIndex)
      this.$store.commit('activeProject/changeActiveLayer', this.layerIndex)
      this.$store.commit('activeProject/clearAdditionalSetting')
    },
    toggleLayerVisibility () {
      this.activateDesignLayer()
      this.$store.commit('activeProject/toggleLayerVisibility', this.layerIndex)
    },
    removeLayer () {
      this.activateDesignLayer()
      this.$store.commit('activeProject/removeLayer', this.layerIndex)
      this.$store.commit('activeProject/addedUnsavedChanges')
      this.$store.dispatch('projectHistory/addSnapshot', this.$store.state.activeProject.activeVariation.scene)
    }
  },
  watch: {
    isActive (newVal) {
      if (newVal) {
        this.$emit('changeActiveLayerTop', this.$refs.layer.offsetTop)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .layer {
    margin-top: 4px;
    @include media-breakpoint-up(xl) {
      margin-top: 2px;
    }
    &__item {
      padding: 8px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include media-breakpoint-up(xl) {
        border-radius: 3px;
        padding: 2px;
      }
      &_main {
        background: $gray;
      }
      &_design {
        background: #FFF3CD;
      }
    }
    &__image-wrap {
      background: $white;
      border-radius: 8px;
      width: 80px;
      height: 56px;
      flex-shrink: 0;
      margin-right: 16px;
      overflow: hidden;
      position: relative;
      @include media-breakpoint-up(xl) {
        width: 34px;
        height: 34px;
        margin-right: 12px;
        border-radius: 2px;
      }
      img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
    &__name {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.2;
      letter-spacing: -0.342857px;
      @include media-breakpoint-up(xl) {
        font-size: 10px;
      }
    }
    &__actions-wrap {
      display: flex;
      align-items: center;
      margin-left: auto;
      flex-shrink: 0;
      padding-left: 12px;
      margin-right: 3px;
    }
    &__action {
      margin-right: 4px;
      padding: 0;
      background: inherit;
      border: none;
      box-shadow: none;
      display: block;
      &:deep svg {
        display: block;
        width: 24px;
        height: 24px;
      }
      @include media-breakpoint-up(xl) {
        &:deep svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    &__inside-icon {
      margin-right: 4px;
      margin-left: 2px;
      flex-shrink: 0;
      &:deep svg {
        display: block;
        width: 16px;
        height: 16px;
      }
      @include media-breakpoint-up(xl) {
        &:deep svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &_active &__item {
      background: $blue;
    }
    &_active &__name {
      color: $white;
    }
    &_active &__action, &_active &__inside-icon {
      &:deep path {
        fill: $white;
      }
    }
  }
</style>
