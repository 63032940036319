<template lang="pug">
.progress-line
  .progress-line__progress(
    :style="{width: `${progress}%`}"
  )
</template>

<script>
export default {
  name: "ProgressBar",

  props: {
    progress: Number
  }
}
</script>

<style lang="scss" scoped>
.progress-line {
  width: 100%;
  display: block;
  height: 4px;
  border-radius: 10px;
  background: $gray;
  position: relative;
  &__progress {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px;
    background-image: linear-gradient(270deg, #4494FE 0%, #4040FF 48%, #5343CA 100%);
    //transition: 0.1s;
  }
}
</style>
