<template lang="pug">
.rejected-message(
  v-click-away="onClickAway"
)
  base-button.rejected-message__activator(
    round
    @click="toggle"
    :class="{'is-open': isOpen}"
  )
    BaseIcon(
      data="@icon/message-circle.svg"
      width="18"
      height="18"
      :fill="false"
    )

  transition(name="rejected-message__window")
    .rejected-message__window(
      v-if="isOpen"
    )
      button.rejected-message__close-btn(
        @click="isOpen = false"
      )
        BaseIcon(
          data="@icon/close.svg"
          width="9"
          height="9"
        )
      .rejected-message__header
        BaseIcon.rejected-message__header-logo(
          data="@icon/logo.svg"
          width="24"
          height="24"
          color="currentColor _ _"
        )
        .rejected-message__header-title Moderator
      .rejected-message__content
        | Good afternoon&#160;
        span.rejected-message__content-name {{ projectAuthor.full_name }}
        |.<br>{{ message }}
</template>

<script>
import BaseButton from "@/components/BaseButton";
import { mixin as VueClickAway } from 'vue3-click-away'
export default {
  name: "vizRejectedMessage",
  mixins: [VueClickAway],
  components: {BaseButton},
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    message: String
  },
  computed: {
    projectAuthor () {
      return this.$store.state.activeProject.project.owner
    }
  },
  methods: {
    onClickAway () {
      this.isOpen = false
    },
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.rejected-message {
  position: relative;
  &__activator:not(.is-open) {
    background: #FF3F00;
    box-shadow: 0 5px 10px 0 rgba(255,63,0,0.20);
    &:hover {
      background: #FF6C00;
    }
  }
  &__close-btn {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 8px;
    top: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    background: $gray;
    padding: 0;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      color: $blue;
    }
  }
  &__window {
    padding: 24px;
    border-radius: 16px;
    right: 0;
    top: calc(100% + 8px);
    position: absolute;
    background: $white;
    width: 360px;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.05);
    z-index: 100;
    &-enter-active,
    &-leave-active {
      transition: all 0.2s;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &-logo {
      color: $black;
      fill: $yellow;
      display: block;
      margin-right: 10px;
    }
    &-title {
      font-weight: bold;
      font-size: 10px;
      letter-spacing: -0.4px;
    }
  }
  &__content {
    font-size: 12px;
    line-height: 1.5;
    padding-left: 34px;
    &-name {
      font-weight: bold;
      color: $blue;
    }
  }
}
</style>
