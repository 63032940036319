<template lang="pug">
mq-responsive(
  target="xl+"
)
  viz-card(
    title="Material library"
    close-button
    @closeCard="$emit('closeCard')"
  )
    //template(
    //  #top
    //)
    //    .material-library-select-wrap
    //      v-select(
    //        v-model="selected"
    //        :options="textureCategories"
    //        :clearable="false"
    //      )

    .material-library
      .material-library__card
        .material-library__texture-wrap(
          v-for="texture in textures"
          :key="texture.data.id"
        )
          button.material-library__texture(
            :class="{'material-library__texture_active': texture.id === activeTextureId}"
            @click="$store.commit('activeProject/applyTexture', texture)"
            v-tooltip="{theme: 'simple-title', content: texture.enName || texture.name}"
          )
            img.material-library__texture-img(
              :src="texture.data.presets[0].previewUrl"
              :alt="texture.data.name"
            )
mq-responsive(
  target="lg-"
)
  .material-library
    .material-library__card
      .material-library__texture-wrap(
        v-for="texture in textures"
        :key="texture.data.id"
      )
        button.material-library__texture(
          :class="{'material-library__texture_active': texture.id === activeTextureId}"
          @click="$store.commit('activeProject/applyTexture', texture)"
        )
          img.material-library__texture-img(
            :src="texture.data.presets[0].previewUrl"
            :alt="texture.data.name"
          )
</template>

<script>
import VizCard from './vizCard'
import vSelect from 'vue-select'
import SelectArrow from "../icons/SelectArrow"
import { MqResponsive } from "vue3-mq"
export default {
  name: 'vizMaterialLibrary',
  components: { SelectArrow, VizCard, vSelect, MqResponsive },
  emits: ['closeCard'],
  data () {
    return {
      selected: 'All Materials'
    }
  },

  computed: {
    textures () {
      const materialName = this.$store.state.activeProject.activeMaterial.name
      return this.$store.getters['textures/getTexturesMaterial'](materialName)
    },
    activeTextureId () {
      return this.$store.state.activeProject.activeLayer.settings.textureId
    }
  }
}
</script>

<style lang="scss" scoped>

  .material-library-select-wrap {
    margin-bottom: 12px;
    &:deep {
      .v-select {
        width: 100%;
        display: block;
        border: 1px solid $gray;
        border-radius: 8px;
      }
      .material-design-icon__svg {
        display: block;
      }
      .vs__dropdown-toggle {
        height: 40px;
      }
      .vs__selected-options {
        padding: 0 2px 0 8px;
      }
      .vs__selected {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.244444px;
        color: #000000;
      }
      .vs__dropdown-menu {
        top: calc(100% - 10px);
        left: -1px;
        width: calc(100% + 2px);
        border-radius: 0 0 8px 8px;
        box-shadow: none;
        padding: 10px 0;
        border: 1px solid $gray;
        border-top: none;
      }
      .vs__dropdown-option {
        padding: 4px 17px;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.244444px;
        &--selected {
          color: $blue;
        }
      }
    }
  }
  .material-library {
    &__card {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
      @include media-breakpoint-up(xl) {
        margin: 0;
        padding: 17px;
        border-radius: 8px;
        background: $gray;
      }
    }
    &__texture-wrap {
      width: 16.6666%;
      padding: 5px;
      @include media-breakpoint-up(xl) {
        width: 25%;
        padding: 3px;
      }
    }
    &__texture {
      display: block;
      padding: 0;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;
      &_active {
        box-shadow: 0 0 0 2px $blue;
      }
      &-img {
        display: block;
        width: 100%;
        transform: scale(1.2);
      }
    }
  }
</style>
