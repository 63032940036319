export var data_query = function(){

  if(window.location.search !== ""){

    this.dataQuery = {}

    let queryArray = window.location.search.slice(1).split('&')
    for(let i=0, len = queryArray.length; i<len; i++){
      let q = queryArray[i].split('=')
      this.dataQuery[q[0]] = Number(q[1]) || q[1]
    }

  }

}
