import {initNodeMaterial} from '../../../modules/materials/initNodeMaterial/index.js';

export const rerenderScene = async function(scene){

  this.dataScene.scene.images.forEach(img => {
    if(img.__texture){
      delete img.__texture;
    }
  });

  scene.materials.forEach(async material => {

    const mId = material.id;

    let materialIndex = this.dataScene.scene.materials.findIndex(m => m.id === mId);

    if(materialIndex >= 0){
      this.dataScene.scene.materials[materialIndex] = material;

      this.materials.ReadyNodeMaterials[mId] = await initNodeMaterial.bind(this)(this.dataScene.scene.materials[materialIndex]);
      this.materials.ReadyNodeMaterials[mId].needsUpdate = true;

      for(let i=0, len=this.dataScene.scene.models[0].meshes.length; i<len; i++){
        const meshLink = this.dataScene.scene.models[0].meshes[i];
        if (meshLink.materialId === mId) {
          const mesh = this.scene.getObjectByName(meshLink.meshName);
          mesh.material = this.materials.ReadyNodeMaterials[mId];
        }
      }

    }

  }, this);

};
