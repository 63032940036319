import {
  Vector3,
} from './../../../../../../build/three.module.js';

async function urlToFile (url, filename, mimeType) {
  return (
    await fetch(url)
      .then(res => {
        return res.arrayBuffer();
      })
      .then(buf => {
        return new File([buf], filename,{ type: mimeType });
      })
  );
}

async function getScreenshot(data){

  /*
  * data = {
  *   format: string, // PNG/JPG/WEBP
  *   transparent: Boolean,
  *   size.width: Number,
  *   size.height: Number,
  * }
  */

  // console.log('-', 'данные для рендера превью', data);
  // console.log(0, 'позиция камеры из данных превью', this.dataScene.scene.previews[0].camera.position);

  this.renderer.setClearColor(0xF6F6F8, (data.transparent ? 0 : 1));

  const w = this.DOM.drawing.clientWidth;
  const h = this.DOM.drawing.clientHeight;

  this.camera.lookAt(new Vector3(0, 0, 0));

  if(window.devicePixelRatio > 1){
    data.size.width /= 2;
    data.size.height /= 2;
  }

  // this.camera.position.z = 3.952; //this.dataScene.scene.previews[0].camera.position.z;
  // console.log(1, 'позиция камеры до рендера скриншота', this.camera.position);
  this.camera.aspect = data.size.width / data.size.height;
  this.camera.updateProjectionMatrix();
  this.controls.orbitControl.update();
  this.renderer.setSize(data.size.width, data.size.height);
  this.renderer.render(this.scene, this.camera);

  const imgData = await this.renderer.domElement.toDataURL('image/'+data.format.toLowerCase());

  // console.log(2, 'позиция камеры после рендера скриншота', this.camera.position);

  this.camera.aspect = w / h;
  this.camera.updateProjectionMatrix();
  this.controls.orbitControl.update();
  this.renderer.setSize(w, h);
  this.renderer.render(this.scene, this.camera);

  this.renderer.setClearColor(0xF6F6F8, 1);

  const file = await urlToFile(imgData, `preview-${this.randomString(8)}.${data.format.toLowerCase()}`, 'image/'+data.format.toLowerCase());

  // console.log('Объект 3D приложения', this);

  return file;
}

export {
  getScreenshot,
};
