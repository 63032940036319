<template lang="pug">
viz-card(
  :title="mobileTab"
  :style="{minHeight: '220px', flexShrink: 100}"
  :close-button="mq.lgMinus"
  @closeCard="$store.commit('activeProject/closeMobileTab')"
)
  template(
    #headBefore
  )
    mq-responsive(
      target="lg-"
    )
      BaseIcon.adjustment-back(
        v-if="mobileTab !== 'Material'"
        data="@icon/chevron_left.svg"
        width="24"
        height="24"
        @click="mobileTab = 'Material'"
      )

  template(
    #top
  )
    mq-responsive.d-flex.justify-content-between.align-items-center.mobile-tabs(
      target="lg-"
    )
      viz-color-controller(
        @click.native="mobileTab = 'Base color'"
        :is-active="mobileTab === 'Base color'"
        v-if="activeLayerIndex === 0"
      )
      viz-material-controller(
        @click.native="mobileTab = 'Edit material'"
        :is-active="mobileTab === 'Edit material'"
      )

  mq-responsive(
    target="xl+"
  )
    viz-color-controller(
      @click.native="$store.commit('activeProject/switchAdditionalSetting', 'color-picker')"
      :is-active="activeTab === 'color-picker'"
      v-if="activeLayerIndex === 0"
    )
    viz-material-controller(
      @click.native="$store.commit('activeProject/switchAdditionalSetting', 'material-library')"
      :is-active="activeTab === 'material-library'"
    )

  template(
    v-if="mobileTab === 'Material'"
  )
    viz-controller(
      v-for="adjustmentName in textureAdjustments"
      :key="adjustmentName"
      :adjustmentPath="'texture.' + adjustmentName"
    )

  template(
    v-if="mobileTab === 'Base color'"
  )
    viz-color-picker

  template(
    v-if="mobileTab === 'Edit material'"
  )
    viz-material-library
</template>

<script>
import VizCard from './vizCard'
import VizController from './vizController'
import VizColorController from './vizColorController'
import VizMaterialController from "./vizMaterialController";
import { MqResponsive } from "vue3-mq"
import VizColorPicker from "@/components/player/vizColorPicker";
import VizMaterialLibrary from "@/components/player/vizMaterialLibrary";
export default {
  name: 'vizAdjustments',

  inject: ["mq"],

  components: {
    VizMaterialLibrary,
    VizColorPicker, VizMaterialController, VizColorController, VizController, VizCard, MqResponsive },

  data () {
    return {
      mobileTab: 'Material'
    }
  },

  computed: {
    activeTab () {
      return this.$store.state.activeProject.additionalSettingTab
    },
    activeLayer () {
      return this.$store.state.activeProject.activeLayer
    },
    activeLayerIndex () {
      return this.$store.state.activeProject.activeMaterial.activeLayerIndex
    },
    textureAdjustments () {
      const exclude = [
        'color',
        'opacityTexture'
      ]

      const adjustments = []

      _.forEach(this.activeLayer.settings.texture, (item, key) => {
        if (!_.includes(exclude, key) && item?.visible && item?.method) {
          if (item.type === 'slider' && !item?.scope && !item?.step) return
          adjustments.push(key)
        }
      })

      return adjustments
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-tabs {
  margin-bottom: 22px;
}
</style>
