<script>
export default {
  name: "CreateProject",
  inject: ["mq"],
  created() {
    this.$store.dispatch('projects/createProject', { id: parseInt(this.$route.params.id), name: 'My new project', isMobile: this.mq.smMinus, replaceLink: true, browser: this.$sniffr.browser })
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.$store.dispatch('projects/createProject', { id: parseInt(to.params.id), name: 'My new project', isMobile: this.mq.smMinus, replaceLink: true, browser: this.$sniffr.browser })
  }
}
</script>
