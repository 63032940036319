<template lang="pug">
.settings
  .settings__switcher-wrap
    viz-setting-mobile-item(
      tab-name="viz-layers"
    )
      template(
        #activator
      )
        img(
          v-if="layerPreview"
          :src="layerPreview"
        )
        BaseIcon(
          v-else
          data="@icon/image-icon.svg"
          width="18"
          height="18"
          :fill="false"
        )
      viz-layers

  .settings__switcher-wrap(
    v-if="activeMaterial.activeLayerIndex !== 0"
  )
    viz-mobile-design-uploader

  .settings__switcher-wrap
    viz-setting-mobile-item(
      tab-name="viz-adjustments"
    )
      template(
        #activator
      )
        img(
          v-if="activeLayer.settings.texture.previewUrl"
          :src="activeLayer.settings.texture.previewUrl"
          :style="{transform: 'scale(0.7)'}"
        )
      viz-adjustments

  .settings__switcher-wrap
    viz-setting-mobile-item(
      tab-name="viz-menu"
    )
      template(
        #activator
      )
        BaseIcon(
          data="@icon/burger-circles-row.svg"
          width="25"
          height="25"
        )
      viz-menu
</template>

<script>
import VizSettingMobileItem from '@/components/player/vizSettingMobileItem'
import VizLayers from "@/components/player/vizLayers";
import VizAdjustments from "@/components/player/vizAdjustments";
import VizMenu from "@/components/player/vizMenu";
import VizInputDesign from "@/components/player/vizInputDesign";
import VizMobileDesignUploader from "@/components/player/vizMobileDesignUploader";
export default {
  name: "vizSettingsIpad",

  components: {VizMobileDesignUploader, VizInputDesign, VizMenu, VizAdjustments, VizLayers, VizSettingMobileItem },

  computed: {
    activeLayer () {
      return this.$store.state.activeProject.activeLayer
    },
    activeMaterial () {
      return this.$store.state.activeProject.activeMaterial
    },
    layerPreview () {
      if (this.activeMaterial.activeLayerIndex === 0 && this.activeMaterial.iconList) {
        const imagesList = this.$store.state.activeProject.activeVariation.scene.images
        const previewIndex = _.findIndex(imagesList, ['id', this.activeMaterial.iconList])
        return imagesList[previewIndex].url
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  position: absolute;
  right: 16px;
  top: 20px;
  &__switcher-wrap {
    margin-bottom: 8px;
  }
}
</style>
