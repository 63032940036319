<template lang="pug">
ul.accordion
  slot
</template>

<script>
export default {
  name: "accordion-wrap",
  props: {
    activeItem: Number
  },
  data () {
    return {
      Accordion: {
        count: 0,
        active: null
      }
    }
  },
  provide () {
    return { Accordion: this.Accordion }
  },
  created () {
    if (this.activeItem || this.activeItem === 0) {
      this.Accordion.active = this.activeItem
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item:last-child {
    border-bottom: none;
  }
}
</style>
