import {
  TextureLoader,
  MathUtils,
  RepeatWrapping,
  ClampToEdgeWrapping,
  Light,
} from './../../../../../../build/three.module.js';
import {
  NodeMaterialLoader,
  NodeMaterialLoaderUtils
} from './../../../../../jsm/loaders/NodeMaterialLoader.js';
import {
  TextureNode,
  TextureCubeNode,
  UVTransformNode,
  UVNode,
  StandardNodeMaterial,
  FloatNode,
  ColorNode,
  MathNode,
  SwitchNode,
  // BumpMapNode,
  OperatorNode,
  NormalMapNode,
} from './../../../../../jsm/nodes/Nodes.js';
//import {shaders} from './shaders/index.js';


export const initNodeMaterial = async function(__dataMaterial, dataImage = null){

  /*
  dataImage = {
    layerId: 0,
    image: {
      "url": "http://domain.com/path/file_design.png",
      "type": "designe",
      "id": "imageId_00"
    },
  }
  */

  //console.log('material: ', __dataMaterial);
  const _this = this;

  let normalLoad = new FloatNode(1);

  let randomString = this.randomString;

  // обновление картинки дизайна на слое
  if(dataImage){
    if(dataImage.layerId != 0){
      _this.dataScene.scene.images.push(dataImage.image);
      __dataMaterial.layers[dataImage.layerId].settings.designMap = {
        id: dataImage.image.id
      };
      const dataMaterial = __dataMaterial.layers[dataImage.layerId];
      dataMaterial.settings.designMap.__texture = new TextureNode(getTexture(dataMaterial.settings.designMap, 'id', 1));
      dataMaterial.settings.designMap.__texture.uv = new UVTransformNode();
      dataMaterial.settings.designMap.__texture.uv.setUvTransform(0, 0, 1, 1, MathUtils.degToRad(0)); //, 0.2, 0.2);
      //designMap.value.wrapS = designMap.value.wrapT = RepeatWrapping;
      dataMaterial.settings.designMap.__texture.value.wrapS = dataMaterial.settings.designMap.__texture.value.wrapT = ClampToEdgeWrapping;
    }
  }

  const AOShadowImg = new TextureNode(getTexture(__dataMaterial.globalSettings.aoMap));
  AOShadowImg.uv = new UVTransformNode();
  AOShadowImg.uv.uv = new UVNode(__dataMaterial.globalSettings.uvAoMap.channel); // uv2 for example

  if(!_this.AOMapVisible){
    _this.AOMapVisible = new FloatNode(0);
  }
  const AOShadow = new MathNode(
    _this.AOMapVisible,
    new FloatNode(1),
    AOShadowImg,
    MathNode.MIX
  );

  let alphaMapImg = null;
  if(__dataMaterial.globalSettings.alphaMap){
    alphaMapImg = new TextureNode(getTexture(__dataMaterial.globalSettings.alphaMap));
    alphaMapImg.uv = new UVTransformNode();
    alphaMapImg.uv.uv = new UVNode(__dataMaterial.globalSettings.uvAoMap.channel); // uv2 for example
  }

  function getTexture(obj, nameId = 'id', design = false, normal = false){
    const textureImages = _this.dataScene.scene.images.find(texture => texture.id === obj[nameId]);
    let pathJPG = textureImages.url;

    if(!design){
      if(textureImages.url !== '/images/Normal0.png' && textureImages.url !== '/images/ffffff.png'){
        pathJPG = (textureImages.url.slice(0, -4)) + '@1.jpg?v='+Date.now();
      }
    }else{
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if(textureImages.url.indexOf('data:application') !== 0){
        pathJPG = isSafari 
          ? textureImages.url
          : (textureImages.url.replace(/\.[^.]+$/, '')) + '@1.webp'+'?v='+Date.now();
      }
    }

    let texture = textureImages.__texture;
    if(!texture){
      if(_this.loadingManager){
        texture = textureImages.__texture = new TextureLoader(_this.loadingManager || {}).load( pathJPG );
      }else{
        texture = textureImages.__texture = new TextureLoader().load( pathJPG );
      }
      texture.wrapS = texture.wrapT = RepeatWrapping;
    }
    return texture;
  }

  const normalMap_0 = new TextureNode(
    (
      (_this.loadingManager)
        ? new TextureLoader(_this.loadingManager || {}).load('/images/Normal0.jpg')
        : new TextureLoader().load('/images/Normal0.jpg')
    )
  );
  normalMap_0.wrapS = normalMap_0.wrapT = RepeatWrapping;

  // MATERIAL
  const mtl = new StandardNodeMaterial();

  // =======================
  // =======================
  // =======================
  // =======================
  // START | GLOBAL settings

  // переводим данные слоя-материала в формат MaterialNode
  for(let i=0, len=__dataMaterial.layers.length; i<len; i++){
    const dataMaterial = __dataMaterial.layers[i];

    if(!dataMaterial.init && dataMaterial.settings.texture){

      dataMaterial.init = true;

      //uv designe offset
      dataMaterial.settings.uvDesignMap.offset.x =
        new FloatNode(dataMaterial.settings.uvDesignMap.offset.x);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(0, 'object', 'uvDesignMap.offset.x', dataMaterial.settings.uvDesignMap.offset.x);
      dataMaterial.settings.uvDesignMap.offset.y =
        new FloatNode(dataMaterial.settings.uvDesignMap.offset.y);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(1, 'object', 'uvDesignMap.offset.y', dataMaterial.settings.uvDesignMap.offset.y);

      // uv designe scale
      dataMaterial.settings.uvDesignMap.scale =
        new FloatNode(dataMaterial.settings.uvDesignMap.scale);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(2, 'object', 'uvDesignMap.scale', dataMaterial.settings.uvDesignMap.scale);
      // uv designe rotation
      // dataMaterial.settings.uvDesignMap.rotation =
        // dataMaterial.settings.uvDesignMap.rotation;
      if(_this.dataQuery && _this.dataQuery.dev) console.log(3, 'object', 'uvDesignMap.rotation', dataMaterial.settings.uvDesignMap.rotation);

      //uv texture offset
      dataMaterial.settings.uvTextureMap.offset.x =
        new FloatNode(dataMaterial.settings.uvTextureMap.offset.x);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(4, 'object', 'uvTextureMap.offset.x', dataMaterial.settings.uvTextureMap.offset.x);
      dataMaterial.settings.uvTextureMap.offset.y =
        new FloatNode(dataMaterial.settings.uvTextureMap.offset.y);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(5, 'object', 'uvTextureMap.offset.y', dataMaterial.settings.uvTextureMap.offset.y);

      // uv texture rotation
      // dataMaterial.settings.uvTextureMap.rotation =
        // new FloatNode(dataMaterial.settings.uvTextureMap.rotation);

      dataMaterial.settings.texture.color.value =
        new ColorNode(dataMaterial.settings.texture.color.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(6, 'object', 'texture.color.value', dataMaterial.settings.texture.color.value);

      dataMaterial.settings.designMapOpacity.value =
        new FloatNode(dataMaterial.settings.designMapOpacity.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(7, 'object', 'designMapOpacity.value', dataMaterial.settings.designMapOpacity.value);
      dataMaterial.settings.designMapOpacity.visible =
        new FloatNode(dataMaterial.settings.designMapOpacity.visible);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(8, 'object', 'designMapOpacity.visible', dataMaterial.settings.designMapOpacity.visible);

      dataMaterial.settings.texture.textureMapOpacity.value =
        new FloatNode(dataMaterial.settings.texture.textureMapOpacity.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(9, 'object', 'dataMaterial.settings.texture.textureMapOpacity.value', dataMaterial.settings.texture.textureMapOpacity.value);
      dataMaterial.settings.texture.textureMapOpacity.visible =
        new FloatNode(dataMaterial.settings.texture.textureMapOpacity.visible);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(10, 'object', 'dataMaterial.settings.texture.textureMapOpacity.visible', dataMaterial.settings.texture.textureMapOpacity.visible);

      dataMaterial.settings.texture.roughness.value =
        new FloatNode(dataMaterial.settings.texture.roughness.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(11, 'object', 'dataMaterial.settings.texture.roughness.value', dataMaterial.settings.texture.roughness.value);
      dataMaterial.settings.texture.roughnessTexture.value =
        new FloatNode(dataMaterial.settings.texture.roughnessTexture.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(12, 'object', 'dataMaterial.settings.texture.roughnessTexture.value', dataMaterial.settings.texture.roughnessTexture.value);

      dataMaterial.settings.texture.reflectivity.value =
        new FloatNode(dataMaterial.settings.texture.reflectivity.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(13, 'object', 'dataMaterial.settings.texture.reflectivity.value', dataMaterial.settings.texture.reflectivity.value);
      dataMaterial.settings.texture.reflectivityTexture.value =
        new FloatNode(dataMaterial.settings.texture.reflectivityTexture.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(14, 'object', 'dataMaterial.settings.texture.reflectivityTexture.value', dataMaterial.settings.texture.reflectivityTexture.value);

      dataMaterial.settings.texture.metalness.value =
        new FloatNode(dataMaterial.settings.texture.metalness.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(15, 'object', 'dataMaterial.settings.texture.metalness.value', dataMaterial.settings.texture.metalness.value);
      dataMaterial.settings.texture.metalnessTexture.value =
        new FloatNode(dataMaterial.settings.texture.metalnessTexture.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(16, 'object', 'dataMaterial.settings.texture.metalnessTexture.value', dataMaterial.settings.texture.metalnessTexture.value);

      dataMaterial.settings.texture.aoTextureOpacity.value =
        new FloatNode(dataMaterial.settings.texture.aoTextureOpacity.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(17, 'object', 'dataMaterial.settings.texture.aoTextureOpacity.value', dataMaterial.settings.texture.aoTextureOpacity.value);

      dataMaterial.settings.uvTextureMap.scale =
        __dataMaterial.materialTextureSize /
        dataMaterial.settings.texture.sizeTexture.value;
      if(_this.dataQuery && _this.dataQuery.dev) console.log(18, 'number', 'dataMaterial.settings.uvTextureMap.scale', dataMaterial.settings.uvTextureMap.scale);

      dataMaterial.settings.texture.normalMap.value =
        new FloatNode(dataMaterial.settings.texture.normalMap.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(19, 'object', 'dataMaterial.settings.texture.normalMap.value', dataMaterial.settings.texture.normalMap.value);

      dataMaterial.settings.texture.bevel.value =
        new FloatNode(dataMaterial.settings.texture.bevel.value);
      if(_this.dataQuery && _this.dataQuery.dev) console.log(20, 'object', 'dataMaterial.settings.texture.bevel.value', dataMaterial.settings.texture.bevel.value);

      const uvScaleTextureValue = dataMaterial.settings.uvTextureMap.scale;
      const uvRotationTextureValue = dataMaterial.settings.texture.rotation ? dataMaterial.settings.texture.rotation.value : 0;

      if(dataMaterial.settings.designMap){
        dataMaterial.settings.designMap.__texture = new TextureNode(getTexture(dataMaterial.settings.designMap, 'id', 1));
        dataMaterial.settings.designMap.__texture.uv = new UVTransformNode();
        dataMaterial.settings.designMap.__texture.uv.setUvTransform(0, 0, 1, 1, MathUtils.degToRad(0)); //, 0.2, 0.2);
        //designMap.value.wrapS = designMap.value.wrapT = RepeatWrapping;
        dataMaterial.settings.designMap.__texture.value.wrapS = dataMaterial.settings.designMap.__texture.value.wrapT = ClampToEdgeWrapping;
      }else{
        dataMaterial.settings.designMap = {
          __texture: dataMaterial.settings.texture.color.value
        };
      }

      dataMaterial.settings.texture.textureColor.__texture = new TextureNode(getTexture(dataMaterial.settings.texture.textureColor));
      dataMaterial.settings.texture.textureColor.__texture.uv = new UVTransformNode();
      dataMaterial.settings.texture.textureColor.__texture.uv.setUvTransform(
        0, 0,
        uvScaleTextureValue, uvScaleTextureValue,
        MathUtils.degToRad(uvRotationTextureValue)
      ); //, 0.2, 0.2);
      dataMaterial.settings.texture.textureColor.__texture.value.wrapS =
      dataMaterial.settings.texture.textureColor.__texture.value.wrapT =
        RepeatWrapping;

      dataMaterial.settings.texture.textureRGB.__texture = new TextureNode(getTexture(dataMaterial.settings.texture.textureRGB));
      dataMaterial.settings.texture.textureRGB.__texture.uv = new UVTransformNode();
      dataMaterial.settings.texture.textureRGB.__texture.uv.setUvTransform(
        0, 0,
        uvScaleTextureValue, uvScaleTextureValue,
        MathUtils.degToRad(uvRotationTextureValue)
      ); //, 0.2, 0.2);
      dataMaterial.settings.texture.textureRGB.__texture.value.wrapS =
      dataMaterial.settings.texture.textureRGB.__texture.value.wrapT =
        RepeatWrapping;

      if(dataMaterial.settings.texture.normalMap.idtexture && dataMaterial.settings.texture.normalMap.idtexture !== ''){
        dataMaterial.settings.texture.normalMap.__texture = new TextureNode(getTexture(dataMaterial.settings.texture.normalMap, 'idtexture', false, 1));
        dataMaterial.settings.texture.normalMap.__texture.uv = new UVTransformNode();
        dataMaterial.settings.texture.normalMap.__texture.uv.setUvTransform(
          0, 0,
          uvScaleTextureValue, uvScaleTextureValue,
          MathUtils.degToRad(uvRotationTextureValue)
        ); //, 0.2, 0.2);
        dataMaterial.settings.texture.normalMap.__texture.value.wrapS =
        dataMaterial.settings.texture.normalMap.__texture.value.wrapT =
          RepeatWrapping;
      }
    }

    if(dataMaterial.settings.designMap && dataMaterial.settings.designMap.id && !dataMaterial.settings.designMap.__texture){
      dataMaterial.settings.designMap.__texture = new TextureNode(getTexture(dataMaterial.settings.designMap, 'id', 1));
      dataMaterial.settings.designMap.__texture.uv = new UVTransformNode();
      dataMaterial.settings.designMap.__texture.uv.setUvTransform(0, 0, 1, 1, MathUtils.degToRad(0)); //, 0.2, 0.2);
      //designMap.value.wrapS = designMap.value.wrapT = RepeatWrapping;
      dataMaterial.settings.designMap.__texture.value.wrapS = dataMaterial.settings.designMap.__texture.value.wrapT = ClampToEdgeWrapping;
    }else if(dataMaterial.settings.designMap === null){
      dataMaterial.settings.designMap = {
        __texture: dataMaterial.settings.texture.color.value
      };
    }

  }
  // END   | GLOBAL settings
  // =======================
  // =======================
  // =======================
  // =======================



  // ===================================================================================
  // ===================================================================================
  // START SHADER ======================================================================
  // ===================================================================================
  // ===================================================================================
  const shaders = {
    color: [], // шейдеры дизайна
    roughness: [], // шейдеры широховатости
    reflectivity: [], // шейдеры отражения
    metalness: [], // шейдеры металических эффектов
    normal: [], // шейдеры карты нормали
  };

  for(let i=0, len=__dataMaterial.layers.length; i<len; i++){

    if(__dataMaterial.layers[i].settings.texture){

      {
        // START | layer 1
        const dataMaterial = __dataMaterial.layers[i];

        const _white = new FloatNode(1);
        const _black = new FloatNode(0);

        // создаем маску для слоя
        let mask = dataMaterial.settings.designMap.__texture;
        if(!mask){
          dataMaterial.settings.designMap = {
            __texture: dataMaterial.settings.texture.color.value
          };
        }
        const maskAlphaOn = dataMaterial.settings.designMapOpacity.visible; // OpacityLayers
        const maskW = new FloatNode(0); // Layers none mask
        dataMaterial.settings.maskAlphaChannel = new MathNode(
          maskW,
          new SwitchNode(mask, 'w'),
          maskAlphaOn,
          MathNode.MIX
        );

        // START | color | layer i
        {
          const color = dataMaterial.settings.texture.color.value;
          const aoTextureOpacity = dataMaterial.settings.texture.aoTextureOpacity.value;

          const colorAOtextureOpacity = new MathNode(
            _white,
            dataMaterial.settings.texture.textureRGB.__texture, // Texture
            aoTextureOpacity, // AO Texture Прозрачность АО мап Значение инвертировано
            MathNode.MIX
          );

          // блендинг
          // const colorAaMul = new OperatorNode(
          //   colorAOtextureOpacity,
          //   color,
          //   OperatorNode.MUL
          // );

          const opacityDesignMap = dataMaterial.settings.designMapOpacity.value;
          const opacityTextureMap = dataMaterial.settings.texture.textureMapOpacity.value;

          const layerMap = new MathNode(
            color,
            dataMaterial.settings.designMap.__texture,
            opacityDesignMap, // DesigneMap opacity
            MathNode.MIX
          );

          const layerOutput2 = new MathNode(
            layerMap,
            _white,
            opacityTextureMap, // TextureMap opacity
            MathNode.MIX
          );
          const layerOutput = new OperatorNode(
            colorAOtextureOpacity,
            layerOutput2,
            OperatorNode.MUL
          );

          shaders.color[i] = layerOutput; // добавление шедера слоя под своим id - i
        }
        /*
        {
          const color = dataMaterial.settings.texture.color.value;

          const opacityDesignMap = dataMaterial.settings.designMapOpacity.value;
          const opacityTextureMap = dataMaterial.settings.textureMapOpacity.value;

          const layerMap = new MathNode(
            color,
            dataMaterial.settings.designMap.__texture,
            opacityDesignMap, // DesigneMap opacity
            MathNode.MIX
          );

          const layerOutput = new MathNode(
            layerMap,
            dataMaterial.settings.texture.textureColor.__texture,
            opacityTextureMap, // TextureMap opacity
            MathNode.MIX
          );

          shaders.color[i] = layerOutput; // добавление шедера слоя под своим id - i
        }
        */
        // END | color | layer i

        // START | roughness | layer i
         {
          const power = dataMaterial.settings.texture.roughness.value; // Roughness
          const textureOpacity = dataMaterial.settings.texture.roughnessTexture.value; // Roughness texture



          // const layerOutput = new MathNode(
          //   power,
          //   floatTextureRGBinvert,
          //   textureOpacity,
          //   MathNode.MIX
          // );
          const roughnessTextureValue = new MathNode(
            _white,
            dataMaterial.settings.texture.textureRGB.__texture,
            textureOpacity,
            MathNode.MIX
          );
          
          const powerIn = new MathNode(
            _white,
            _black,
            power,
            MathNode.MIX
          );

          const layerOutputIn = new OperatorNode(
            roughnessTextureValue,
            powerIn,
            OperatorNode.MUL
          );
          const layerOutput = new MathNode(
            _white,
            _black,
            layerOutputIn,
            MathNode.MIX
          );
          shaders.roughness[i] = layerOutput; // добавление шедера слоя под своим id - i
        }
        // END | roughness | layer i

        // START | metalness | layer i
        {
          const power = dataMaterial.settings.texture.metalness.value; // metalness
          const textureOpacity = dataMaterial.settings.texture.metalnessTexture.value; // Roughness texture

          // const layerOutput = new MathNode(
          //   power,
          //   dataMaterial.settings.texture.textureRGB.__texture,
          //   textureOpacity,
          //   MathNode.MIX
          // );
          const metalnessTextureValue = new MathNode(
            _white,
            dataMaterial.settings.texture.textureRGB.__texture,
            textureOpacity,
            MathNode.MIX
          );
          const layerOutput = new OperatorNode(
            metalnessTextureValue,
            power,
            OperatorNode.MUL
          );

          shaders.metalness[i] = layerOutput; // добавление шедера слоя под своим id - i

        }
        // END | metalness | layer i

        // START | reflectivity | layer i
        {
          const power = dataMaterial.settings.texture.reflectivity.value; // reflectivity
          const textureOpacity = dataMaterial.settings.texture.reflectivityTexture.value; // reflectivity texture

          // const layerOutput = new MathNode(
          //   power,
          //   dataMaterial.settings.texture.textureRGB.__texture,
          //   textureOpacity,
          //   MathNode.MIX
          // );
          const reflectivityTextureValue = new MathNode(
            _white,
            dataMaterial.settings.texture.textureRGB.__texture,
            textureOpacity,
            MathNode.MIX
          );
          const layerOutput = new OperatorNode(
            reflectivityTextureValue,
            power,
            OperatorNode.MUL
          );

          shaders.reflectivity[i] = layerOutput; // добавление шедера слоя под своим id - i

        }
        // END | reflectivity | layer i 

        // START | normal | layer i
        {

          if(dataMaterial.settings.texture.normalMap.idtexture && dataMaterial.settings.texture.normalMap.idtexture !== ''){

            // const power = new FloatNode(1); //dataMaterial.settings.texture.normalMap.value; // normalMap
            // const normalOpacity = dataMaterial.settings.texture.normalMap.value; // normalMap texture

            const normalMap_0 = new NormalMapNode(new ColorNode('#8080ff'));
            const normalMap = new NormalMapNode(dataMaterial.settings.texture.normalMap.__texture);
            normalMap.scale = dataMaterial.settings.texture.normalMap.value; //new FloatNode( .3 );

            const layerOutput = new MathNode(
              normalMap_0,
              normalMap,
              normalLoad,
              MathNode.MIX
            );

            // const normalMap = new NormalMapNode(
            //   layerOutput
            // );
            shaders.normal[i] = normalMap; //layerOutput; // добавление шедера слоя под своим id - i

          }

        }
        // END | normal | layer i
      }

    }
  }
  // END | layer i

  // ===================================================================================
  // ===================================================================================
  // END   SHADER ======================================================================
  // ===================================================================================
  // ===================================================================================


  // ===================
  // start | MIX shaders
  // ===================

  const mixes = {
    color: [], // микс дизайна
    roughness: [], // микс широховатости
    reflectivity: [], // микс отражения
    metalness: [], // микс металических эффектов
    normal: [], // микс карты нормали
    alpha: [], // микс альфа
  };

  for(let i=1, len=shaders.color.length; i<(len == 1 ? 2 : len); i++){

    { // start color
      if(len == 1){
        mixes.color[i-1] = shaders.color[i-1];
      }else{
        mixes.color[i-1] = new MathNode(
        (i==1
          ? shaders.color[i-1]
          : mixes.color[i-2]
        ),
          shaders.color[i],
          __dataMaterial.layers[i].settings.maskAlphaChannel,
          MathNode.MIX
        );
      }
    } // end color

    { // start roughness
      if(len == 1){
        mixes.roughness[i-1] = shaders.roughness[i-1];
      }else{
        mixes.roughness[i-1] = new MathNode(
          (i==1
            ? shaders.roughness[i-1]
            : mixes.roughness[i-2]
          ),
          shaders.roughness[i],
          __dataMaterial.layers[i].settings.maskAlphaChannel,
          MathNode.MIX
        );
      }
    } // end roughness

    { // start reflectivity
      if(len == 1){
        mixes.reflectivity[i-1] = shaders.reflectivity[i-1];
      }else{
        mixes.reflectivity[i-1] = new MathNode(
          (i==1
            ? shaders.reflectivity[i-1]
            : mixes.reflectivity[i-2]
          ),
          shaders.reflectivity[i],
          __dataMaterial.layers[i].settings.maskAlphaChannel,
          MathNode.MIX
        );
      }
    } // end reflectivity

    { // start metalness
      if(len == 1){
        mixes.metalness[i-1] = shaders.metalness[i-1];
      }else{
        mixes.metalness[i-1] = new MathNode(
          (i==1
            ? shaders.metalness[i-1]
            : mixes.metalness[i-2]
          ),
          shaders.metalness[i],
          __dataMaterial.layers[i].settings.maskAlphaChannel,
          MathNode.MIX
        );
      }
    } // end metalness

    { // start normal
      if(len == 1){
        mixes.normal[i-1] = shaders.normal[i-1];
      }else{
        mixes.normal[i-1] = new MathNode(
          (i==1
            ? shaders.normal[i-1]
            : mixes.normal[i-2]
          ),
          shaders.normal[i],
          __dataMaterial.layers[i].settings.maskAlphaChannel,
          MathNode.MIX
        );
      }
    } // end normal

    { // start alpha
      if(len == 1){
        mixes.alpha[i-1] = new FloatNode(1);
      }else{
        mixes.alpha[i-1] = new MathNode(
          (i==1
            ? new FloatNode(1)
            : mixes.alpha[i-2]
          ),
          new FloatNode(1),
          __dataMaterial.layers[i].settings.maskAlphaChannel,
          MathNode.MIX
        );
      }
    } // end alpha

  }
  // ===================
  // end   | MIX shaders
  // ===================


  // AO shadow map
  // const AOShadowAdd = new OperatorNode(
  //   AOShadow,
  //   new FloatNode(1),
  //   OperatorNode.ADD
  // );

  const colorAO = new OperatorNode(
    AOShadow,
    mixes.color.slice(-1)[0],
    OperatorNode.MUL
  );

  // Environment

  const cubeMapNode = new TextureCubeNode(_this.environments.maps[0]); //(_this.dataQuery.hdr ? 1 : _this.environments.active)]);
  const cubeMapAO = new OperatorNode(
    AOShadow,
    cubeMapNode,
    OperatorNode.MUL
  );


  // start set shaders
  mtl.color = colorAO;
  mtl.shadow = AOShadow;
  mtl.ao = AOShadow;
  mtl.environment = cubeMapNode;

  if(alphaMapImg){
    mtl.alpha = alphaMapImg;
  }else{
    mtl.alpha = mixes.alpha.slice(-1)[0];
  }

  mtl.roughness = mixes.roughness.slice(-1)[0];
  mtl.metalness = mixes.metalness.slice(-1)[0];
  mtl.reflectivity = mixes.reflectivity.slice(-1)[0];
  mtl.normal = mixes.normal.slice(-1)[0];
  // end set shaders

  return mtl;

};

