function closeVis3D(clallback){

  this.renderer.forceContextLoss();
  // this.renderer.context = null;
  this.renderer.domElement = null;
  this.renderer = null;

  if(clallback) clallback();

}

export {
  closeVis3D,
};