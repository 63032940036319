<template lang="pug">
.setting-item(
  v-click-away="onClickAway"
)
  .setting-item__btn(
    @click="toggle"
    :class="{'setting-item__btn_active': tabName === mobileTab}"
  )
    slot(
      name="activator"
    )

  .setting-item__content(
    v-if="tabName === mobileTab"
  )
    slot
  standard-modal(
    v-model="modalOpen"
    :name="`modal-${tabName}`"
    @modalClose="modalOpen = false"
  )
    slot
</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'
import StandardModal from "@/components/modals/StandardModal";

export default {
  name: "vizSettingMobileItem",
  inject: ["mq"],
  components: {StandardModal},
  mixins: [VueClickAway],
  props: {
    tabName: String
  },
  data () {
    return {
      modalOpen: false
    }
  },
  computed: {
    mobileTab () {
      return this.$store.state.activeProject.activeMobileTab
    }
  },
  methods: {
    onClickAway () {
      if (this.tabName === this.mobileTab) {
        this.$store.commit('activeProject/closeMobileTab')
      }
    },
    toggle () {
      if (this.mq.mdPlus) {
        if (this.tabName === this.mobileTab) {
          this.$store.commit('activeProject/closeMobileTab')
        } else  {
          this.$store.commit('activeProject/setActiveMobileTab', this.tabName)
        }
      } else {
        this.modalOpen = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-item {
  position: relative;
  &__btn {
    padding: 0;
    width: 56px;
    height: 56px;
    border-radius: 16px;
    background: $white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.2s;
    :deep img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    &_active {
      background: $blue;
      color: $white;
    }
  }
  &__btn-inside {
    pointer-events: none;
  }
  &__content {
    position: absolute;
    right: 66px;
    top: 0;
    width: 375px;
  }
}
</style>
