<template lang="pug">
.markets
  a.markets__item(
    v-for="market in menuMarkets"
    :key="market.key"
    :href="market.url"
  )
    .markets__icon.market-icon(
      :class="'market-icon_' + market.key"
    )
    .markets__title(v-html="market.titleHtml")
</template>

<style lang="scss" scoped>
  .markets {
    padding: 13px 14px;
    font-weight: $font-weight-bold;

    &__item {
      display: flex;
      align-items: center;
      margin: 0 0 18px;

      &:last-child {
        margin: 0;
      }
    }

    &__icon {
      flex: 0 0 42px;
      width: 42px;
      height: 42px;
      margin: 0 20px 0 0;
    }
    &__title {
      font-size: 12px;
      line-height: 1.3;
      letter-spacing: -0.46px;
    }
  }
</style>

<script>

export default {
  name: 'TheHeaderUserMenu',
  props: {
    markets: Array
  },
  data: () => ({
    menuMarkets: null
  }),
  created: function () {
    this.menuMarkets = _.clone(this.markets)

    // and add two custom links
    this.menuMarkets.splice(this.menuMarkets.length-1, 0,
      {
        key: 'yt',
        titleHtml: 'Pricing <br>90% OFF',
        url: 'https://yellowimages.com/yellow-ticket'
      },
      {
        key: 'deposit',
        titleHtml: 'Make <br>a Deposit',
        url: 'https://yellowimages.com/make-a-deposit'
      }
    )
  }
}
</script>
