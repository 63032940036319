import router from '@/router'
import { $vfm } from 'vue-final-modal'

const FILTER_MAP = {
  'published': '?order=published',
  'likes': '?order=likes',
  'views': '?order=views',
  'selected': '?order=selected&count=12&contest=1',
  'waiting_approval': '?state=1',
  'blocked': '?state=3'
}

const state = () => ({
  projectsList: [],
  userProjects: [],
  projectsFilter: null,
  projectsListPage: 0,
  isLastProjectPage: true
})

const getters = {}

const actions = {

  /**
   * Получение списка проектов по заданному фильтру
   * @param state
   * @param commit
   * @param filter
   */
  async getProjectsShowcase ({state, commit}, filter) {
    if (state.projectsFilter !== filter) {
      commit('clearProjectShowcase', filter)
    }
    state.projectsListPage++
    await this.$http
      .get(`/v1/showcase/projects${FILTER_MAP[filter]}&page=${state.projectsListPage}&contest=1`)
      .then(result => {
        if (state.projectsListPage === 1) {
          state.projectsList = []
        }
        commit('setProjectShowcase', result.data.body.items)

        state.isLastProjectPage = !result.data.body.is_more
      })
  },

  /**
   * Получение и открытие нового проекта
   * @param rootGetters
   * @param {Object} data Данные о новом проекте
   * @param {number | string} data.name Имя проекта
   * @param {number | string} data.id ID продукта из каталога
   * @param {Boolean} data.isMobile true если выполняется с мобильного устройства
   * @param {Boolean} data.replaceLink не запоминать текущую ссылку в истории
   */
  createProject ({ rootGetters }, data) {
    if (data.browser.name === 'safari' && data.browser.version[0] < 14) {
      $vfm.show('old-browser')
      return
    }
    if (data.isMobile) {
      $vfm.show('unable-to-create')
      return
    }
    if (rootGetters['auth/isAuthenticated']) {
      this.$http.get(`/v1/showcase/products/${data.id}/config`)
        .then(response => {
          const responseParse = JSON.parse(response.data.body.data)
          const projectObject = {
            name: data.name,
            categories: [1, 2, 3],
            product_id: data.id,
            cover: responseParse.scene.previews ? responseParse.scene.previews[0].image.url : '',
            data: {
              variations: [
                {
                  settings: {
                    id: 'variation-' + (new Date()).getTime()
                  },
                  products: responseParse.product,
                  scene: responseParse.scene
                }
              ]
            }
          }

          // Сохранение проекта у пользователя
          this.$http.post('/v1/projects', JSON.stringify(projectObject))
            .then(response => {
              if (data.replaceLink) {
                router.replace(`/p/${response.data.body.id}`)
              } else {
                router.push(`/p/${response.data.body.id}`)
              }
              // $vfm.show('ducks-limit')
            })
            .catch(error => {
              if (error.response.status === 409 && error.response.data.error.code === "CONTEST_LIMIT") {
                $vfm.show('ducks-limit')
              }
            })
        })
    } else {
      $vfm.show('join-now', { regRequiredType: 'create-duck' })
    }
  },

  /**
   * Получение проектов пользователя
   * @param commit
   */
  async getUserProjects ({ commit }) {
    if (this.getters['auth/isAuthenticated']) {
      await this.$http.get('/v1/projects?contest=1')
        .then(response => {
          commit('setUserProjects', response.data.body.items)
        })
    }
  }
}

const mutations = {
  /**
   * Установка лайка в проект
   * @param state
   * @param {Object} payload
   * @param {String} payload.id id проекта
   * @param {Boolean} payload.isLike лайкнут ли проект
   */
  changeProjectLikeCount (state, payload) {
    const projectInList = _.find(state.projectsList, ['id', payload.id])
    const projectInUser = _.find(state.userProjects, ['id', payload.id])

    const changedProjects = [projectInList, projectInUser]

    _.forEach(changedProjects, project => {
      if (project && project.you) {
        project.you.liked = payload.isLike

        if (payload.isLike) {
          project.likes++
        } else {
          project.likes--
        }
      }
    })
  },

  changeProjectState (state, { projectId, newState, isSelected }) {
    const project = _.find(state.projectsList, ['id', projectId])

    if (project) {
      project.state = newState
      project.is_selected = isSelected
    }

    if (project && state.projectsFilter === 'waiting_approval') {
      const projectIndex = _.findIndex(state.projectsList, ['id', projectId])
      state.projectsList.splice(projectIndex, 1)
    }
  },

  changeProjectName (state, { projectId, name }) {
    const project = _.find(state.userProjects, ['id', projectId])

    if (project) {
      project.name = name
    }
  },

  /**
   * Установка полученных проектов
   * @param state
   * @param {Array} projects
   */
  setProjectShowcase (state, projects) {
    state.projectsList = state.projectsList.concat(projects)
  },

  clearProjectShowcase (state, newFilter) {
    state.projectsListPage = 0
    state.projectsFilter = newFilter
    state.isLastProjectPage = true
  },

  /**
   * Установка проектов пользователя
   * @param state
   * @param {Array} projects
   */
  setUserProjects (state, projects) {
    state.userProjects = projects
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
