import {
  WebGLRenderer,
  WebGL1Renderer,
  PCFSoftShadowMap,
  sRGBEncoding,
  NoToneMapping,
  LinearToneMapping,
  ReinhardToneMapping,
  CineonToneMapping,
  ACESFilmicToneMapping,
  CustomToneMapping,
  ShaderChunk,
} from './../../../../../build/three.module.js'

export var renderer = function(){

  // Set CustomToneMapping to Uncharted2
  // source: http://filmicworlds.com/blog/filmic-tonemapping-operators/
  // example: https://github.com/mrdoob/three.js/blob/master/examples/webgl_tonemapping.html
  ShaderChunk.tonemapping_pars_fragment = ShaderChunk.tonemapping_pars_fragment.replace(
    'vec3 CustomToneMapping(vec3 color) return color;}',
    `#define Uncharted2Helper(x) max(((x * (0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) / (x * (0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
    float toneMappingWhitePoint = 1.0;
    vec3 CustomToneMapping(vec3 color){
      color *= toneMappingExposure;
      return saturate(Uncharted2Helper(color) / Uncharted2Helper(vec3(toneMappingWhitePoint)));
    }`
  );

  const toneMappingParams = {
    exposure: 1.97,
    toneMapping: 'ACESFilmic'
  };

  const toneMappingOptions = {
    None:       NoToneMapping,
    Linear:     LinearToneMapping,
    Reinhard:   ReinhardToneMapping,
    Cineon:     CineonToneMapping,
    ACESFilmic: ACESFilmicToneMapping,
    Custom:     CustomToneMapping
  };

  this.renderer = new WebGL1Renderer({ antialias: true, preserveDrawingBuffer: true, alpha: true });
  this.renderer.setPixelRatio(window.devicePixelRatio);
  this.renderer.setSize(
    this.DOM.drawing.clientWidth,
    this.DOM.drawing.clientHeight
  );
  //this.renderer.setClearColor(0xf5f5f5, 0); //a2ab8e, 0); //dfebff );
  //this.renderer.gammaInput = true;
  //this.renderer.gammaFactor = 2.2;
  this.renderer.physicallyCorrectLights = true;
  // this.renderer.shadowMap.enabled = true;
  // this.renderer.shadowMap.type = PCFSoftShadowMap; // default THREE.PCFShadowMap
  this.renderer.outputEncoding = sRGBEncoding;

  this.DOM.drawing.appendChild(this.renderer.domElement);

  this.renderer.toneMapping = toneMappingOptions[toneMappingParams.toneMapping];
  this.renderer.toneMappingExposure = toneMappingParams.exposure;

};
