import {
  Raycaster,
} from './../../../../../build/three.module.js';
import {windowresize} from './windowresize/index.js';

export var events = function(){

  //$(document).bind('contextmenu', function(e){ e.preventDefault() });
  const _this = this;

  {
    const raycaster = new Raycaster();
    // raycaster.layers.set(1);
    const hoverObj = _this.hoverObject;
    let _INTERSECTED;

    // function onPointerMove(e){
    //   if(_this.renderer){

    //     const {top, left, width, height} = _this.renderer.domElement.getBoundingClientRect();

    //     hoverObj.mouse.x = -1+2*(e.clientX-left)/width; //( event.clientX / window.innerWidth ) * 2 - 1;
    //     hoverObj.mouse.y = 1-2*(e.clientY-top)/height; //- ( event.clientY / window.innerHeight ) * 2 + 1;

    //     raycaster.setFromCamera(hoverObj.mouse, _this.camera)

    //     const scene = _this.scene.getObjectByName('FBXModel');
    //     if(scene && scene.children[0]){
    //       _INTERSECTED = raycaster.intersectObjects(_this.scene.children[0].children[0].children);
    //     }

    //     hoverObj.object = (_INTERSECTED && _INTERSECTED[0]) ? _INTERSECTED[0].object.name : null;
    //   }
    // }
    // window.addEventListener('pointermove', onPointerMove);

  }

  window.addEventListener('resize', windowresize.bind(this), false);

};
