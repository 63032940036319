import {PerspectiveCamera} from './../../../../../build/three.module.js';

export const camera = function(){

  const fov = this.DOM.drawing.clientWidth < 576 ? 50 : 20;

  // window.app3dStats.canvas.push({
  //   width: this.DOM.drawing.clientWidth,
  //   height: this.DOM.drawing.clientHeight,
  // });

  this.camera = new PerspectiveCamera(
    fov,
    this.DOM.drawing.clientWidth / this.DOM.drawing.clientHeight,
    0.1,
    1000
  );

  // this.camera.name = name;
  this.camera.position.x = 0;
  this.camera.position.y = 0;
  this.camera.position.z = 3.952;

};
