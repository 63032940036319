<template lang="pug">
base-modal(
  v-model="isOpen"
  name="how-create"
  @modalClose="isOpen = false"
  full-screen
)
  template(
    #head
  )
    .title How to create your duck

  .how-create-hero.row
    .how-create-hero__image-wrap.col-md-6
      img.how-create-hero__image(
        src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/duck.png"
      )
    .how-create-hero__content.col-md-6
      .how-create-hero__title
        span Welcome to our 3D Visualizer!&ensp;
        | This is  where you'll create your own unique duck design.
      .how-create-hero__subtitle We've prepared a template for you to make the process easier.
      base-button Download PSD template

  .how-create
    masonry-wall(
      :items="cards"
      :column-width="300"
      :gap="8"
    )
      template(
        #default="{ item, index }"
      )
        .how-create-card(
          :style="{background: item.color}"
        )
          .how-create-card__title(
            v-if="item.title"
          ) {{ item.title }}
          div.how-create-card__content(
            v-if="item.content"
            v-html="item.content"
          )

</template>

<script>
import BaseModal from "@/components/BaseModal";
import BaseButton from "@/components/BaseButton";
export default {
  name: "MobileHowCreate",
  components: { BaseButton, BaseModal },
  data () {
    return {
      isOpen: false,
      cards: [
        {
          title: "Let's get acquainted with the Visualizer tools",
          color: '#ffffff'
        },
        {
          content: `
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/main-tabs.png" alt="main tabs"/>
          `,
          color: '#A4E0FF'
        },
        {
          title: "Layers and adding images",
          content: `
          <div>You have two layers available for creating your design, the body and the beak.</div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/layers.png" alt="layers"/>
          </div>
          <div>Click on a layer to start editing it. Press "+" to upload your own media and add it to the layer.</div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/body-layer.png" alt="body layer"/>
          </div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/layers-design.png" alt="layers design"/>
          </div>
          <div>You can delete any media you've uploaded by clicking on the trash can icon, or by adding a new file from the layer editing tool.</div>
          <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/main-tools-design.png" alt="main tools design"/>
          `,
          color: '#DDB0FF'
        },
        {
          title: "Materials",
          content: `
          <div>You can apply your own colors, materials, and parameters for each layer.</div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/materials-setting.png" alt="materials setting"/>
          </div>
          <div>In the "color" section, you can choose from our color palette or select your own.</div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/materials-color.png" alt="materials color"/>
          </div>
          <div>In the "materials" section, you can select from pre-set material options.</div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/materials-library.png"  alt="materials-library"/>
          </div>
          `,
          color: '#F2E7DD'
        },
        {
          title: "Tools",
          content: `
          <div>Click this icon <img class="how-create-card__text-icon" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/tools-icon.svg" alt="tools icon"/> for tools to help you create your duck.</div>
          <div class="how-create-card__image-tools">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/tools.png" alt="tools"/>
          </div>
          `,
          color: '#C3BBF9'
        },
        {
          title: "Name your project and submit it",
          content: `
          <div>Click this icon <img class="how-create-card__text-icon" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/settings-icon.svg" alt="tools icon"/> in the top menu to finalize your submission.</div>
          <div class="how-create-card__image-card">
            <img class="how-create-card__image" src="https://cdn.yellowimages.com/3dvisualizer/how-create-duck/publish.png" alt="publish"/>
          </div>
          `,
          color: '#AEDEB3'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  letter-spacing: -0.89px;
  font-weight: bold;
  text-align: center;
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}
.how-create-hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  @include media-breakpoint-up(md) {
    padding-top: 90px;
    padding-bottom: 110px;
  }
  &__title {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 16px;
    max-width: 340px;
    span {
      font-weight: bold;
    }
  }
  &__subtitle {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    max-width: 260px;
  }
  &__image {
    margin: 0 auto;
    display: block;
    width: 264px;
    max-width: 100%;
  }
  &__image-wrap {
    @include media-breakpoint-down(md) {
      margin-bottom: 45px;
    }
  }
}
.how-create {
}
.how-create-card {
  background: #fff;
  border-radius: 16px;
  padding: 25px 15px;
  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
  }
  &__content {
    line-height: 1.5;
  }
  &__title + &__content {
    margin-top: 16px;
  }
  :deep &__image {
    width: 100%;
    display: block;
  }
  :deep .how-create-card__image {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
    &_tools {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  :deep(.how-create-card__image-card) {
    background: #FFFFFF;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.05);
    border-radius: 16px;
    padding: 24px;
    margin: 24px 0 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  :deep {
    .how-create-card__image-tools {
      margin-left: -28px;
      margin-right: -28px;
    }
  }
  :deep {
    .how-create-card__image-card + .how-create-card__image-card {
      margin-top: -32px;
    }
  }
  :deep &__text-icon {
    vertical-align: middle;
    margin: -14px 5px -10px;
  }
}

@include media-breakpoint-down(md) {
  .button {
    width: 100%;
    height: 56px;
  }
}
</style>
