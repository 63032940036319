<template lang="pug">
.screenshot-settings
  .screenshot-settings__description This will export the current viewport as the selected image type.

  .screenshot-settings__wrap
    .screenshot-settings__title Format
    .screenshot-settings__formats
      base-button.screenshot-settings__button.screenshot-settings__button_format(
        v-for="(format, index) in formats"
        :key="format.text"
        :class="{'screenshot-settings__button_active': format.active}"
        @click="changeFormat(index)"
      ) {{ format.text }}

  .screenshot-settings__wrap
    .screenshot-settings__transparent-wrap(
      :class="{'screenshot-settings__transparent-wrap_disable': transparent.disabled}"
    )
      .screenshot-settings__title(
        :style="{margin: '0'}"
      ) Transparent
      .screenshot-settings__transparent-switcher
        toggle-btn(
          v-model="transparent.value"
        )

  .screenshot-settings__wrap
    .screenshot-settings__title Resolution
    .screenshot-settings__resolutions-wrap
      base-button.screenshot-settings__button.screenshot-settings__button_resolution(
        v-for="(resolution, index) in resolutions"
        :key="resolution.name"
        :class="{'screenshot-settings__button_active': resolution.active}"
        @click="changeResolution(index)"
      ) <span>{{ resolution.name }}</span><span>{{ resolution.size.width }}x{{ resolution.size.height }}</span>

  base-button.screenshot-settings__main-btn(
    @click="generateScreenshot"
    :size="mq.xlPlus ? 'medium' : 'large'"
  ) Create screenshot

</template>

<script>
import BaseButton from "@/components/BaseButton";
import ToggleBtn from "@/components/ToggleBtn";
export default {
  name: "vizScreenshotSettings",
  components: {ToggleBtn, BaseButton},
  inject: ["mq"],

  data () {
    return {
      formats: [
        {
          text: 'PNG',
          value: 'PNG',
          transparentAccess: true,
          active: true
        },
        {
          text: 'JPG',
          value: 'JPG',
          transparentAccess: false,
          active: false
        },
        {
          text: 'WEBP',
          value: 'WEBP',
          transparentAccess: true,
          active: false
        }
      ],
      transparent: {
        value: true,
        disabled: false
      },
      resolutions: [
        {
          name: 'Small',
          size: {
            width: 1440,
            height: 1024
          },
          active: true
        },
        {
          name: 'Large',
          size: {
            width: 2880,
            height: 2048
          },
          active: false
        },
        {
          name: 'Max',
          size: {
            width: 5760,
            height: 4096
          },
          active: false
        }
      ]
    }
  },

  methods: {
    changeFormat (formatIndex) {
      _.forEach(this.formats, (format) => {
        format.active = false
      })
      this.formats[formatIndex].active = true

      if (this.formats[formatIndex].transparentAccess) {
        this.transparent.disabled = false
        this.transparent.value = true
      } else {
        this.transparent.disabled = true
        this.transparent.value = false
      }
    },
    changeResolution (resolutionIndex) {
      _.forEach(this.resolutions, (resolution) => {
        resolution.active = false
      })
      this.resolutions[resolutionIndex].active = true
    },
    generateScreenshot () {
      const activeFormat = _.find(this.formats, 'active')
      const activeResolution = _.find(this.resolutions, 'active')

      const screenshotSettings = {
        format: activeFormat.value,
        transparent: this.transparent.value,
        size: {
          width: activeResolution.size.width,
          height: activeResolution.size.height
        }
      }

      this.$store.dispatch('activeProject/getCurrentScreenshot', screenshotSettings)
    }
  }
}
</script>

<style lang="scss" scoped>
.screenshot-settings {
  padding-top: 10px;
  &__description {
    font-weight: 500;
    font-size: 12px;
    color: #656565;
    letter-spacing: -0.4px;
    line-height: 1.5;
  }
  &__wrap {
    margin-top: 20px;
  }
  &__title {
    font-weight: bold;
    line-height: 17px;
    letter-spacing: -0.533333px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  &__transparent-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_disable {
      color: $gray-dark;
      pointer-events: none;
    }
  }
  &__formats {
    display: flex;
    justify-content: space-between;
  }
  &__button {
    border: 1px solid $gray;
    border-radius: 8px;
    background: $gray;
    color: $black;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    @include media-breakpoint-down(xl) {
      height: 56px;
    }
    @include media-breakpoint-up(xl) {
      background: $white;
      border: 1px solid #E2E5E9;
      font-size: 12px;
      padding-left: 12px;
      padding-right: 12px;
    }
    &:hover {
      background: $gray;
      border-color: $gray;
      color: $black;
    }
    &_active {
      color: $white;
      background: $blue;
      border-color: $blue;
      pointer-events: none;
      &:hover {
        color: $white;
        background: $blue;
        border-color: $blue;
      }
    }
    &_format {
      width: calc(33.333% - 4px);
    }
    &_resolution {
      width: 100%;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__main-btn {
    width: 100%;
    margin-top: 24px;
  }
}
</style>
