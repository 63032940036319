const ROUTE_HEAD = {
  main: {
    title: 'Create Your Own Duck | Yellow Images Contest',
    ogpTitle: 'Create your own duck'
  },
  home: {
    ogpDescription: 'Create unique duck designs to win an iPad Pro!',
    ogpImage: 'https://yi-data3d-prod.s3.amazonaws.com/yi_duck_competition.png'
  },
  duckPage: {
    title: 'Vote for my duck design | Yellow Images Contest',
    ogpTitle: 'Vote for my duck design',
    ogpDescription: 'If you snooze, I lose! Help me win an iPad Pro.'
  },
  about: {
    title: 'Create Your Own Duck | Yellow Images Contest',
    ogpTitle: 'Stand out with your duck design!',
    ogpDescription: 'Design your own duck and win an iPad Pro!',
    ogpImage: 'https://yi-data3d-prod.s3.amazonaws.com/yi_duck_competition.png'
  },
  rules: {
    title: 'Create Your Own Duck Contest Rules | Yellow Images Contest',
    ogpTitle: 'Create Your Own Duck Contest Rules',
    ogpDescription: 'How to create a duck and win an iPad Pro',
    ogpImage: 'https://yi-data3d-prod.s3.amazonaws.com/yi_duck_competition.png'
  },
  myProjects: {
    title: 'My projects | Yellow Images Contest',
    ogpTitle: 'My projects | Yellow Images Contest',
    ogpImage: 'https://yi-data3d-prod.s3.amazonaws.com/yi_duck_competition.png'
  },
  error404: {
    ogpImage: 'https://yi-data3d-prod.s3.amazonaws.com/yi_duck_competition.png'
  }
}

module.exports={
  ROUTE_HEAD
}
