<template lang="pug">
viz-aside-cards-wrap(
  :class="{'settings-pro': isProVersion}"
  ref="settings"
)
  template(
    #outside-main
  )
    .additional-setting(
      v-if="additionalSetting === 'color-picker'"
      :style="{ top: settingTop + 'px' }"
    )
      viz-card(
        title="Color"
        close-button
        @closeCard="$store.commit('activeProject/clearAdditionalSetting')"
      )
        viz-color-picker
    .additional-setting(
      v-show="additionalSetting === 'material-library'"
      :style="{ top: settingTop + 'px' }"
    )
      viz-material-library(
        @closeCard="$store.commit('activeProject/clearAdditionalSetting')"
      )
    .settings-tab-list(
      v-if="isProVersion"
    )
      .settings-tab-list__item
        base-button.settings-tab-list__btn(
          round
          @click="activeSettingsTab = 'object'"
        ) 1
      .settings-tab-list__item
        base-button.settings-tab-list__btn(
          round
          @click="activeSettingsTab = 'ambience'"
        ) 2
  template(
    v-if="activeSettingsTab === 'object'"
  )
    viz-layers(
      v-tooltip.left="{theme: 'hint', content: 'You have two layers available for creating your design, the body and the beak.', shown: isHintsOpen, triggers: []}"
    )
    viz-add-design(
      v-tooltip.left="{theme: 'hint', content: 'You can upload your design. Body and beak design may vary', shown: isHintsOpen, triggers: []}"
    )
    viz-psd-template
    viz-adjustments(
      v-tooltip.left="{theme: 'hint', content: 'Choose and customize materials and colors to your liking', shown: isHintsOpen, triggers: []}"
    )
  template(
    v-if="activeSettingsTab === 'ambience'"
  )
    div Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aspernatur error excepturi facilis, qui totam. Dignissimos maiores necessitatibus nobis reiciendis.
</template>

<script>
import VizLayers from './vizLayers'
import VizAddDesign from './vizAddDesign'
import VizAdjustments from './vizAdjustments'
import VizColorPicker from './vizColorPicker'
import VizMaterialLibrary from "./vizMaterialLibrary";
import VizAsideCardsWrap from "./vizAsideCardsWrap";
import BaseButton from "@/components/BaseButton";
import VizCard from "@/components/player/vizCard";
import VizPsdTemplate from "@/components/player/vizPsdTemplate";
export default {
  name: 'vizSettings',

  components: {
    VizPsdTemplate,
    VizCard,
    BaseButton, VizAsideCardsWrap, VizMaterialLibrary, VizColorPicker, VizAdjustments, VizAddDesign, VizLayers },

  data () {
    return {
      activeSettingsTab: 'object',
      isProVersion: false,
      settingTop: '0px'
    }
  },

  computed: {
    isHintsOpen () {
      return this.$store.state.activeProject.isHintsOpen
    },
    additionalSetting () {
      this.updateAdditionalSettingPosition()
      return this.$store.state.activeProject.additionalSettingTab
    }
  },

  methods: {
    updateAdditionalSettingPosition () {
      if (this.$el) {
        this.settingTop = this.$el.querySelector('.settings').clientHeight - 25
      }
    }
  },

  mounted () {
    window.addEventListener('resize', this.updateAdditionalSettingPosition)
  },

  destroyed () {
    window.removeEventListener('resize', this.updateAdditionalSettingPosition)
  },
}
</script>

<style lang="scss" scoped>
  .additional-setting {
    position: absolute;
    width: 250px;
    left: -250px;

    transform: translateY(-100%);
    max-height: calc(100% - 50px);
    display: flex;

    :deep {
      .card {
        height: 100%;
        width: 100%;
      }
    }
  }
  .settings-tab-list {
    flex-shrink: 0;
    padding: 0 20px;
    display: flex;
  }
  .settings-pro {
    :deep {
      .settings__main {
        padding: 10px 20px 25px;
        margin: 10px 0 -25px;
      }
    }
  }
</style>
