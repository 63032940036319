<template lang="pug">
.view-player
  .view-player__btns(
    v-if="state !== 0"
  )
    .view-player__btn-action-wrap(
      v-click-away="closeShareMenu"
    )
      .view-player__btn-action(
        @click="toggleShareMenu"
        :class="{'view-player__btn-action_share-active': showShareMenu}"
      )
        BaseIcon(
          data="@icon/share.svg"
          width="24"
          height="24"
        )
      viz-share.view-player__share-dropdown(
        v-if="showShareMenu"
        show-copy-link
      )
    .view-player__btn-action-wrap
      viz-like-btn(
        :like-count="likeCount"
        :liked="liked"
        @onLike="onLike"
        isBig
      )
  .author-card
    .author-card__head
      img.author-card__avatar(
        :src="projectAuthor.avatar"
        :alt="projectAuthor.full_name"
      )
      .author-card__author-data
        .author-card__item-title About creator
        .author-card__author-name {{ projectAuthor.full_name }}

    .author-card__content-wrap
      .author-card__item
        .author-card__item-title.author-card__item-title_in-data Project Name
        .author-card__item-desc {{ projectName }}
      .author-card__item
        .author-card__item-title.author-card__item-title_in-data Released
        .author-card__item-desc {{ releasedDate }}
      .author-card__project-metrics-wrap
        .author-card__project-metric
          BaseIcon.author-card__project-metric-icon(
            data="@icon/heart.svg"
            width="20"
            height="20"
            color="#E2E5E9"
          )
          .author-card__project-metric-data {{ likeCount }}
        .author-card__project-metric
          BaseIcon.author-card__project-metric-icon(
            data="@icon/views-icon.svg"
            width="20"
            height="20"
            color="#E2E5E9"
          )
          .author-card__project-metric-data {{ viewsCount }}

</template>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'
import VizShare from "@/components/player/vizShare";
import VizLikeBtn from "@/components/player/vizLikeBtn";
import moment from "moment";

export default {
  name: "vizMobileViewPlayer",
  components: {VizLikeBtn, VizShare},
  mixins: [VueClickAway],

  data () {
    return {
      showShareMenu: false
    }
  },

  computed: {
    projectName () {
      return this.$store.state.activeProject.project.name
    },
    projectAuthor () {
      return this.$store.state.activeProject.project.owner
    },
    releasedDate () {
      return moment(this.$store.state.activeProject.project.updated_at).format('MMMM DD, YYYY')
    },
    likeCount () {
      return this.$store.state.activeProject.project.likes
    },
    viewsCount () {
      return this.$store.state.activeProject.project.views
    },
    liked () {
      return this.$store.state.activeProject.project?.you?.liked
    },
    state () {
      return this.$store.state.activeProject.project.state
    }
  },

  methods: {
    toggleShareMenu () {
      this.showShareMenu = !this.showShareMenu
    },
    closeShareMenu () {
      this.showShareMenu = false
    },
    onLike () {
      this.$store.dispatch('likes/onProjectLike', {id: this.$store.state.activeProject.project.id, isLike: !this.liked})
    }
  }
}
</script>

<style lang="scss" scoped>
.view-player {
  position: absolute;
  bottom: 16px;
  left: 0;
  padding: 0 16px;
  width: 100%;
  @include media-breakpoint-up(md) {
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    width: auto;
  }
  @include media-breakpoint-up(lg) {
    left: calc(50% + 90px);
    top: 50%;
    transform: translateY(-50%);
  }
  &__btns {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      margin-bottom: 40px;
    }
  }
  &__btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background: $white;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
    transition: 0.2s;
    &_share-active {
      background: $black;
      color: $white;
    }
  }
  &__btn-action-wrap {
    margin-right: 12px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
  }
  &__share-dropdown {
    position: absolute;
    left: -101px;
    top: -64px;
    @media (max-width: 374px) {
      left: -51px;
    }
    &:deep {
      .share-icons {
        &__item-wrap {
          width: 52px;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          border-radius: 50%;
          background: $white;
          &:last-child {
            margin-right: 0;
          }
          @media (max-width: 374px) {
            width: 36px;
            height: 36px;
          }
        }
        &__item {
          width: auto;
          &_more {
            width: 100%;
            height: 100%;
            .svg-icon {
              width: 100% !important;
              height: 100% !important;
            }
            circle {
              fill: $white;
            }
            &-active {
              circle {
                fill: $black;
              }
              path {
                fill: $white;
              }
            }
          }
          &_network, &_copy-link {
            .svg-icon {
              width: 40px !important;
              height: 40px !important;

              @media (max-width: 374px) {
                width: 30px !important;
                height: 30px !important;
              }
            }
          }
        }
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.author-card {
  padding: 20px 16px;
  background: $white;
  border-radius: 16px;
  width: 100%;
  max-width: 345px;
  margin: auto;

  @include media-breakpoint-up(md) {
    width: 345px;
    max-width: inherit;
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 325px;
  }
  &__head {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    position: relative;
    &::before {
      content: '';
      display: block;
      height: 1px;
      background-color: $gray;
      position: absolute;
      left: 0;
      bottom: -20px;
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: calc(100% - 52px);
        left: 52px;
      }
    }
  }
  &__avatar {
    flex-shrink: 0;
    display: block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  &__item-title {
    font-size: 12px;
    color: $gray-dark;
    margin-bottom: 4px;
    &_in-data {
      @include media-breakpoint-down(lg) {
        margin-bottom: 8px;
      }
    }
  }
  &__author-name {
    font-weight: bold;
    font-size: 14px;
  }
  &__content-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include media-breakpoint-up(lg) {
      padding-left: 52px;
      display: block;
    }
  }
  &__item {
    margin-right: 12px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
  &__project-metrics-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }
  }
  &__project-metric {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    @include media-breakpoint-up(lg) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  &__project-metric-icon {
    flex-shrink: 0;
    margin-right: 6px;
  }
  &__project-metric-data {
    font-size: 12px;
  }
}
</style>
