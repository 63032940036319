<template lang="pug">
.user
  .user__top {{ user.full_name }}
  .user__content
    a.user__link(
      href="https://passport.yellowimages.com/sso?client=6e0505eb-9598-4b74-9e6e-7781c4af28d4"
    ) My account
    .user__link(
      @click="logout"
    ) Log out
</template>

<style lang="scss" scoped>
  .user {
    &__top {
      padding: 15px 20px 10px;
      font-size: 16px;
      line-height: 1.1;
      font-weight: $font-weight-bold;
      border-bottom: 2px dotted #dedede;
    }
    &__content {
      padding: 10px 0 2px;
    }
    &__link {
      cursor: pointer;
      color: $black;
      transition: background 0.2s, color 0.2s;
      display: block;
      padding: 11px 8px;
      border-radius: 6px;

      &:hover {
        color: $black;
        background: $gray;
      }
    }
  }
</style>

<script>

export default {
  name: 'TheHeaderUser',
  components: {
  },

  props: {
    user: Object
  },
  computed: {
  },
  methods: {
    async logout(){
      let token = this.$cookie.getCookie('userToken')

      await this.$store.dispatch('auth/LogOut')
      
      console.log(token)
      if (token) {
        window.location.href = `${process.env.VUE_APP_API_URL}/logout?authorization=${token}`
      }
    }
  }
}
</script>
