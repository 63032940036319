<template lang="pug">
.container
  the-navigation

.container
  .row
    .col
      h1.lead-header My recently <br><span>created&nbsp;projects</span>

  .row.projects
    .col-6.col-sm-4.col-lg-3.col-xl-2.d-flex
      .card-blank.d-flex(
        @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
      )
        .card-blank__content.d-flex.flex-column
          .card-blank__inner
            .card-blank__info.d-flex.flex-column
              .card-blank__suptitle Take a chance!
              .card-blank__title Create a new duck&nbsp;yourself
              .card-blank__duck.d-flex.align-items-center.justify-content-center
                BaseIcon(
                  data="@icon/duck.svg"
                  width="28"
                  height="27"
                  color="#fff"
                )
          BaseButton.button_mobile Start creating
    .col-6.col-sm-4.col-lg-3.col-xl-2(
      v-for="project in userProjects"
      :key="project.id"
    )
      ProjectItem(
        :item="project"
        noModerate
        user-project
      )

</template>

<script>
import TheNavigation from "@/components/TheNavigation"
import BaseButton from "@/components/BaseButton";
import ProjectItem from "@/components/ProjectItem";
import { ROUTE_HEAD } from '@/data/serverRoutersData'
import {useHead} from "@vueuse/head";

export default {
  name: 'MyProjects',
  inject: ['mq'],
  components: {ProjectItem, BaseButton, TheNavigation},
  computed: {
    userProjects () {
      return this.$store.state.projects.userProjects
    }
  },
  created() {
    this.$store.dispatch('projects/getUserProjects')
  },
  setup () {
    useHead({
      title: ROUTE_HEAD.myProjects.title
    })
  }
}
</script>

<style lang="scss" scoped>
.card-blank {
  transition: box-shadow .4s;
  cursor: pointer;

  width: 100%;
  position: relative;
  z-index: 1;
  min-height: 300px;
  border-radius: 18px;
  padding: 10px;
  font-weight: $font-weight-bold;


  @include media-breakpoint-down(md) {
    padding: 6px;
    min-height: 0;
  }

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    z-index: 15;
    color: $black;
  }

  &__content {
    -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
    position: relative;
    z-index: 5;
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    background: $gray;
  }
  &__inner {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 124.464%;
    }
  }

  &__info {
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 24px 24px;

    @include media-breakpoint-down(md) {
      padding: 20px 15px;
    }
  }

  &__suptitle {
    letter-spacing: -0.53px;
    margin: 6px 0 32px;

    @include media-breakpoint-down(md) {
      letter-spacing: -0.5px;
      font-size: 13px;
      margin: 4px 0 15px 0;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.76px;
    margin: 0 0 12px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 1.3;
    }
  }

  &__duck {
    margin: auto;
    flex: 0 0 64px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: $blue;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .button {
    margin: auto 24px 24px;

    @include media-breakpoint-down(md) {
      margin: auto 10px 10px;
      padding: 10px 12px;
    }
  }
}
</style>
