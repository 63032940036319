<template lang="pug">
ul.share-icons
  li.share-icons__item-wrap(
    v-for="soc in currentNetworks"
    :key="soc.name"
  )
    share-network.share-icons__item.share-icons__item_network(
      :network="soc.name"
      :url="currentUrl"
      title="Vote for my duck design"
      description="If you snooze, I lose! Help me win an iPad Pro."
      :media="projectCover"
      @open="shareNetworkOpened"
    )
      BaseIcon(
        :data="soc.icon"
        width="34"
        height="34"
        :original="true"
      )
  li.share-icons__item-wrap(
    v-if="showCopyLink"
  )
    copy-current-link.share-icons__item.share-icons__item_copy-link(
      @on-copy-url="onCopyUrl"
    )
      BaseIcon(
        data="@icon/copy-link.svg"
        width="34"
        height="34"
        :original="true"
      )
  li.share-icons__item-wrap
    div.share-icons__item.share-icons__item_more(
      @click="showSecondNetworks = !showSecondNetworks"
      :class="{'share-icons__item_more-active': showSecondNetworks}"
    )
      BaseIcon(
        data="@icon/share-more.svg"
        width="34"
        height="34"
        :original="true"
      )
</template>

<script>
import VizCard from '@/components/player/vizCard'
import facebookIcon from '@/assets/icons/facebook.svg'
import redditIcon from '@/assets/icons/reddit.svg'
import pinterestIcon from '@/assets/icons/pinterest.svg'
import twitterIcon from '@/assets/icons/twitter.svg'
import telegramIcon from '@/assets/icons/telegram.svg'
import tumblrIcon from '@/assets/icons/tumblr.svg'
import whatsappIcon from '@/assets/icons/whatsapp.svg'
import vkIcon from '@/assets/icons/vk.svg'
import BaseButton from '@/components/BaseButton'
import CopyCurrentLink from "@/components/CopyCurrentLink";
export default {
  name: "vizShare",

  components: {CopyCurrentLink, BaseButton, VizCard },

  props: {
    showCopyLink: Boolean
  },

  data () {
    return {
      socialNetworksFirst: [
        {
          name: 'Facebook',
          icon: facebookIcon
        },
        {
          name: 'Twitter',
          icon: twitterIcon
        },
        {
          name: 'Pinterest',
          icon: pinterestIcon
        },
        {
          name: 'Reddit',
          icon: redditIcon
        }
      ],
      socialNetworksSecond: [
        {
          name: 'Telegram',
          icon: telegramIcon
        },
        {
          name: 'Tumblr',
          icon: tumblrIcon
        },
        {
          name: 'WhatsApp',
          icon: whatsappIcon
        },
        {
          name: 'VK',
          icon: vkIcon
        }
      ],
      showSecondNetworks: false,
      currentUrl: ''
    }
  },

  computed: {
    projectName () {
      return this.$store.state.activeProject.project.name
    },
    projectCover () {
      return this.$store.state.activeProject.project.cover
    },
    currentNetworks () {
      return this.showSecondNetworks ? this.socialNetworksSecond : this.socialNetworksFirst
    }
  },

  created () {
    this.currentUrl = window.location.href
  },

  methods: {
    onCopyUrl () {
      this.$toast.show('Link copied')
    },
    shareNetworkOpened(network, url) {
      // Hubspot track
      window._hsq = window._hsq || [];
      _hsq.push(['trackCustomBehavioralEvent', {name: 'pe20302049_shared_duck',
        properties: { 
          yi_timestamp: new Date().toISOString().split('T')[0],
          VID: this.$store.getters['auth/isAuthenticated'] ? this.$store.getters['auth/user'].yid : '',
          duck_url: url,
          social_media_platform: network
        }
      }]);
    }
  }
}
</script>

<style lang="scss" scoped>


  .share-icons {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    justify-content: space-between;
    &__item {
      display: block;
      width: 100%;
      cursor: pointer;
      transition: 0.2s;
      :deep {
        svg {
          display: block;
        }
        circle, path {
          transition: 0.2s;
        }
      }
      &_network:hover {
        opacity: 0.8;
      }
      &_more {
        &:hover {
          :deep path {
            fill: $blue;
          }
        }
      }
      &_more-active {
        :deep {
          circle {
            fill: $black;
          }
          path {
            fill: $white;
          }
        }
        &:hover {
          :deep circle {
            fill: $gray;
          }
        }
      }
    }
  }
</style>
