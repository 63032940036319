import Stats from './stats.module.js'

export var _stats = function(cssStyle){
  this.dev_tools.stats = new Stats();
  this.dev_tools.stats.domElement.style.cssText = 
    cssStyle ? 
    cssStyle :
    'position:absolute;top:0px;left:0px;opacity:.6;';
  $('body').append(this.dev_tools.stats.dom);
};
