import {
  Group,
  MeshStandardMaterial,
  LoadingManager,
} from './../../../../../build/three.module.js';
//import {GLTFLoader} from './../../../../jsm/loaders/GLTFLoader.js';
import {FBXLoader} from './../../../../jsm/loaders/FBXLoader.js';
//import {ObjectControls} from '../control/ObjectControls.js';
import {initNodeMaterial} from '../materials/initNodeMaterial/index.js';

// добавление 3д объектов на сцену
export const addingObjectsToScene = async function(callback){

  const _this = this;

  const urlModel = _this.dataScene.scene.models[0].url;// + _this.dataScene.scene.models[0].file;
  const nameModel = _this.dataScene.scene.models[0].id;

  _this.loadingManager = new LoadingManager();

  const loader = new FBXLoader();
  loader.load(
    urlModel,
    async function(loadScene){

      // сборка материала для наложения на 3д модель (меш)
      const materialsId = {};
      for(let i=0, len=_this.dataScene.scene.models[0].meshes.length; i<len; i++){
        const mId = _this.dataScene.scene.models[0].meshes[i].materialId;
        const dataMaterial = _this.dataScene.scene.materials.find(mlt => mlt.id === mId);
        //const materialData = _this.dataScene.scene.materials.find(el => meshLink.materialId === el.id);
        if(dataMaterial){
          if(!materialsId[mId]){
            materialsId[mId] = true;
            _this.materials.ReadyNodeMaterials[mId] = await initNodeMaterial.bind(_this)(dataMaterial);
          }
        }
      };

      loadScene.traverse( async function(model){
        if(model.isMesh){

          // получаем  объект связи между мешем и материалом
          const meshLink = _this.dataScene.scene.models[0].meshes.find(el => model.name === el.meshName);
          // console.log('Имя модели из fbx-файлай: ', model.name, ' = ', meshLink.meshName, ' = ', meshLink.materialId);

          // накладываем материал на меш
          if(meshLink.materialId){
            if(meshLink && meshLink !== undefined){
              meshLink.active = true;
              model.material = _this.materials.ReadyNodeMaterials[""+meshLink.materialId];
              model.material.name = `${meshLink.materialId}-${model.name}`;
              model.castShadow = true;
              model.receiveShadow = true;
              //model.material.needsUpdate = true;
            }
          }else{
            model.material = new MeshStandardMaterial({ color: 0xebebeb });
          }

        }
      });

      loadScene.scale.set(
        _this.dataScene.scene.models[0].scale,
        _this.dataScene.scene.models[0].scale,
        _this.dataScene.scene.models[0].scale,
      );
      loadScene.position.x = _this.dataScene.scene.models[0].position.x;
      loadScene.position.y = _this.dataScene.scene.models[0].position.y;
      loadScene.position.z = _this.dataScene.scene.models[0].position.z;

      loadScene.rotation.x = _this.dataScene.scene.models[0].rotation.x * Math.PI / 180;
      loadScene.rotation.y = _this.dataScene.scene.models[0].rotation.y * Math.PI / 180;
      loadScene.rotation.z = _this.dataScene.scene.models[0].rotation.z * Math.PI / 180;

      _this.objScene = loadScene;
      _this.scene.add(_this.objScene);

      _this.EnvRotation = new Group();
      _this.EnvRotation.name = 'EnvRotation';

      //_this.EnvRotation.add(_this.camera);

      loadScene.name = 'FBXModel';
      _this.objScene = loadScene;
      _this.EnvRotation.add(_this.objScene);

      if(_this.loadingManager){
        _this.loadingManager.onLoad = () => {

          _this.scene.add(_this.EnvRotation);
          _this.scene.add(_this.camera);

          callback();
        }
      }

      // window.app3dStats.logs.push({model: `model loaded`})

    },
    function(xhr){
      //console.log(`${(xhr.loaded/xhr.total*100)}% loaded`)
      // window.app3dStats.logs.push({model: `${(xhr.loaded/xhr.total*100)}% loaded`})
    },
    function(error){
      console.log('An error happened', error);
      // window.app3dStats.logs.push({model: `load error!`, error: error});
      // if(window.app3dStats) _this.resLogs();
    }
  );

};
