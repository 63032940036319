<template lang="pug">
.controller
  .controller__top
    .controller__name {{ adjustment.name }}
    .controller__value {{ value }}
  vue-slider(
    v-model="value"
    tooltip="none"
    :dotStyle="{background: '#4040FF', border: '1px solid #FFFFFF', boxShadow: 'none'}"
    :railStyle="{backgroundColor: '#D3D3D3'}"
    :processStyle="{backgroundColor: '#4040FF'}"
    :min="adjustment.scope.min"
    :max="adjustment.scope.max"
    :interval="adjustment.step"
    :duration='0'
    :drag-on-click='true'
    @drag-end="onControllerChange"
  )
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'vizController',

  components: {
    VueSlider
  },

  props: {
    adjustmentPath: String
  },

  computed: {
    layer () {
      return this.$store.state.activeProject.activeLayer
    },
    materialId () {
      return this.$store.state.activeProject.activeMaterial.id
    },
    layerIndex () {
      return this.$store.state.activeProject.activeMaterial.activeLayerIndex
    },
    adjustment () {
      if (this.adjustmentPath === 'sizeTexture') {
        return {
          name: 'Texture scale',
          type: 'slider',
          visible: 1,
          value: this.layer.settings.texture.sizeTexture.value,
          step: 1,
          method: 'setTransformTexture',
          groups: [1],
          scope: {
            min: 1,
            max: 100
          }
        }
      } else {
        return _.get(this.layer.settings, this.adjustmentPath)
      }
    },
    value: {
      get () {
        return this.adjustment.value
      },
      set (val) {
        if (this.adjustmentPath === 'sizeTexture') {
          this.$store.commit('activeProject/layerSettingsLocalChange', {
            settingsPath: 'texture.sizeTexture',
            value: val
          })
        } else {
          this.$store.commit('activeProject/layerSettingsLocalChange', {
            settingsPath: this.adjustmentPath,
            value: val
          })
        }

        if (this.adjustment.method) {
          this.$store.commit('activeProject/adjustmentChange', {
            method: this.adjustment.method,
            args: [this.materialId, this.layerIndex, val]
          })
        }
      }
    }
  },

  methods: {
    onControllerChange () {
      this.$store.commit('activeProject/addedUnsavedChanges')
      this.$store.dispatch('projectHistory/addSnapshot', this.$store.state.activeProject.activeVariation.scene)
    }
  }
}
</script>

<style lang="scss" scoped>
  .controller {
    flex-shrink: 0;
    padding-top: 18px;
    &:first-child {
      padding-top: 0;
    }
    @include media-breakpoint-up(xl) {
      overflow: hidden;
      background: $gray;
      border-radius: 3px;
      padding: 13px 20px 6px;
      margin-top: 2px;
      &:first-child {
        margin-top: 0;
      }
    }
    &:deep {
      .vue-slider {
        cursor: pointer;
      }
      .vue-slider-dot {
        width: 22px !important;
        height: 22px !important;
        transition: width 0.2s, height 0.2s !important;

        @include media-breakpoint-up(xl) {
          width: 8px !important;
          height: 8px !important;
          &:hover, &:focus {
            width: 14px !important;
            height: 14px !important;
          }
          &::before {
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            position: absolute;
            left: calc(50% - 17px);
            top: calc(50% - 17px);
            cursor: pointer;
          }
        }
      }
      .vue-slider {
        height: 2px !important;
        padding: 14px 0 !important;
        box-sizing: content-box;
        @include media-breakpoint-up(xl) {
          margin: -7px 0;
          height: 1px !important;
        }
      }
    }

    &__top {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 6px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 11px;
      }
    }
    &__name, &__value {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: -0.419048px;
      color: $black;
      @include media-breakpoint-up(xl) {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
</style>
