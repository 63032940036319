import {initNodeMaterial} from '../../../modules/materials/initNodeMaterial/index.js';

export const updateDesignImage = async function(materialId, layerId, image, _file = null, callback){

  /*
  layerId: 1,
  materialId: '12343',
  image: {
    "url": imageUrl,
    "type": "designe",
    "id": "imageId_00234453"
  },
  */

  // поиск нужного материала
  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId) || null;

  if(material){

    const mId = material.id;
    const meshName = this.dataScene.scene.models[0].meshes.find(mesh => mesh.materialId === mId).meshName;

    this.materials.ReadyNodeMaterials[material.id] = await initNodeMaterial.bind(this)(material, {
      layerId: layerId,
      materialId: materialId,
      image: image,
    }, null, _file);

    for(let i=0, len=this.dataScene.scene.models[0].meshes.length; i<len; i++){
      const meshLink = this.dataScene.scene.models[0].meshes[i];
      if(meshLink.materialId === material.id){
        const mesh = this.scene.getObjectByName(meshLink.meshName);
        mesh.material = this.materials.ReadyNodeMaterials[material.id];
      }
    }

    if(callback) callback();

  }else{
    console.error(`No find material: ${materialId}`);
  }

};
