import {initNodeMaterial} from '../../../modules/materials/initNodeMaterial/index.js';

export const addLayer = async function(materialId, newLayer, _file = null, callback){

  // поиск нужного материала
  const materialDefault = this.dataSceneDefault.scene.materials.find(mtl => mtl.id === materialId) || null;
  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId) || null;

  if(material && materialDefault){

    if(newLayer.settings.designMap){
      // Добавление данных изображения в список всех изображений
      this.dataScene.scene.images.push(newLayer.settings.designMap);
    }

    // Доабвление нового словя в список слоев, в конец массива
    material.layers.push(newLayer);

    // const mId = material.id;
    // const meshName = this.dataScene.scene.models[0].meshes.find(mesh => mesh.materialId === mId).meshName;

    this.materials.ReadyNodeMaterials[material.id] = await initNodeMaterial.bind(this)(material, null, _file);

    for(let i=0, len=this.dataScene.scene.models[0].meshes.length; i<len; i++){
      const meshLink = this.dataScene.scene.models[0].meshes[i];
      if(meshLink.materialId === material.id){
        const mesh = this.scene.getObjectByName(meshLink.meshName);
        mesh.material = this.materials.ReadyNodeMaterials[material.id];
      }
    }

    if(callback) callback();

  }else{
    console.error(`No find material: ${materialId}`);
  }

};
