import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";

import Home from '../views/Home.vue'
import VizPlayer from '@/views/VizPlayer'
import Catalog from '@/views/Catalog'
import About from '@/views/About'
import MyProjects from '@/views/MyProjects'
import CreateProject from '@/views/CreateProject'

// Basic SSO auth
import LoginCallback from '../views/LoginCallback.vue'

// Static pages
import Rules from '../views/Rules.vue'
import Help from '../views/Help.vue'
import PageNotFound from '@/views/PageNotFound'

const routes = [
  {
    path: '/',
    name: 'Catalog',
    component: Home
  },
  {
    path: '/my-projects',
    name: 'My projects',
    component: MyProjects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/login',
    name: 'Login',
    component: {},
    meta: {
      externalUrl: process.env.VUE_APP_API_URL + '/authorize',
      guest: true
    }
  },
  {
    path: '/logincallback',
    name: 'Login Callback',
    component: LoginCallback,
    meta: {
      guest: true
    }
  },
  {
    path: '/3dmodels',
    name: '3d models Page',
    component: Catalog,
    meta: {
      admin: true
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/p/:id',
    component: VizPlayer
  },
  {
    path: '/c/:id',
    component: CreateProject
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: '',
  linkExactActiveClass: 'is-active',
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['auth/isAuthenticated']) {
      next('/');
      return;
    }
    next();
  } else {
    next();
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.admin)) {
    const userRole = store.getters['auth/userRole']
    if (userRole === 2 || userRole === 3) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.externalUrl)) {
    const url = to.meta.externalUrl
    const urlPattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

    if (url && url.match(urlPattern) !== null) {
      if (to.name === 'Login') {
        router.$cookie.setCookie('loginRefererUrl', from.path)
      }
      window.location.href = url
      return
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})


// Hubspot track pageview
router.afterEach(( to, from ) => {
  var _hsq = window._hsq = window._hsq || [];
  _hsq.push(['setPath', to.path]);
  _hsq.push(['trackPageView']);
});

export default router
