<template lang="pug">
viz-card-controller(
  name="Edit material"
  :is-active="isActive"
)
  .color-icon(
  )
    img.color-icon__preview(
      v-if="layer.settings.texture.previewUrl"
      :src="layer.settings.texture.previewUrl"
    )
</template>

<script>
import VizCardController from './vizCardController'
export default {
  name: 'vizMaterialController',

  components: { VizCardController },

  props: {
    isActive: Boolean
  },

  computed: {
    layer () {
      return this.$store.state.activeProject.activeLayer
    },
    adjustment () {
      return _.get(this.layer.settings, 'texture.color')
    }
  }
}
</script>

<style lang="scss" scoped>
  .color-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    @include media-breakpoint-up(xl) {
      width: 28px;
      height: 28px;
    }
    &__preview {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>
