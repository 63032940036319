//import * as THREE from "../../../build/three.module.js";
import {
  Scene,
  Vector2,
} from '../../../build/three.module.js';

import {data_query} from './modules/data_query/index.js';

// прелоадер
import {preloader} from './modules/preloader/index.js';

// материалы/шейдеры мешей
import {materials} from './modules/materials/index.js';

// инициализация событий
import {events} from './modules/events/index.js';

// камера
import {camera} from './modules/camera/index.js';

// окружение
import {environments} from './modules/environments/index.js'

// функция визуализации
import {renderer} from './modules/renderer/index.js';

// функция управления позицией камеры
import {control} from './modules/control/index.js';

// функция добавления 3д объектов
import {addingObjectsToScene} from './modules/addingObjectsToScene/index.js';

// функция добавления освещения
import {addingLightsToScene} from './modules/addingLightsToScene/index.js';

// функции звпуска визуализации
import {render} from './modules/render/index.js';

// функция смены кадров
import {animate} from './modules/animate/index.js';

import {randomString} from './modules/randomString/index.js';

// api
import {api} from './api/index.js';

import {_stats} from './modules/tools/stats/index.js';
import {_gui} from './modules/tools/gui/index.js';

class Visualizer3D {

  //constructor (idDrawingBlock = null, dataScene){
  constructor (data, callback){

    this.getDate = function(){
      return ((new Date()).toISOString().split('T')[0])+
        ' '+((new Date()).getHours())+':'+((new Date()).getMinutes())+
        ' UTC+'+((new Date()).getTimezoneOffset() / 60 * -1);
    };

    /*
    this.resLogs = function(){
      window.app3dStats.workTime.end = Date.now();
      const dataStats = {
        event_name: 'stats',
        data: window.app3dStats,
      };
      fetch(
        'https://3dvisualizer-api.develop.getyellow.com/v1/userexperience',
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json;charset=utf-8'},
          body: JSON.stringify(dataStats),
        }
      );
      delete window.app3dStats;
    };
    */

    /*
    { // init log object

      window.app3dStats = {
        project: {
          url: window.location.href,
        },
        fps: [],
        mem: [],
        navigator: {
          userAgent: window.navigator.userAgent,
          platform: window.navigator.platform,
        },
        date: this.getDate(),
        workTime: {
          start: Date.now(),
          end: 0,
        },
        screen: {
          width: innerWidth,
          height: innerHeight,
        },
        canvas: [],
        logs: [],
      };

      const resLogs = this.resLogs;

      const t = 1000;
      let timerId = setTimeout(function tick() {
        if(window.app3dStats && window.app3dStats.fps.length === 180){
          resLogs();
        }else if(window.app3dStats){
          timerId = setTimeout(tick, t);
        }
      }, t);

    }
    */

    // переменные из строки запроса для отображения
    // вспомогательных елементов на web-странице в режиме разработчика
    data_query.bind(this)();

    // добавляем api
    api.bind(this)();

    this.hoverObject = {
      mouse: new Vector2(),
      object: null,
    };

    this.dataScene = data.dataScene[0] ?? data.dataScene; // данные сцены/товара
    this.dataSceneDefault = JSON.parse(JSON.stringify(data.dataScene[0] ?? data.dataScene)); // данные сцены/товара для значений по умолчанию
    if(this.dataQuery && this.dataQuery.dev) console.log("Scene data:\n", this.dataScene);

    const __images = this.dataScene.scene.images;

    if(data.textureList){
      data.textureList.forEach(texture => {
        if(texture.files_list){
          texture.files_list.forEach(file => {
            const existFile = __images.find(f => f.id === file.id);
            if (!existFile) {
              __images.push({
                id: file.id,
                name: file.original_name,
                type: 'texture',
                url: file.path,
                globalType: 'texture',
              });
            }
          });
        }
      });
    }

    {
      const __normalMap = __images.find(f => f.id === 'Normal0.png');
      if(!__normalMap){
        __images.push({
          id: 'Normal0.png',
          name: 'Normal0.png',
          type: 'normalMap',
          url: '/images/Normal0.png',
          globalType: 'texture',
        });
      }
    }

    // если есть данные сцены/товара, запускается инициализация
    if(this.dataScene){

      if(data.container){

        { // start | html-елементы приложения

          this.DOM = {};
          // контейнер приложения
          this.DOM.container = data.container;
          // контейнер, куда будет добавлен canvas
          this.DOM.drawing = document.createElement('div');
          this.DOM.drawing.classList.add('drawing');
          this.DOM.container.appendChild(this.DOM.drawing);

        } // end | html-елементы приложения

        this.randomString = randomString.bind(this);
        // this.preloader = preloader; // функция отображения прелоадера
        // this.preloader(this.DOM.container); // включение прелоадера

        this.scene = new Scene();

        this.materials = materials; // библиотека материалов

        // добавление главной камеры
        camera.bind(this)();

        events.bind(this)(); // инициализация событий

        // инициализаци и настройка рендера сцены
        renderer.bind(this)();

        // добавление контрола камеры
        control.bind(this)();

        {

          const _this = this;

          new Promise((res, rej) => {

            // объект с картами коружения
            environments.bind(_this)(res);

          }).then(function(){

            addingObjectsToScene.bind(_this)(function(){ // добавление 3д объектов в сцену

              addingLightsToScene.bind(_this)(); // добавление освещения в сцену

              {

                let opacity = 0;

                // режим разработчика. показ статистики и контроллера
                if(_this.dataQuery && _this.dataQuery.dev) opacity = 1;

                if(_this.dataQuery && _this.dataQuery.dev){
                  _this.dev_tools = {};
                  _stats.bind(_this)(`position:fixed;top:162px;left:0px;opacity:${opacity};z-index:99999999;`);
                  _gui.bind(_this)();
                }
                // window.App3d = _this;

              }

              // запуск визуализации
              _this.start();

              delete _this.loadingManager;

              if(callback) callback();

            });

          });

        }

      }else{
        throw new Error('No dom element');
      }

    }else{
      throw new Error('No data object');
    }

  }

  // функция запуска визуализации 3д приложения
  start(){
    if(this.DOM.drawing){

      this.render = render.bind(this); // инициализация визуализации
      this.animate = animate.bind(this); // инициализация смены кадров
      this.animate(); // запуск смены кадров

      // если режим разработки, то показывается весь объект 3д приложения
      if(this.dataQuery && this.dataQuery.dev) console.log(this);

    }else{
      console.error(`no canvas`);
    }
  }

}

export const Viz3D = Visualizer3D;
