<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="ducks-limit"
  @modalClose="isOpen = false"
)
  template(
    #default
  )
    h2.standard-modal__title Unable to create
    div Unfortunately, you have already created 3 projects

  template(
    v-slot:buttons="{ params }"
  )
    base-button(
      v-if="$route.matched[0]?.components?.default?.name === 'MyProjects'"
      @click="isOpen = false"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Ok
    base-button(
      v-else
      to="/my-projects"
      @click="isOpen = false"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Go to my projects
</template>

<script>
import BaseButton from '../BaseButton'
import StandardModal from '@/components/modals/StandardModal'
export default {
  name: 'DucksLimit',

  inject: ["mq"],
  components: { StandardModal, BaseButton },

  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
