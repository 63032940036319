<template lang="pug">
.menu(
  :class="{'menu_card-open': activeMenuItem === 'screenshot'}"
)
  ul.menu__list
    li.menu__item
      button.menu__item-btn(
        :class="{'menu__item-btn_active': activeMenuItem === 'move'}"
        v-tooltip.right="{theme: 'hint', content: 'Move and rotate', distance: 32, shown: isHintsOpen, triggers: hintsTriggers}"
        @click="activeMenuItem = 'move'"
      )
        rotate360(
          :size="28"
        )
    li.menu__item
      button.menu__item-btn(
        v-tooltip.right="{theme: 'hint', content: 'Reset view', distance: 32, shown: isHintsOpen, triggers: hintsTriggers}"
        @click="() => {$store.commit('activeProject/resetCameraPosition'); activeMenuItem = 'move'}"
      )
        cube-scan(
          :size="28"
        )
    li.menu__item
      a.menu__item-btn(
        href="https://yellowimages.com/contact-us"
        target="_blank"
        v-tooltip.right="{theme: 'hint', content: 'Help', distance: 32, shown: isHintsOpen, triggers: hintsTriggers}"
      )
        live-help(
          :size="28"
        )
    li.menu__item
      a.menu__item-btn(
        href="/rules"
        target="_blank"
        v-tooltip.right="{theme: 'hint', content: 'Rules', distance: 32, shown: isHintsOpen, triggers: hintsTriggers}"
      )
        checkbox-marked-circle-outline(
          :size="28"
        )
    li.menu__item(
      v-if="$store.state.activeProject.editAccess"
    )
      button.menu__item-btn(
        v-tooltip.right="{theme: 'hint', content: 'Create screenshot', distance: 32, shown: isHintsOpen, triggers: hintsTriggers}"
        @click="openScreenshotMenu"
        :class="{'menu__item-btn_active': activeMenuItem === 'screenshot'}"
      )
        camera-enhance-outline(
          :size="28"
        )
    li.menu__item(
      v-if="$store.state.activeProject.editAccess && mq.xlPlus && $store.state.activeProject.project.state === 0"
    )
      button.menu__item-btn(
        :class="{'menu__item-btn_active': activeMenuItem === 'hints'}"
        v-tooltip.right="{theme: 'hint', content: 'About interface', distance: 32, shown: isHintsOpen, triggers: hintsTriggers}"
        @click="$store.commit('activeProject/switchHints')"
      )
        BaseIcon(
          data="@icon/info-icon.svg"
          width="28"
          height="28"
          color="#000000"
        )
    li.menu__item(
      v-if="!$store.state.activeProject.editAccess"
    )
      button.menu__item-btn(
        v-tooltip.right="{theme: 'hint', content: 'Create a duck', distance: 32}"
        @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
      )
        BaseIcon(
          data="@icon/duck-icon.svg"
          width="28"
          height="28"
          color="#000000"
        )

  .additional-setting(
    v-show="activeMenuItem === 'screenshot'"
  )
    viz-card(
      title="Screenshot"
      close-button
      @closeCard="activeMenuItem = 'move'"
    )
      viz-screenshot-settings
</template>

<script>
import CubeScan from 'vue-material-design-icons/CubeScan.vue'
import CheckboxMarkedCircleOutline from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue'
import CameraEnhanceOutline from 'vue-material-design-icons/CameraEnhanceOutline.vue'
import Rotate360 from '../icons/Rotate360'
import LiveHelp from '../icons/LiveHelp'
import VizCard from "@/components/player/vizCard";
import VizScreenshotSettings from "@/components/player/vizScreenshotSettings";
export default {
  name: 'vizMenu',

  inject: ["mq"],

  components: {
    VizScreenshotSettings,
    VizCard,
    LiveHelp,
    Rotate360,
    CubeScan,
    CheckboxMarkedCircleOutline,
    CameraEnhanceOutline
  },

  data () {
    return {
      hintsTriggers: ['hover', 'focus'],
      activeMenuItem: 'move'
    }
  },

  computed: {
    userRole () {
      return Number(this.$store.getters['auth/userRole'])
    },
    activeProject () {
      return this.$store.state.activeProject.project
    },
    isHintsOpen () {
      const hints = this.$store.state.activeProject.isHintsOpen

      if (hints) {
        this.hintsTriggers = []
        this.activeMenuItem = 'hints'
      } else {
        this.hintsTriggers = ['hover', 'focus']
        if (this.activeMenuItem === 'hints') {
          this.activeMenuItem = 'move'
        }
      }

      return hints
    }
  },

  methods: {
    openScreenshotMenu () {
      this.activeMenuItem = 'screenshot'

    }
  },

  mounted() {
    if (this.mq.lgMinus) {
      this.hintsTriggers = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu {
    background: $white;
    border-radius: 16px;
    right: 0;
    top: 0;
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    z-index: 3;
    @include media-breakpoint-up(xl) {
      border-radius: 8px;
      left: 45px;
      top: 50%;
      right: auto;
      transform: translateY(-50%);
      width: 58px;
      padding: 0 12px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
    @include media-breakpoint-down(xl) {
      &_card-open {
        background: inherit;
        box-shadow: none;
      }
      &_card-open &__list {
        opacity: 0;
        pointer-events: none;
      }
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      @include media-breakpoint-down(xl) {
        display: flex;
        align-items: center;
        height: 56px;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      border-right: 1px solid $gray;
      &:last-child {
        border-bottom: none;
        border-right: none;
      }
      @include media-breakpoint-up(xl) {
        padding: 14px 0;
        border-bottom: 1px solid $gray;
        border-right: none;
      }
    }
    &__item-btn {
      background: inherit;
      border: none;
      padding: 0;
      display: block;
      cursor: pointer;
      &:deep {
        svg {
          display: block;
        }
        path {
          transition: 0.2s ease;
        }
      }
      &:hover, &_active {
        &:deep path {
          fill: $blue;
        }
      }
    }
  }
  .additional-setting {
    position: absolute;
    width: 284px;
    top: 0;
    right: 0;

    @include media-breakpoint-up(xl) {
      bottom: 0;
      left: 66px;
      top: auto;
      right: auto;
      width: 250px;
    }
    @media (max-height: 900px) and (min-width: 1280px) {
      bottom: 50%;
      transform: translateY(50%);
    }

    :deep .card {
      max-height: inherit;
    }
  }
</style>
