<template lang="pug">
viz-card(
  title="About creator"
  :style="{minHeight: '200px'}"
)
  .author-header
    img.author-header__photo(
      :src="projectAuthor.avatar"
      :alt="projectAuthor.full_name"
    )
    .author-header__name {{ projectAuthor.full_name }}
  ul.author-data
    li.author-data__item(
      v-for="data in authorData"
      :key="data.title"
    )
      .author-data__title {{ data.title }}
      .author-data__desc {{ data.description }}
</template>

<script>
import VizCard from './vizCard'
import moment from "moment";
export default {
  name: 'vizAuthor',

  components: { VizCard },

  data () {
    return {
      authorData: [
        {
          title: 'Project Name',
          description: this.projectName
        },
        {
          title: 'Released',
          description: moment(this.$store.state.activeProject.project.updated_at).format('MMMM DD, YYYY')
        },
        {
          title: 'Views',
          description: this.viewsCount
        },
        {
          title: 'Likes',
          description: this.likeCount
        }
      ]
    }
  },

  computed: {
    projectName () {
      const projectName = this.$store.state.activeProject.project.name
      this.authorData[0].description = projectName
      return projectName
    },
    projectAuthor () {
      return this.$store.state.activeProject.project.owner
    },
    likeCount () {
      const likeCount = this.$store.state.activeProject.project.likes
      this.authorData[3].description = likeCount
      return likeCount
    },
    viewsCount () {
      const viewsCount = this.$store.state.activeProject.project.views
      this.authorData[2].description = viewsCount
      return viewsCount
    }
  },

  created () {
    this.authorData[0].description = this.projectName
    this.authorData[2].description = this.viewsCount
    this.authorData[3].description = this.likeCount
  },

  watch: {
    projectName () {
      this.authorData[0].description = this.projectName
    },
    likeCount () {
      this.authorData[3].description = this.likeCount
    }
  }
}
</script>

<style lang="scss" scoped>


.author-header {
  display: flex;
  align-items: center;
  padding-bottom: 17px;
  padding-top: 6px;
  border-bottom: 2px solid $gray;
  &__photo {
    width: 52px;
    height: 52px;
    display: block;
    margin-right: 12px;
    flex-shrink: 0;
    border-radius: 50%;
  }
  &__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: -0.609524px;
  }
}

.author-data {
  padding: 0;
  margin: 12px 0 0 0;
  list-style: none;
  &__item {
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    color: $gray-dark;
    font-size: 10px;
  }
  &__desc {
    font-size: 12px;
    font-weight: 700;
  }
}
</style>
