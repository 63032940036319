function setAOTextureOpacity(materialId, layerId, value){

  const material = this.dataScene.scene.materials.find(mtl => mtl.id === materialId) || null;
  const layer = material.layers[layerId] || null;

  if(layer){
    layer.settings.texture.aoTextureOpacity.value.value = Number(value);
  }

}

export {
  setAOTextureOpacity,  
};
