<template lang="pug">
nav#navigation.navigation.bg-gray.d-flex.flex-nowrap.align-items-stretch.align-items-md-center(
  :class="{'navigation_dark': dark}"
  ref="navigation"
)
  .navigation__gallery.align-items-center.flex-nowrap.d-md-flex.d-none
    .visualizer-logo
      router-link.visualizer-logo__link(
        to="/"
      )
      BaseIcon.visualizer-logo__icon(
        data="@icon/visualizer-logo.svg"
        width="42"
        height="42"
        :original="true"
      )
    BaseButton(
      to="/"
      :color="dark ? 'default' : 'white'"
      activeClass="button_blue"
    ) Gallery

  .navigation__center.flex-nowrap.d-md-flex.d-none
    BaseButton(
      to="/about"
      :color="dark ? 'default': 'white'"
      activeClass="button_blue"
    ) About

    BaseButton(
      to="/rules"
      :color="dark ? 'default': 'white'"
      activeClass="button_blue"
    ) Rules

    BaseButton(
      href="https://yellowimages.com/contact-us"
      :color="dark ? 'default': 'white'"
      target="_blank"
      color="white"
    ) Help

  BaseSelect.navigation__select.d-md-none(
    :clearable="false"
    :searchable="false"
    label="title"
    v-model="selectedLink"
    :options="selectLinks"
  )
    template(
      v-slot:option="option"
    )
      router-link(
        @click.stop
        v-if="option.to"
        :to="option.to"
      ) {{ option.title }}
      a(
        @click.stop
        v-else
        :href="option.url"
      ) {{ option.title }}

  .navigation__right.d-flex.flex-nowrap
    BaseButton.d-md-inline-flex.d-none(
      to="/my-projects"
      :color="dark ? 'default': 'white'"
      activeClass="button_blue"
      v-if="isLoggedIn"
    ) Мy projects

    BaseButton.button_mobile.navigation__add(
      :color="dark ? 'blue': 'default'"
      @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
    )
      span.d-md-flex.d-none Create your duck
      BaseIcon.d-md-none(
        data="@icon/duck.svg"
        width="18"
        height="17"
        color="#fff"
      )
</template>

<style lang="scss" scoped>
  .navigation {
    margin: 0px -20px 50px;
    padding: 25px 20px;
    border-radius: 12px;
    white-space: nowrap;

    @media (max-width: 1648px) {
      margin-left: 0;
      margin-right: 0;
    }

    @include media-breakpoint-down(lg) {
      padding: 16px 16px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .button {
      @include media-breakpoint-up(lg) {
        min-width: 113px;
      }
    }

    &__gallery {
      margin: 0 6px 0 0;

      @include media-breakpoint-down(lg) {
        margin-right: 4px;
      }

      .button {
        margin: 0 0 0 12px;

        @include media-breakpoint-down(lg) {
          margin: 0 0 0 8px;
        }
      }
    }

    &__center {
      .button {
        margin: 0 6px;

        @include media-breakpoint-down(lg) {
          margin: 0 4px;
        }
      }
    }

    &__right {
      margin: 0 0 0 auto;

      @include media-breakpoint-down(lg) {
        .button_white {
          margin: 0 4px;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 0 0 0 16px;
      }

      .navigation__add {
        margin-left: 12px;
        margin-right: 0;
        min-width: 168px;

        .svg-icon {
          margin: 0 6px 0 4px;
        }

        @include media-breakpoint-down(lg) {
          min-width: 0;
          margin-left: 4px;
        }

        @include media-breakpoint-down(md) {
          letter-spacing: -0.4px;
          margin: 0;
          padding: 10px 20px;
          height: 52px;

          &:after {
            content: '+';
          }
        }
      }
    }

    &__select {
      flex-direction: column;
      flex: 1 1 auto;
      font-size: 14px;

      &:deep {
        .vs__dropdown {
          &-toggle {
            flex: 1 1 auto;
            height: auto;
            border-radius: 999px;
            border: 1px solid #E2E5E9;
            padding: 0 19px;
          }
          &-option {
            padding: 0;
            a {
              padding: 7px 20px;
              display: block;
              width: 100%;
              color: inherit;

              &:hover, &.is-active {
                color: $blue;
              }
            }
            &--highlight {
              color: inherit;
            }
          }
          &-menu {
            padding: 14px 0;
            border-radius: 26px;
            top: calc(100% + 8px);
          }
        }
        .vs__selected {
          line-height: 1;
        }
        .vs__actions {
          margin: 0 0 0 10px;
        }
      }
    }

    &_dark {
      background-color: rgba(32,31,34,0.70) !important;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      .navigation__select {
        color: $white;

        &:deep {
          .vs__dropdown {
            &-toggle {
              background: $black;
              border: 1px solid #656565;
            }
            &-menu {
              background: #2E2D30;
            }
            &-option {
              a:hover, a.is-active {
                color: $yellow;
              }
            }
          }
          .vs__open-indicator path {
            stroke: $white;
          }
        }
      }

    }

  }
</style>

<script>
import BaseButton from '@/components/BaseButton.vue'
import BaseSelect from 'vue-select'
import ArrowDown from '@/components/icons/ArrowDown.vue'
BaseSelect.props.components.default = () => ({
  OpenIndicator: ArrowDown
});

export default {
  name: 'TheNavigation',
  inject: ["mq"],
  components: {
    BaseButton,
    BaseSelect
  },
  props: {
    dark: Boolean
  },
  data: () => ({
    selectedLink: null,
    links: [
      {
        title: 'Gallery',
        to: '/'
      },
      {
        title: 'My projects',
        to: '/my-projects',
        needLogin: true
      },
      {
        title: 'About',
        to: '/about'
      },
      {
        title: 'Rules',
        to: '/rules'
      },
      {
        title: 'Help',
        url: 'https://yellowimages.com/contact-us'
      }
    ]
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isAuthenticated']
    },
    selectLinks() {
      return this.isLoggedIn ? this.links : this.links.filter(link => !link?.needLogin)
    }
  },
  mounted() {
    let routeIndex = _.findIndex(this.links, {to: this.$route.path });
    let selectedLinkIndex = routeIndex === -1 ? 0 : routeIndex
    this.selectedLink = this.links[selectedLinkIndex]
  }
}
</script>
