<template lang="pug">
viz-setting-mobile-item.mobile-help(
  tab-name="viz-help"
)
  template(
    #activator
  )
    BaseIcon(
      data="@icon/help.svg"
      width="24"
      height="24"
    )
  .mobile-help__window
    button.mobile-help__close-btn(
      @click="$store.commit('activeProject/closeMobileTab')"
    )
      BaseIcon(
        data="@icon/close.svg"
        width="14"
        height="14"
      )
    ul.mobile-help__list
      li.mobile-help__item
        router-link(
          to="/rules"
        ) Rules
      li.mobile-help__item
        a(
          href="https://yellowimages.com/contact-us"
          target="_blank"
        ) Help
      li.mobile-help__item(
        @click="$vfm.show('how-create')"
      ) How create my own duck
</template>

<script>
import VizSettingMobileItem from "@/components/player/vizSettingMobileItem";
export default {
  name: "vizMobileHelp",
  components: {VizSettingMobileItem}
}
</script>

<style lang="scss" scoped>
.mobile-help {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    cursor: pointer;
    padding: 18px 0;
    font-size: 16px;
    letter-spacing: -0.71px;
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    border-bottom: 1px solid $gray;
    &:last-child {
      border-bottom: none;
    }
  }
  &__window {
    @include media-breakpoint-down(md) {
      margin-bottom: -16px;
    }
  }
  &__close-btn {
    display: none;
    @include media-breakpoint-up(md) {
      position: absolute;
      padding: 0;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      background: inherit;
      border: none;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        color: $blue;
      }
    }
  }
  :deep {
    .setting-item__btn {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
    .setting-item__content {
      width: 300px;
      top: calc(100% + 8px);
      left: 0;
      right: auto;
      background: $white;
      box-shadow: 0 10px 30px 0 rgba(0,0,0,0.05);
      border-radius: 16px;
      padding: 40px 16px 0;
      text-align: center;
    }
  }
}
</style>
