<template lang="pug">
.container
  TheNavigation

  p(
    v-if="error"
  ) {{ error }}
</template>

<script>
import { mapActions } from "vuex";
import TheNavigation from '@/components/TheNavigation.vue'

export default {
  name: 'LoginCallback',
  components: {
    TheNavigation
  },
  data: () => ({
    error: null
  }),
  async beforeMount () {
    const defaultError = 'There was a problem with the login. Please try again to click "Join now" button.'
    if (this.$route.query?.data) {
      const token = atob(this.$route.query.data)

      this.$store.dispatch('auth/LogIn', token)
        .then(user => {
          // Detect login referrer url
          let refererUrl = this.$cookie.getCookie('loginRefererUrl') || '/'
          this.$cookie.removeCookie('loginRefererUrl')

          // Hubspot track
          window._hsq = window._hsq || [];
          _hsq.push(['trackCustomBehavioralEvent', {name: 'pe20302049_duck_join_now',
            properties: {
              yi_timestamp: new Date().toISOString().split('T')[0],
              VID: user.yid,
              hs_page_url: refererUrl
            }
          }]);

          // Redirect back to referer
          this.$router.push(refererUrl)
          // window.location.href = refererUrl
        })
        .catch(() => {
          this.error = defaultError
        })
    } else {
      this.error = defaultError
    }
  },
  methods: {
    ...mapActions(['LogIn']),
  },
}
</script>
