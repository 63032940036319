<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="delete-project"
  @modalClose="isOpen = false"
)
  template(
    #default
  )
    h2.standard-modal__title Delete duck
    div Are you sure you want to remove the duck? You will not be able to restore it, and you will also lose likes and views

  template(
    v-slot:buttons="{ params }"
  )
    base-button(
      color="gray"
      @click="isOpen = false"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Don’t delete
    base-button(
      color="gray"
      @click="deleteProject(params.projectId)"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Yes
</template>

<script>
import BaseButton from '../BaseButton'
import StandardModal from '@/components/modals/StandardModal'
export default {
  name: 'DeleteProject',
  inject: ["mq"],
  components: { StandardModal, BaseButton },

  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    deleteProject (projectId) {
      this.$store.dispatch('activeProject/deleteProject', projectId).then(() => {
        this.$store.dispatch('projects/getUserProjects')
        this.isOpen = false
        this.$router.push('/my-projects')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
