<template lang="pug">
.container
  TheNavigation

.container
  .row
    .col-md-12.col-lg-10.col-xl-8
      h1.lead-header Create your Duck. <span class="d-block d-md-inline">It’s a chance <span class="nobr">to&nbsp;win an&nbsp;iPad&nbsp;Pro!</span></span>

  HomeSlides

  Leaders

  section.projects-section
    h2 3D gallery from <br>independent authors

    Flicking.row.row_scroller.filters(
      :options="{ moveType: 'freeScroll', bound: true, align: 'prev', bounce: 0, autoResize: true }"
      @ready="filtersReady"
      ref="filters"
      @wheel="onWheel($event, this.$refs.filters)"
    )
      .col.col-auto(:key="0")
        BaseButton.button_mobile(
          color="gray"
          @click="$store.dispatch('projects/getProjectsShowcase', 'published')"
          :class="{'is-active': activeFilter === 'published'}"
        ) Freshly baked
      .col.col-auto(:key="1")
        BaseButton.button_mobile(
          color="gray"
          @click="$store.dispatch('projects/getProjectsShowcase', 'likes')"
          :class="{'is-active': activeFilter === 'likes'}"
        ) Popular
      .col.col-auto(:key="2")
        BaseButton.button_mobile(
          color="gray"
          @click="$store.dispatch('projects/getProjectsShowcase', 'views')"
          :class="{'is-active': activeFilter === 'views'}"
        ) Most viewed
      .col.col-auto(:key="3")
        BaseButton.button_mobile(
          color="gray"
          @click="$store.dispatch('projects/getProjectsShowcase', 'selected')"
          :class="{'is-active': activeFilter === 'selected'}"
        ) Selected 12
      .col.col-auto(
        :key="4"
        v-if="userRole === 2 || userRole === 3"
      )
        BaseButton.button_mobile(
          color="gray"
          @click="$store.dispatch('projects/getProjectsShowcase', 'waiting_approval')"
          :class="{'is-active': activeFilter === 'waiting_approval'}"
        ) Waiting for approval
      .col.col-auto(
        :key="5"
        v-if="userRole === 2 || userRole === 3"
      )
        BaseButton.button_mobile(
          color="gray"
          @click="$store.dispatch('projects/getProjectsShowcase', 'blocked')"
          :class="{'is-active': activeFilter === 'blocked'}"
        ) Blocked


    .row.projects
      .col-6.col-sm-4.col-lg-3.col-xl-2(
        v-for="(project, projectIndex) in projects"
        :key="projectIndex"
      )
        ProjectItem(
          :item="project"
        )

    .row.projects__bottom
      .col-sm-12.d-flex.align-items-center.justify-content-start.flex-wrap
        .col
        .col
          BaseButton.projects__load(
            color="gray"
            @click="this.$store.dispatch('projects/getProjectsShowcase', activeFilter)"
            v-if="!$store.state.projects.isLastProjectPage"
          ) Load more ducks

        .col
          BaseButton.projects__scroll(
            color="gray"
            @click="scrollToTop"
          )
            span.d-md-inline.d-none Return to top
            BaseIcon(
              data="@icon/arrow-top.svg"
              width="14"
              height="15"
            )
</template>

<script>
// @ is an alias to /src
import BaseButton from '@/components/BaseButton.vue'

import TheNavigation from '@/components/TheNavigation.vue'
import ProjectItem from '@/components/ProjectItem.vue'

import HomeSlides from '@/components/HomeSlides.vue'
import Leaders from "@/components/Leaders"

import Flicking from "@egjs/vue3-flicking"
import { ROUTE_HEAD } from '@/data/serverRoutersData'
import {useHead} from "@vueuse/head";

export default {
  name: 'Home',

  components: {
    Leaders,
    BaseButton,
    TheNavigation,
    ProjectItem,
    HomeSlides,
    Flicking
  },

  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    filtersReady(e) {
      setTimeout(() => {
        e.currentTarget.resize()
      }, 2000)
    },
    onWheel(e, flick){
      if (e.deltaX !== 0) {
        e.preventDefault();
        flick.control.controller.axes.setTo({ flick: flick.camera.position + e.deltaX }, 0);
      }
    }
  },

  computed: {
    projects () {
      return this.$store.state.projects.projectsList
    },
    userRole () {
      return Number(this.$store.getters['auth/userRole'])
    },
    activeFilter () {
      return this.$store.state.projects.projectsFilter
    }
  },

  created () {
    this.$store.commit('projects/clearProjectShowcase', 'published')
    this.$store.dispatch('projects/getProjectsShowcase', this.activeFilter)
  },
  setup () {
    useHead({
      title: ROUTE_HEAD.main.title
    })
  }
}
</script>

<style lang="scss" scoped>
  .projects {
    &__bottom {
      margin-top: 20px;
      margin-bottom: 40px;

      .col {
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }

    &__load {
      white-space: nowrap;
    }

    &__scroll {
      .svg-icon {
        margin: 0 -3px 0 11px;
      }

      @include media-breakpoint-down(md) {
        padding: 0;
        width: 42px;

        .svg-icon {
          margin: 0;
        }
      }
    }
  }

  .filters {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    margin: 14px -26px 52px;

    [class*='col-'] {
      padding: 0 6px;

      @include media-breakpoint-down(md) {
        padding: 0 4px;
        width: auto!important;
      }
    }

    @include media-breakpoint-down(xxl) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(md) {
      margin: 0 -14px 25px;
    }

    &:deep .flicking-camera {
      flex-wrap: nowrap;
    }

    .button {
      min-width: 125px;

      &.is-active {
        background: $black;
        color: $white;
        pointer-events: none;
      }
    }
  }
</style>
