<template lang="pug">
.page-not-found
  .container
    .page-not-found__wrap
      BaseIcon.page-not-found__logo(
        data="@icon/logo.svg"
        width="42"
        height="42"
        color="currentColor _ _"
      )
      h1.page-not-found__title Oops! Something went wrong.
      .page-not-found__content
        | Sorry, the page you requested was not found. But you can go to the gallery, or create your own duck.
      .d-flex.justify-content-center
        base-button.page-not-found__btn(
          to="/"
          color="gray"
        ) See gallery
        base-button.page-not-found__btn(
          @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
        ) Create duck
</template>

<script>
import BaseButton from "@/components/BaseButton";
import {useHead} from "@vueuse/head";
import {ROUTE_HEAD} from "@/data/serverRoutersData";
export default {
  name: 'PageNotFound',
  inject: ["mq"],
  components: {BaseButton},
  setup () {
    useHead({
      title: ROUTE_HEAD.main.title
    })
  }
}
</script>

<style lang="scss" scoped>
.page-not-found {
  flex-grow: 1;
  margin-top: -20px;
  margin-bottom: -20px;
  background-image: url(https://cdn.yellowimages.com/3dvisualizer/404/bg-md.png);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(xxl) {
    background-image: url(https://cdn.yellowimages.com/3dvisualizer/404/bg.png);
  }
  &__wrap {
    text-align: center;
    margin-top: -80px;
    padding: 40px 0;
  }
  &__title {
    font-size: 36px;
    margin-bottom: 16px;
  }
  &__content {
    max-width: 432px;
    margin: 0 auto 24px;
    font-size: 16px;
  }
  &__btn {
    margin: 0 10px;
    min-width: 150px;
  }
  &__logo {
    color: $black;
    fill: $yellow;
    display: block;
    margin: 0 auto 32px;
  }
}
</style>
