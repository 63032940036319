<template lang="pug">
footer#footer.footer.bg-gray.d-flex.align-items-center(
  :class="{'footer_dark': dark}"
)
  .container.d-flex.align-items-center
    BaseSelect.footer__menu(
      :clearable="false"
      :searchable="false"
      label="title"
      v-model="selectedMoreLink"
      :options="moreLinks"
      :selectable="option => !option.disabled"
    )
      template(
        v-slot:option="option"
      )
        a.footer__menu-link(
          @click.stop
          :href="option.url"
        ) {{ option.title }}

    BaseSelect.footer__lang(
      :clearable="false"
      :searchable="false"
      label="title"
      :disabled="true"
      v-model="selectedLanguage"
      :options="languages"
      :reduce="language => language.code"
      :selectable="option => !option.disabled"
      :noDrop="true"
    )
      template(
        v-slot:option="option"
      )
        span {{ option.icon }}
        | {{ option.title }}

      template(
        v-slot:selected-option="option"
      )
        span {{ option.icon }}
        | {{ option.title }}

    .footer__links.d-md-block.d-none
      a.footer__link(
        v-for="(link, linkIndex) in links"
        :href="link.url"
      ) {{ link.title }}

    .footer__logo
      BaseIcon.footer__icon(
        data="@icon/logo.svg"
        width="27"
        height="27"
        color="currentColor _ _"
      )
      | GetYellow
</template>

<style lang="scss" scoped>
  .footer {
    flex: 0 0 auto;
    height: 42px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;

    &__menu {
      &:deep {
        .vs__dropdown-option {
          padding: 0;
          a {
            padding: 6px 17px;
            display: block;
            width: 100%;
          }
        }
      }
    }

    &:deep {
      .vs__selected, .vs__dropdown-option {
        span {
          display: inline-block;
          margin: 2px 10px 0 -3px;
          font-size: 19px;

          @include media-breakpoint-down(md) {
            margin: 1px 6px 0 -2px;
            font-size: 18px;
          }
        }
      }

      .vs__dropdown-menu {
        top: auto;
        bottom: calc(100% + 10px);
      }
    }

    .v-select {
      margin: 0 12px 0 0;

      @include media-breakpoint-down(md) {
        margin: 0 8px 0 0;
      }
    }

    &__links {
      letter-spacing: -0.45px;
      margin: 0 10px 0 5px;
    }
    &__link {
      margin: 0 17px;
    }

    &__logo {
      letter-spacing: -0.71px;
      margin: 0 1px 0 auto;
      padding: 2px 0 0 0;
      &:deep {
        .svg-icon {
          color: $gray;
          fill: $black;
          margin: -2px 9px 0 0;
        }

        @include media-breakpoint-down(md) {
          margin: -2px 5px 0 0;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 0 0 0 auto;
      }
    }
    &__lang {
      &:deep {
        .vs__actions {
          display: none;
        }
      }
    }


    &_dark {
      background: #201F22 !important;
      &:deep {
        .vs__dropdown-toggle {
          background: rgba(255, 255, 255, 0.1);
        }
        .vs__selected {
          color: $white;
        }
        .vs__open-indicator g {
          stroke: $white;
        }
      }
    }

    &_dark &__link {
      color: $white;
      &:hover {
        color: $blue
      }
    }

    &_dark &__logo {
      color: $white;
      &:deep {
        .svg-icon {
          color: $black;
          fill: $white;
        }
      }
    }
  }
</style>

<script>
import BaseSelect from 'vue-select'
import ArrowDown from '@/components/icons/ArrowDown.vue'
BaseSelect.props.components.default = () => ({
  OpenIndicator: ArrowDown
});

export default {
  name: 'TheFooter',
  components: {
    BaseSelect
  },
  props: {
    dark: Boolean
  },
  data: () => ({
    languages: [
      {
        code: 'us',
        icon: '🇺🇸',
        title: 'US'
      }
    ],
    selectedLanguage: null,

    moreLinks: [
      {
        disabled: true,
        title: 'More Yellow'
      },
      {
        title: 'ProVisual / 3D Mockups',
        url: 'https://provisual.app/'
      },
      {
        title: 'lumenor AI Images',
        url: 'https://lumenor.ai/'
      },
      {
        title: 'Object Mockups',
        url: 'https://yellowimages.com/all/objects'
      },
      {
        title: 'Creative Store',
        url: 'https://yellowimages.com/all/store'
      },
      {
        title: 'PNG Images',
        url: 'https://yellowimages.com/images-360'
      },
      {
        title: 'Creative Fonts',
        url: 'https://yellowimages.com/creative-fonts/all'
      },
      {
        title: 'Custom Job',
        url: 'https://yellowimages.com/if-you-cannot-find-the-image-needed'
      },
      {
        title: 'Membership',
        url: 'https://yellowimages.com/yellow-ticket'
      },
      {
        title: 'Become an author',
        url: 'https://yellowimages.com/become-an-author'
      }
    ],
    selectedMoreLink: null,

    links: [
      {
        title: 'Help',
        url: 'https://yellowimages.com/contact-us'
      },
      {
        title: 'Terms',
        url: 'https://yellowimages.com/terms'
      },
      {
        title: 'Privacy',
        url: 'https://yellowimages.com/privacy-policy'
      }
    ]
  }),
  mounted() {
    this.selectedLanguage = this.languages[0].code
    this.selectedMoreLink = this.moreLinks[0]
  }
}
</script>
