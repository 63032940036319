<template lang="pug">
component(
  :class="buttonClass"
  :is="type"
  :href="href"
  :to="to"
  v-bind="$attrs"
  @click="onClick"
  :exact-active-class="activeClass ? activeClass : 'is-active'"
)
  slot
</template>

<style lang="scss" scoped>
  .button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;

    border-radius: $border-radius-pill;
    font-weight: $font-weight-bold;

    font-size: $font-size-base;
    line-height: 1;
    letter-spacing: $negative-letter-spacing;
    text-align: center;
    padding: 10px 20px;
    height: 42px;

    transition: color .2s, background .2s;
    cursor: pointer;

    // States
    &_disabled {
      cursor: not-allowed;
    }

    // Figure types
    &_round {
      padding: 0;
      width: 42px;
    }

    // Colors
    &_default, &_default-yellow {
      background: $black;
      color: $white;

      &:hover {
        background: $blue;
        color: $white;
      }
    }

    &_default-yellow {
      &:hover {
        background: $yellow;
        color: $black;
      }
    }

    &_white {
      background: $white;
      color: $black;

      &:hover {
        background: $black;
        color: $white;
      }
    }

    &_gray {
      color: $black;
      background-color: $gray;

      &:hover {
        background-color: $gray;
        color: $blue;
      }
    }

    &_blue {
      background: $blue;
      color: $white;

      &:hover {
        background: lighten($blue, 5);
        color: $white;
      }
    }

    &_white-outline {
      border: 1px solid #E2E5E9;
      background: $white;

      &:hover {
        background: $gray;
        color: $blue;
      }
    }

    &_large {
      height: 56px;
      font-size: 16px;
    }

    &_mobile {
      @include media-breakpoint-down(md) {
        height: 56px;
        padding: 10px 24px;
        letter-spacing: 0;
      }
    }
  }
</style>

<script>
export default {
  name: 'BaseButton',
  inheritAttrs: false,
  props: {
    href: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'default',
      // You can replace with:
      // validator: (val) => ['primary', 'secondary', 'default'].includes(val)
      validator: (val) => ~['default', 'white', 'gray', 'blue', 'white-outline'].indexOf(val)
    },
    size: {
      type: String,
      default: 'medium',
      validator: (val) => ~['small', 'medium', 'large'].indexOf(val)
    },
    activeClass: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type() {
      if (this.href) {
        return 'a'
      } else if (this.to) {
        return 'router-link'
      } else {
        return 'button'
      }
    },
    buttonClass() {
      let classes = ['button']

      if (this.color) {
        classes.push('button_' + this.color)
      }

      if (this.size) {
        classes.push('button_' + this.size)
      }

      if (this.round) {
        classes.push('button_round')
      }

      if (this.disabled) {
        classes.push('button_disabled')
      }

      if (this.loading) {
        classes.push('button_loading')
      }

      return classes.join(' ')
    }
  },
  emits: [
    'click'
  ],
  methods: {
    // Send click events only when the button is active
    onClick(event) {
      if (this.disabled) {
        return
      }
      this.$emit('click', event)
    }
  }
}
</script>
