<template lang="pug">
.admin-panel(
  :class="{'admin-panel_column': column}"
)
  .admin-panel__item(
    v-if="!project.is_selected"
  )
    button.admin-panel__btn(
      @click="$store.dispatch('common/onChangeState', { projectId: project.id, newState: 2, isSelected: true })"
      v-tooltip.bottom="{theme: 'hint', content: 'Add to Selected 12', distance: 14}"
    )
      BaseIcon.admin-panel__icon(
        data="@icon/selected-project.svg"
        :width="iconSize"
        :height="iconSize"
        color="#4040FF"
        :class="{ 'admin-panel__icon_active': project.is_selected }"
      )
  .admin-panel__item(
    v-else
  )
    button.admin-panel__btn(
      @click="$store.dispatch('common/onChangeState', { projectId: project.id, newState: 2, isSelected: false, removeSelected: true })"
      v-tooltip.bottom="{theme: 'hint', content: 'Remove from Selected 12', distance: 14}"
    )
      BaseIcon.admin-panel__icon(
        data="@icon/selected-project.svg"
        :width="iconSize"
        :height="iconSize"
        color="#4040FF"
        :class="{ 'admin-panel__icon_active': project.is_selected }"
      )
  .admin-panel__item
    button.admin-panel__btn(
      @click="$store.dispatch('common/onChangeState', { projectId: project.id, newState: 2, isSelected: false })"
      v-tooltip.bottom="{theme: 'hint', content: 'Approve', distance: 14}"
    )
      BaseIcon.admin-panel__icon(
        data="@icon/verified.svg"
        :width="iconSize"
        :height="iconSize"
        color="#4040FF"
        :class="{ 'admin-panel__icon_active': project.state === 2 }"
      )
  .admin-panel__item
    button.admin-panel__btn(
      @click="this.$vfm.show('reject-project', {projectId: project.id})"
      v-tooltip.bottom="{theme: 'hint', content: 'Decline', distance: 14}"
    )
      BaseIcon.admin-panel__icon(
        data="@icon/not-verified.svg"
        :width="iconSize"
        :height="iconSize"
        color="#4040FF"
        :class="{ 'admin-panel__icon_active': project.state === 3 }"
      )
  .admin-panel__item
    button.admin-panel__btn(
      @click="rerenderPreview"
      v-tooltip.bottom="{theme: 'hint', content: 'Update cover', distance: 14}"
    )
      Refresh.admin-panel__icon(
        :size="iconSize"
        fill-color="#4040FF"
      )
</template>

<script>
import Refresh from 'vue-material-design-icons/Refresh.vue'
export default {
  name: 'ProjectModeration',

  components: {Refresh},

  props: {
    column: Boolean,
    project: Object
  },

  computed: {
    iconSize () {
      return this.column ? 22 : 28
    }
  },

  methods: {
    rerenderPreview () {
      this.$toast.show('Rerender preview start')
      this.$store.dispatch('activeProject/rerenderMainPreview').then(() => {
        this.$toast.success('Rerender preview done')
      })
    },
    selectProject () {
      this.$http.post(`/v1/manage/projects/${this.project.id}/action/contest_public`)
    },
    acceptProject () {
      this.$http.post(`/v1/manage/projects/${this.project.id}/action/contest_accept`)
    },
    rejectProject () {
      this.$http.post(`/v1/manage/projects/${this.project.id}/action/contest_reject`)
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-panel {
  padding: 7px 6px;
  background: $white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &__item {
    padding: 0 14px;
    border-right: 1px solid $gray;
    &:last-child {
      border: none;
    }
  }
  &__btn {
    padding: 0;
    background: inherit;
    border: none;
    display: block;
    cursor: pointer;
    :deep path {
      transition: 0.2s;
    }
    &:not(:hover){
      :deep path {
        fill: $gray-dark;
      }
    }
  }
  &__icon {
    display: block;
    &_active {
      :deep path {
        fill: #030303 !important;
      }
    }
  }

  &_column {
    flex-direction: column;
    padding: 0 7px;
  }
  &_column &__item {
    border-right: none;
    border-bottom: 1px solid $gray;
    padding: 8px 0;
  }
}
</style>
