<template>
  <span :aria-hidden="!title"
        :aria-label="title"
        class="material-design-icon"
        role="img"
        v-bind="$attrs"
        @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 28 28">
      <path d="M14 8.16667C7.55998 8.16667 2.33331 10.78 2.33331 14C2.33331 16.6133 5.76331 18.8183 10.5 19.565V23.3333L15.1666 18.6667L10.5 14V17.185C6.82498 16.5317 4.66665 14.9683 4.66665 14C4.66665 12.7633 8.21331 10.5 14 10.5C19.7866 10.5 23.3333 12.7633 23.3333 14C23.3333 14.8517 21.63 16.205 18.6666 16.9517V19.3433C22.785 18.445 25.6666 16.3917 25.6666 14C25.6666 10.78 20.44 8.16667 14 8.16667Z">
        <title v-if="title">{{ title }}</title>
      </path>
      </svg>
  </span>
</template>

<script>
export default {
  name: "Rotate360",
  props: {
    title: {
      type: String,
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>
