<template lang="pug">
viz-card(
  title="Like and vote"
)
  .like-block
    viz-like-btn(
      :like-count="likeCount"
      :liked="liked"
      @onLike="onLike"
    )
</template>

<script>
import VizCard from './vizCard'
import Heart from 'vue-material-design-icons/Heart.vue'
import VizLikeBtn from "@/components/player/vizLikeBtn";
export default {
  name: 'vizLikes',

  components: {VizLikeBtn, VizCard, Heart },

  computed: {
    activeProject () {
      return this.$store.state.activeProject.project
    },
    likeCount () {
      return this.activeProject.likes
    },
    liked () {
      return this.activeProject?.you?.liked
    }
  },

  methods: {
    onLike () {
      this.$store.dispatch('likes/onProjectLike', {id: this.activeProject.id, isLike: !this.liked})
    }
  }
}
</script>

<style lang="scss" scoped>
.like-block {
  border-radius: 8px;
  height: 72px;
  background: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
