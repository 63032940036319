<template lang="pug">
.player-footer
  .player-footer__history
    base-button(
      round
      :disabled="$store.state.projectHistory.currentSnapshotIndex === 0"
      @click="$store.dispatch('projectHistory/snapshotBack').then()"
      :style="{marginRight: '3px'}"
    )
      undo(
        :size="24"
        fill-color="#ffffff"
      )

    base-button(
      round
      :disabled="$store.state.projectHistory.currentSnapshotIndex === $store.state.projectHistory.snapshots.length - 1"
      @click="$store.dispatch('projectHistory/snapshotForward').then()"
    )
      redo(
        :size="24"
        fill-color="#ffffff"
      )

  .player-footer__btns.d-flex.justify-content-center
    base-button(
      @click="$store.dispatch('activeProject/saveProject')"
    ) Save as draft

    base-button(
      @click="onPublish"
    ) Publish
</template>

<script>
import BaseButton from "@/components/BaseButton";
import Undo from 'vue-material-design-icons/Undo.vue'
import Redo from 'vue-material-design-icons/Redo.vue'
export default {
  name: "vizMobileFooter",
  components: {BaseButton, Undo, Redo},
  methods: {
    onPublish () {
      if (this.$store.state.activeProject.hasUnsavedChanges) {
        this.$store.dispatch('activeProject/saveProject')
      }
      this.$vfm.show('publish-project')
    }
  }
}
</script>

<style lang="scss" scoped>
.player-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 16px 32px;
  &__history {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  .button_disabled {
    background: $white;
    &:deep {
      svg {
        fill: $gray-dark
      }
    }
  }
  &__btns {
    .button {
      width: 165px;
      font-size: 16px;
      height: 56px;
      &:first-child {
        margin-right: 12px;
      }
    }
  }
}
</style>
