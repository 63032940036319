<template lang="pug">
.animated-gradient(
  ref="gradientCard"
)
  slot
</template>

<script>
export default {
  name: "AnimatedGradient",
  props: {
    colors: {
      type: Array,
      required: true
    },
    gradientSpeed: {
      type: Number,
      default: 0.002
    }
  },
  data () {
    return {
      step: 0,
      colorIndices: [0, 1, 2, 3],
      intervalId: null
    }
  },
  methods: {
    updateGradient () {
      let c0_0 = this.colors[this.colorIndices[0]],
          c0_1 = this.colors[this.colorIndices[1]],
          c1_0 = this.colors[this.colorIndices[2]],
          c1_1 = this.colors[this.colorIndices[3]]

      let istep = 1 - this.step

      let r1 = Math.round(istep * c0_0[0] + this.step * c0_1[0]),
          g1 = Math.round(istep * c0_0[1] + this.step * c0_1[1]),
          b1 = Math.round(istep * c0_0[2] + this.step * c0_1[2]),
          color1 = "rgb("+r1+","+g1+","+b1+")"

      let r2 = Math.round(istep * c1_0[0] + this.step * c1_1[0]),
          g2 = Math.round(istep * c1_0[1] + this.step * c1_1[1]),
          b2 = Math.round(istep * c1_0[2] + this.step * c1_1[2]),
          color2 = "rgb("+r2+","+g2+","+b2+")"

      // this.$refs.gradientCard.css({
      //   background: "-webkit-gradient(linear, left top, right top, from("+color1+"), to("+color2+"))"}).css({
      //   background: "-moz-linear-gradient(left, "+color1+" 0%, "+color2+" 100%)"})

      this.$refs.gradientCard.style.background = "-webkit-gradient(linear, left top, right top, from("+color1+"), to("+color2+"))"

      this.step += this.gradientSpeed
      if ( this.step >= 1 ) {
        this.step %= 1
        this.colorIndices[0] = this.colorIndices[1]
        this.colorIndices[2] = this.colorIndices[3]

        this.colorIndices[1] = ( this.colorIndices[1] + Math.floor( 1 + Math.random() * (this.colors.length - 1))) % this.colors.length
        this.colorIndices[3] = ( this.colorIndices[3] + Math.floor( 1 + Math.random() * (this.colors.length - 1))) % this.colors.length
      }
    }
  },
  mounted() {
    this.intervalId = setInterval(this.updateGradient,10)
  },
  unmounted() {
    clearInterval(this.intervalId)
  }
}
</script>
