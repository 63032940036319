<template lang="pug">
div(
  @dragenter.prevent="toggleActive"
  @dragleave.prevent="toggleActive"
  @dragover.prevent
  @drop.prevent="toggleActive"
  class="dropzone"
)
  label.dropzone__label
    input.dropzoneFile(
      type='file'
    )
    .dropzone__container.dropzone__card(
      v-if="!isDone"
      :class="{ 'dropzone__container_active': active }"
    )
      BaseIcon.dropzone__image-icon(
        data="@icon/image-dropdown.svg"
        :original="true"
        width="18"
        height="18"
      )
      .dropzone__title Drop your image here, <span>or browse</span>
      .dropzone__subtitle Supports: JPG, PNG

    div(
      v-for="(material, index) in materials"
      :key="material.id"
      v-show="isDone"
    )
      div(
        v-show="index === activeMaterialIndex"
      )
        load-image(
          v-if="material.layers[1] && material.layers[1].settings.designMap"
          @onProgress="imageSetting"
          @onError="errorImageSetting"
        )
          template(
            #image
          )
            .dropzone__image-wrap.dropzone__card
              img.dropzone__image(
                :src="designLinks[index]"
                :alt="material.layers[1].name"
              )
              .dropzone__image-btn
                BaseIcon.dropzone__image-upload-icon(
                  data="@icon/upload.svg"
                  width="24"
                  height="24"
                )

          template(
            #preloader
          )
            .dropzone__loading.dropzone__card
              .dropzone__title Uploading
              .dropzone__subtitle {{ progressPercent }}
              progress-bar.dropzone__progress(
                :progress="loadingProgress"
              )
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import FileUploadOutline from 'vue-material-design-icons/FileUploadOutline.vue'
import Autorenew from 'vue-material-design-icons/Autorenew.vue'
import ProgressBar from "@/components/ProgressBar";
import LoadImage from "@/components/utils/LoadImage";
import isBase64 from "@/functions/isBase64";
import getImageLink from "@/functions/getImageLink";

export default {
  name: "DropZone",

  components: {LoadImage, ProgressBar, FileUploadOutline, Autorenew },

  data () {
    return {
      loadingProgress: 0
    }
  },

  computed: {
    materials () {
      return this.$store.state.activeProject.activeVariation.scene.materials
    },
    activeMaterialIndex () {
      return this.$store.state.activeProject.activeVariation.activeMaterialIndex
    },
    designLayer () {
      return this.$store.state.activeProject.activeMaterial.layers[1]
    },
    isDone () {
      return this.designLayer && this.designLayer.settings.designMap
    },
    progressPercent () {
      return this.loadingProgress + '%'
    },
    designLinks () {
      const designLinks = []
      _.forEach(this.materials, (material) => {
        if (material.layers[1] && material.layers[1].settings.designMap) {
          designLinks.push(this.getWebpDesign(material.layers[1].settings.designMap.url))
        } else {
          designLinks.push('')
        }
      })

      return designLinks
    }
  },

  methods: {
    imageSetting (percent) {
      this.loadingProgress = percent
    },
    errorImageSetting () {
      this.$toast.error('Image load fails')
    },
    getWebpDesign (url) {
      if (!isBase64(url)) {
        return getImageLink(url, '@2', 'webp')
      } else {
        return url
      }
    }
  },

  setup() {
    const store = useStore()
    const active = ref(false)

    const toggleActive = () => {
      active.value = !active.value
    };

    return { active, toggleActive }
  }
}
</script>

<style lang="scss" scoped>
.dropzone {
  width: 100%;
  height: 92px;
  position: relative;
  &_loading {
    pointer-events: none;
  }
  &__label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
  }
  input {
    display: none;
  }

  &__image-wrap:hover &__image {
    transform: scale(1.1);
  }
  &__image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s;
  }
  &__image-btn {
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 3;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.2s;
    &:hover {
      background: $black;
      color: $white;
    }
  }
  &__image-upload-icon {
    display: block;
    transition: 0.2s;
  }

  &__card {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateZ(0);
  }

  &__loading {
    border: 1px solid $gray;
    padding: 0 18px;
  }
  &__progress {
    margin-top: 10px;
  }

  &__container {
    cursor: pointer;
    border: 1px dashed #E2E5E9;
    transition: 0.3s;
    &:hover, &_active {
      background: rgba(64,64,255,0.10);
      border: 1px dashed $blue;
    }
  }
  &__image-icon {
    margin-bottom: 8px;
  }
  &__title {
    font-weight: bold;
    font-size: 10px;
    color: $black;
    letter-spacing: -0.33px;
    line-height: 1.6;
    margin-bottom: 4px;
    span {
      font-weight: normal;
    }
  }
  &__subtitle {
    font-weight: 500;
    font-size: 8px;
    color: rgba(0,0,0,0.30);
    letter-spacing: -0.3px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
</style>
