<template lang="pug">
section.slides
  .slides__item.examples(
    v-hammer:swipe.horizontal="swipeExample"
  )
    transition-group(
      tag="div"
      name="slide-fade"
    )
      .examples__bg(
        v-for="(example, index) in examples"
        :key="index"
        v-show="index === activeExample"
        :style="{ backgroundImage: example.backgroundImage }"
      )

    .slides__suptitle Test your might, fight!
    .slides__title.slides__title_big Create the best Duck. <br>Win an iPad Bro!

    transition-group(
      tag="div"
      name="slide-move"
    )
      img.examples__image(
        v-for="(example, index) in examples"
        v-show="index === activeExample"
        :key="index"
        :width="example.width"
        :height="example.height"
        :src="example.src"
        :srcset="example.srcset"
      )

    .d-flex.align-items-center.examples__dots
      .examples__dot(
        v-for="(example, index) in examples"
        :key="index"
        @click="activeExample = index"
        :class="{ 'is-active': index === activeExample }"
      )
        .examples__dot-bg(
          :style="{ backgroundColor: example.color }"
        )

    BaseButton.slides__button.d-md-inline-flex.d-none(
      @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
    ) Create Now

  .slides__item(
    v-for="(slide, index) in slides.slice(0, 5)"
    :key="index"
    :style="{ backgroundImage: slide.backgroundImage }"
  )
    .slides__title(
      v-html="slide.title"
    )
    img.slides__image(
      :class="'slides__image_' + (index + 2)"
      :width="slide.width"
      :height="slide.height"
      :src="slide.src"
      :srcset="slide.srcset"
    )
</template>

<style lang="scss" scoped>
  .slides {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin: 0 0 70px 0;
    height: 514px;

    @include media-breakpoint-down(xxl) {
      height: 460px;
      margin: 0 0 60px 0;
    }

    @include media-breakpoint-down(xl) {
      grid-template-rows: repeat(3, 1fr);
      height: 720px;
    }

    @include media-breakpoint-down(lg) {
      height: 660px;
    }

    @include media-breakpoint-down(md) {
      display: block;
      height: auto;
    }

    &__item {
      -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
      border-radius: 26px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      overflow: hidden;

      background-color: $gray;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;

      font-weight: 700;
      padding: 25px 24px;
      line-height: 1.4;

      &:nth-child(1), &:nth-child(2) {
        padding: 32px 32px;
        font-size: 24px;
        line-height: 1.2;
      }

      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 6;
      }
      &:nth-child(2) {
        grid-area: 1 / 6 / 3 / 9;
      }
      &:nth-child(3) {
        grid-area: 1 / 9 / 2 / 11;
        color: $white;
      }
      &:nth-child(4) {
        grid-area: 1 / 11 / 2 / 13;
      }
      &:nth-child(5) {
        grid-area: 2 / 9 / 3 / 11;
      }
      &:nth-child(6) {
        grid-area: 2 / 11 / 3 / 13;
        color: $white;
      }

      @include media-breakpoint-down(xl) {
        padding: 22px 20px;

        &:nth-child(1), &:nth-child(2) {
          padding: 26px 26px;
        }

        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 8;
        }
        &:nth-child(2) {
          grid-area: 1 / 8 / 3 / 13;
        }
        &:nth-child(3) {
          grid-area: 3 / 1 / 4 / 4;
        }
        &:nth-child(4) {
          grid-area: 3 / 4 / 4 / 7;
        }
        &:nth-child(5) {
          grid-area: 3 / 7 / 4 / 10;
        }
        &:nth-child(6) {
          grid-area: 3 / 10 / 4 / 13;
        }
      }

      @include media-breakpoint-down(md) {
        display: none;

        &:nth-child(1) {
          display: flex;
          height: 484px;

          padding: 24px 24px 36px;
          font-size: 18px;
          line-height: 1.3;
        }
      }
    }
    &__title, &__suptitle {
      position: relative;
      z-index: 5;

      width: 100%;
      white-space: nowrap;
    }
    &__title {
      margin: 0 0 18.3% 0;

      @include media-breakpoint-down(xxl) {
        margin: 0 0 16.2% 0;
      }

      @media (max-width: 998px) {
        margin: 0 0 10% 0;
      }
    }

    &__suptitle {
      font-size: 12px;
      letter-spacing: -0.1px;
      color: $blue;
      margin: 0 0 16px 0;

      @include media-breakpoint-down(md) {
        letter-spacing: -0.15px;
        line-height: 1.4;
      }
    }

    &__button {
      position: relative;
      z-index: 15;
      margin: auto 0 0;
      min-width: 150px;
    }

    &__image {
      display: block;
      position: absolute;
      z-index: 1;
      height: auto;
      bottom: 0;
      left: 0;

      &_2, &_3, &_4 {
        width: 100%;
        height: auto;
      }
      &_5 {
        left: 12%;
        max-width: 79%;
      }
      &_6 {
        left: 12%;
        max-width: 80%;
      }

      @include media-breakpoint-down(xl) {
        bottom: -10%;
      }
      @include media-breakpoint-down(lg) {
        bottom: 0;
      }
    }

  }

  .examples {
    transition: background .2s;
    touch-action: pan-y!important;
    overscroll-behavior: none;

    &__bg {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      will-change: opacity;
      transition: opacity .5s;
    }

    &__image {
      display: block;
      position: absolute;
      z-index: 10;
      right: 32px;
      bottom: 32px;

      @include media-breakpoint-down(xxl) {
        right: 4%;
        bottom: 6%;
        max-width: 300px;
        height: auto;
      }
      @media (max-width: 998px) {
        right: 3%;
        bottom: 7%;
        max-width: 51%;
      }

      @include media-breakpoint-down(md) {
        top: 51%;
        left: 50%;
        bottom: auto;
        transform: translate(-50%, -50%);
        max-width: 41%;
      }

      @include media-breakpoint-down(sm) {
        top: 53%;
        max-width: 226px;
      }

    }

    &__dots {
      margin: auto 0 auto -6px;
      position: relative;
      z-index: 15;

      @media (max-width: 998px) {
        margin: 0 0 0 -6px;
      }
      @include media-breakpoint-down(md) {
        margin: auto auto 0;
        width: 100%;
        justify-content: center;
      }
    }

    &__dot {
      cursor: pointer;
      border-radius: 100%;
      width: 40px;
      height: 40px;

      margin: 0 2px;
      padding: 3px;
      border: 1px solid transparent;
      transition: border-color .25s;

      &.is-active {
        border-color: $blue;
      }

      &-bg {
        border-radius: 100%;
        box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.15);
        width: 100%;
        height: 100%;
      }
    }
  }

  .slide-fade, .slide-move {
    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }

  .slide-fade {
    &-enter-active,
    &-leave-active {
      will-change: opacity;
      transition: all .5s;
    }
  }

  .slide-move {
    &-enter-active,
    &-leave-active {
      will-change: opacity, transform;
      transition: opacity .5s ease, transform .5s ease;
    }

    &-enter-from {
      transform: translate(80px, 0);
      @include media-breakpoint-down(md) {
        transform: translate(calc(-50% + 80px), -50%);
      }
    }
    &-leave-to {
      transform: translate(-80px, 0);
      @include media-breakpoint-down(md) {
        transform: translate(calc(-50% - 80px), -50%);
      }
    }
  }

</style>

<script>
import BaseButton from '@/components/BaseButton.vue'

export default {
  name: 'HomeSlides',
  inject: ["mq"],
  components: {
    BaseButton
  },
  data() {
    return {
      activeExample: 0,
      examples: [
        {
          width: 340,
          height: 350,
          color: '#FF43E3',
          backgroundImage: 'linear-gradient(224deg, #FFE5F6 0%, #FFF6DC 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_01.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_01@2x.png 2x'
        },
        {
          width: 326,
          height: 362,
          color: '#B63AE9',
          backgroundImage: 'linear-gradient(225deg, #C6DFF9 0%, rgba(241,190,255,0.69) 49%, #FFF3D9 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_02.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_02@2x.png 2x'
        },
        {
          width: 322,
          height: 362,
          color: '#FF7400',
          backgroundImage: 'linear-gradient(225deg, rgba(83,55,191,0.48) 0%, rgba(64,187,255,0.30) 49%, rgba(255,200,239,0.71) 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_03.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_03@2x.png 2x'
        },
        {
          width: 301,
          height: 362,
          color: '#FFFB1F',
          backgroundImage: 'linear-gradient(223deg, #FF6B5B 0%, #FFF6DC 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_04.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_04@2x.png 2x',
        },
        {
          width: 313,
          height: 362,
          color: '#0BCC3A',
          backgroundImage: 'linear-gradient(220deg, rgba(150,230,161,0.82) 0%, #D4FC79 60%, #FFF5DD 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_05.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/01_05@2x.png 2x'
        }
      ],
      slides: [
        {
          title: 'Play by the rules — <br>but be furious!',
          backgroundImage: 'linear-gradient(-43deg, #FF6B5B 0%, #FFF6DC 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/02.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/02@2x.png 2x',
          width: 382,
          height: 410
        },
        {
          title: 'Ready to get <br>creative?',
          backgroundImage: 'linear-gradient(180deg, #FF6E5B 0%, #FFC8EF 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/03.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/03@2x.png 2x',
          width: 248,
          height: 179
        },
        {
          title: 'Gotcha! All right, <br>Let\'s go fight!',
          backgroundImage: 'radial-gradient(circle at 56% 54%, #FFE6F5 0%, #FFE3DD 0%, #FFD371 55%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/04.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/04@2x.png 2x',
          width: 248,
          height: 209
        },
        {
          title: 'Make the crowd <br>go wild!',
          backgroundImage: 'linear-gradient(237deg, #5337BF 0%, rgba(64,187,255,0.86) 63%, rgba(255,200,239,0.71) 100%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/05.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/05@2x.png 2x',
          width: 191,
          height: 230
        },
        {
          title: 'Prizes are <br>waiting for you',
          backgroundImage: 'radial-gradient(circle at -27% 1.3e+02%, #FAF6EA 0%, #FFC6EF 11%, #462815 70%, #000000 161%)',
          src: 'https://cdn.yellowimages.com/3dvisualizer/slides/06.png',
          srcset: 'https://cdn.yellowimages.com/3dvisualizer/slides/06@2x.png 2x',
          width: 203,
          height: 220
        }
      ]
    }
  },
  props: {
    item: Object
  },
  methods: {
    swipeExample(e) {
      let newExample = 0,
          examplesCount = this.examples.length

      if (e.direction == 2) {
        newExample = this.activeExample + 1 < examplesCount ? this.activeExample + 1 : 0
      } else if (e.direction == 4) {
        newExample = this.activeExample - 1 < 0 ? examplesCount - 1 : this.activeExample - 1
      }

      this.activeExample = newExample
    }
  }
}
</script>
