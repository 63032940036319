<template lang="pug">
header#header.header.d-flex.align-items-stretch
  .container.header__container.d-flex.align-items-center
    a.header__logo(href="https://yellowimages.com/")
      BaseIcon(
        data="@icon/logo.svg"
        width="42"
        height="42"
        color="currentColor _ _"
      )
      span.d-lg-inline.d-none GetYellow

    .header__center.d-md-flex.d-none.align-items-center
      TheHeaderDropdown.header__select
        template(v-slot:activator)
          .d-flex.align-items-center
            | Browse
            BaseIcon(
              data="@icon/arrow-down.svg"
              width="18"
              height="18"
            )

        template(v-slot:default)
          TheHeaderBrowse(
            :markets="markets"
          )

    .header__right.d-flex.align-items-center
      TheHeaderDropdown.header__user(
        v-if="isLoggedIn"
      )
        template(v-slot:activator)
          img(
            :src="user.avatar"
            :title="user.full_name"
            :alt="user.full_name"
          )

        template(v-slot:default)
          TheHeaderUser(
            :user="user"
          )

      BaseButton.header__login(
        v-else
        to="/login"
        color="default-yellow"
      ) Join Now

      TheHeaderDropdown.header__markets
        template(v-slot:activator)
          .header__dot
          .header__dot
          .header__dot
          .header__dot
          .header__dot
          .header__dot
          .header__dot
          .header__dot
          .header__dot

        template(v-slot:default)
          TheHeaderMarkets(
            :markets="markets"
          )
</template>

<script>
import BaseButton from '@/components/BaseButton.vue'
import TheHeaderDropdown from '@/components/TheHeaderDropdown.vue'
import TheHeaderBrowse from '@/components/TheHeaderBrowse.vue'
import TheHeaderMarkets from '@/components/TheHeaderMarkets.vue'
import TheHeaderUser from '@/components/TheHeaderUser.vue'

export default {
  name: 'TheHeader',

  components: {
    BaseButton,
    TheHeaderDropdown,
    TheHeaderBrowse,
    TheHeaderMarkets,
    TheHeaderUser
  },

  props: {
    isLoggedIn: Boolean
  },
  computed: {
    user: function(){
      return this.$store.getters['auth/user']
    }
  },

  data: () => ({
    markets: [
      {
        key: '3d',
        titleHtml: 'ProVisual <br> 3D Mockups',
        title: 'ProVisual / 3D Mockups',
        url: 'https://provisual.app/'
      },
      {
        key: 'om',
        titleHtml: 'Object <br>Mockups',
        title: 'Object Mockups',
        url: 'https://yellowimages.com/all/objects'
      },
      {
        key: 'cs',
        titleHtml: 'Creative <br>Store',
        title: 'Creative Store',
        url: 'https://yellowimages.com/all/store'
      },
      {
        key: 'png',
        titleHtml: 'PNG <br>Images',
        title: 'PNG Images',
        url: 'https://yellowimages.com/images-360'
      },
      {
        key: 'cf',
        titleHtml: 'Handcrafted <br>fonts',
        title: 'Handcrafted fonts',
        url: 'https://yellowimages.com/creative-fonts/all'
      },
      {
        key: 'ai',
        titleHtml: 'lumenor <br>AI Images',
        title: 'lumenor AI Images',
        url: 'https://lumenor.ai/'
      },
      {
        key: 'jobs',
        titleHtml: 'Custom Job <br>service',
        title: 'Custom Job & Wishlist',
        url: 'https://yellowimages.com/if-you-cannot-find-the-image-needed'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
  .header {
    flex: 0 0 auto;
    background: $white;
    margin: 0;
    height: 63px;
    border-bottom: 1px solid #e2e5e9;

    font-size: 14px;
    line-height: 1.2;
    white-space: nowrap;
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 1000;

    @include media-breakpoint-down(md) {
      border: 0;
      height: 58px;
    }

    &__container {
      @media only screen and (min-width: 1769px) {
        max-width: 100%;
        padding: 0 60px;
      }
    }

    // Main logo with sitename
    &__logo {
      align-self: stretch;
      display: flex;
      align-items: center;

      font-weight: 700;
      font-size: 19px;
      letter-spacing: -1.05px;
      color: $black;

      &:hover {
        color: $black;
      }

      span {
        margin: 0 0 -2px;
      }

      .svg-icon {
        color: $black;
        fill: $yellow;
        margin: 0 15px 0 0;

        &:deep {
          path {
            transition: fill .3s ease, color .3s ease;
          }
        }

        &:hover {
          fill: $black;
          color: $yellow;
        }

        @include media-breakpoint-down(lg) {
          margin: 0;
        }
      }
    }

    // Center
    &__center {
      white-space: normal;
      position: relative;
      margin: 0 0 0 122px;

      @include media-breakpoint-down(xl) {
        margin: 0 0 0 100px;
      }
      @include media-breakpoint-down(lg) {
        margin: 0 0 0 50px;
      }
    }

    // Right part
    &__right {
      white-space: normal;
      margin: 0 0 0 auto;
    }

    &__link {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      position: relative;

      .svg-icon {
        margin: -1px 6px 0 0;
      }
    }

    &__badge {
      position: absolute;
      top: -6px;
      left: 100%;
      background: $yellow;
      color: $black;

      font-size: 8px;
      padding: 2px 5px;
      border-radius: 999px;
    }

    &__select {
      margin: 0 60px 0 0;
      transition: color .2s;

      &:last-child {
        margin: 0;
      }

      &:deep {
        .dropdown__content {
          width: 584px;
        }

        .dropdown__activator {
          font-weight: $font-weight-bold;
          padding: 5px 0;
          transition: color .2s;
          letter-spacing: -.4px;
          &:hover {
            color: $blue;
          }

          &.is-opened .svg-icon {
            transform: rotate(180deg);
          }
        }
        .svg-icon {
          margin: 0 0 0 8px;
          color: $black;
        }

        .dropdown__content {
          right: auto;
          left: 0;
        }
      }
    }


    // Markets dropdown & burger
    &__markets {
      margin: 0 0 0 12px;

      &:deep {
        .dropdown__content {
          width: 214px;
        }
        .dropdown__activator {
          position: relative;
          height: 42px;
          width: 42px;
          cursor: pointer;
          white-space: normal;
          font-size: 0;
          padding: 8px 4px 4px 8px;
          border-radius: 6px;
          background: $yellow;
          transition: background .2s ease;

          &:hover:not(.is-opened  ) {
            .header__dot:nth-child(odd):not(:nth-child(5)) {
              background: $yellow;
            }
          }

          &.is-opened {
            background: $black;

            .header__dot:nth-child(odd) {
              background: $white;
            }
          }
        }
      }
    }

    &__dot {
      display: inline-block;
      vertical-align: top;

      width: 7px;
      height: 7px;
      margin: 0 3px 3px 0;
      border-radius: 100%;

      transition: background .2s ease;
      background: $black;
    }


    // &__login {
    //   border: 1px solid #E2E5E9;

    //   &:hover {
    //     background: $gray;
    //     color: $blue;
    //   }
    // }

    &__user {
      &:deep {
        .dropdown__content {
          width: 190px;
        }
        .dropdown__activator {
          width: 44px;
          height: 44px;
          border-radius: 100%;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
</style>
