import {createToaster} from '@meforma/vue-toaster'
import {$viz3d} from '@/plugins/lib3d'

const toaster = createToaster({
  position: 'top',
  duration: 3000,
  useDefaultCss: false
})

//TODO: Вынести логику скриншотов в отдельный стор

const state = () => ({
  project: {},
  activeVariation: {},
  activeMaterial: {},
  activeLayer: {},
  currentCameraPosition: null,
  isTextureLoading: false,
  hasUnsavedChanges: false,
  editAccess: false,
  saveOnProgress: false,
  screenshotOnProgress: false,
  additionalSettingTab: null,
  activeMobileTab: '',
  isHintsOpen: false,
  isSavingFile: [
    new Promise((resolve, reject) => {
      resolve()
    })
  ],
  coverLoading: {
    isCoverLoading: false,
    coverUrlDone: true
  },
  isProjectLoading: false
})

const getters = {
  getImageById: (state) => (imageId) => {
    const imageItem = _.find(state.activeVariation.scene.images, ['id', imageId])
    return imageItem ? imageItem.url : ''
  },
  getActiveTexturesId (state) {
    const activeTextures = []
    _.forEach(state.activeVariation.scene.materials, (material) => {
      _.forEach(material.layers, (layer) => {
        activeTextures.push(layer.settings.textureId)
      })
    })

    return activeTextures
  }
}

const actions = {

  setProjectMaterialIcons ({ state, commit, dispatch }) {
    state.activeVariation.scene.materials.forEach((material, materialIndex) => {
      material.layers.forEach((layer, layerIndex) => {
        dispatch('textures/getTextureIconById', layer.settings.textureId, { root: true }).then(previewUrl => {
          commit('setLayerIcon', {materialIndex, layerIndex, previewUrl})
        })
      })
    })
  },

  async deleteProject ({ state }, projectId) {
    await this.$http.delete(`/v1/projects/${projectId}`)
      .then(() => {
        toaster.success('Duck removed successfully')
      })
  },

  /**
   * Сохранение активного проекта
   * @param commit
   * @param state
   * @param dispatch
   * @param {Object} payload
   * @param {String} payload.successMessage - ответ при сохранении проекта
   * @param {Boolean} payload.disableMessage - отключение сообщения о сохранении
   */
  async saveProject ({ commit, state, dispatch }, payload) {
    if (!state.saveOnProgress) {
      let hasError = false
      state.saveOnProgress = true
      await dispatch('rerenderMainPreview')
      await Promise.all(state.isSavingFile)

      commit('clearSavedFile')

      // TODO: Сделать рефакторинг клонирования проекта без поля sceneInstance
      const projectDataCopy = {
        variations: []
      }
      state.project.data.variations.forEach(variation => {
        const scene = _.cloneDeep(variation.scene)

        _.forEach(scene.materials, (material) => {
          _.forEach(material.layers, (layer) => {
            const designMap = layer.settings.designMap
            if (designMap && designMap.url) {
              if (designMap.designUrl) {
                designMap.url = designMap.designUrl

                delete designMap.designUrl
                scene.images.push(designMap)
              } else {
                setTimeout(() => {
                  if (designMap.designUrl) {
                    designMap.url = designMap.designUrl

                    delete designMap.designUrl
                    scene.images.push(designMap)
                  } else {
                    hasError = true
                  }
                }, 4000)
              }
            }

          })
        })

        projectDataCopy.variations.push({
          products: variation.products,
          scene,
          settings: variation.settings
        })
      })

      const updatedProject = JSON.stringify({
        id: state.project.id,
        name: state.project.name,
        state: state.project.state,
        categories: state.project.categories,
        data: projectDataCopy
      })

      if (hasError) {
        state.saveOnProgress = false
        toaster.error('Error. Something went wrong')
      }

      return await this.$http.put(`/v1/projects/${state.project.id}`, updatedProject)
        .then((result) => {
          if (!payload?.disableMessage) {
            toaster.success(payload?.successMessage || 'Changes saved')
          }
          commit('clearUnsavedChanges')
          commit('updateEditedDate', result.data.body.updated_at)
          state.saveOnProgress = false
        })
        .catch((err) => {
          state.saveOnProgress = false
          toaster.error('Error. Something went wrong')
        })
    }
  },

  /**
   * Загрузка изображения дизайна в текущий или новый слой
   * @param state
   * @param commit
   * @param dispatch
   * @param {Object} payload
   * @param {} payload.fileData
   * @param {} payload.response
   * @param {Boolean} payload.createNewLayer если true, то создаем новый слой, иначе добавляем изображение в активный слой
   */
  addDesignInLayer ({ state, commit, dispatch }, payload) {
    setTimeout(() => {
      if (payload.response.ok && payload.response?.body && payload.response.body.path) {
        const image = {
          type: 'design',
          id: payload.response.body.file_id,
          url: payload.response.body.path
        }

        if (payload.createNewLayer) {
          commit('addLayer', {
            layerIndex: 0,
            image: _.clone(image),
            fileData: payload.fileData
          })
        } else {
          commit('changeActiveLayer', 1)

          commit('adjustmentChange', {
            method: 'updateDesignImage',
            args: [state.activeMaterial.id, state.activeMaterial.activeLayerIndex, _.clone(image), payload.fileData]
          })

          commit('layerSettingsLocalChange', {
            settingsPath: 'designMap',
            value: _.clone(image),
            updateObject: true
          })
        }

        dispatch('projectHistory/addSnapshot', state.activeVariation.scene, { root: true })
        commit('addedUnsavedChanges')
      }
    }, 250)
  },

  applyDesign ({ state, commit, dispatch }, designFile) {
    const designLayer = state.activeMaterial.layers[1]

    const image = {
      type: 'design',
      id: 'd-' + (new Date()).getTime(),
      url: designFile
    }

    if (designLayer) {
      // Обновление дизайна слоя
      commit('changeActiveLayer', 1)

      commit('layerSettingsLocalChange', {
        settingsPath: 'designMap',
        value: image,
        updateObject: true
      })

      commit('adjustmentChange', {
        method: 'updateDesignImage',
        args: [state.activeMaterial.id, state.activeMaterial.activeLayerIndex, image]
      })
    } else {
      // Добавление слоя с дизайном
      commit('addLayer', {
        layerIndex: 0,
        image
      })
    }

    dispatch('projectHistory/addSnapshot', state.activeVariation.scene, { root: true })
    commit('addedUnsavedChanges')
  },

  publishDuck ({state, commit, dispatch}) {
    commit('changeActiveProjectState', { projectId: state.project.id, newState: 1, isSelected: false})
    dispatch('saveProject', { successMessage: 'Publishing was successful' })
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  },

  /**
   * Получение скриншота по текущей позиции
   * @param state
   * @param dispatch
   * @param screenshotSettings
   * @returns {Promise<void>}
   */
  async getCurrentScreenshot ({state, dispatch}, screenshotSettings) {
    if (!state.screenshotOnProgress) {
      state.screenshotOnProgress = true
      const screenshotResult = await state.activeVariation.sceneInstance.api.getScreenshot(_.cloneDeep(screenshotSettings))

      dispatch('uploadScreenshot', {screenshot: screenshotResult, type: 'screen'}).then(res => {
        const link = document.createElement('a');
        link.href = res.data.body.path;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        state.screenshotOnProgress = false
      })
    }

  },


  /**
   * Функция запуская ререндер главного превью и устанавливает новое изображение
   * @param state
   * @param commit
   * @param dispatch
   * @returns {Promise<void>}
   */
  async rerenderMainPreview ({state, commit, dispatch}) {
    const mainPreview = state.activeVariation.scene.previews[0]
    // const cameraPosition = _.clone(state.currentCameraPosition)
    state.activeVariation.sceneInstance.api.setCameraPosition(mainPreview.camera.position)

    const screenshotResult = await state.activeVariation.sceneInstance.api.getScreenshot({
      format: 'JPG',
      transparent: false,
      size: {
        width: mainPreview.image.width,
        height: mainPreview.image.height
      }
    })

    // state.activeVariation.sceneInstance.api.setCameraPosition(cameraPosition)

    await dispatch('uploadScreenshot', {screenshot: screenshotResult, type: 'cover'}).then(res => {
      const previewImage = res.data.body.path + '?v=' + (new Date()).getTime()

      const previewData = {
        isDesignChanged: false,
        image: {
          width: mainPreview.image.width,
          height: mainPreview.image.height,
          type: mainPreview.image.type,
          url: previewImage
        }
      }

      commit('changeProjectCover', previewImage)
      commit('changePreviewImage', {previewData, previewIndex: 0})
      commit('urlCoverDone')
    })
  },

  /**
   * Передача скриншота на сервер
   * @param state
   * @param data
   * @param data.screenshot данные о картинке
   * @param {String} data.type тип скриншота (cover|screen)
   * @returns {Promise<void>}
   */
  async uploadScreenshot ({state}, data) {
    const formData = new FormData()
    formData.append('file_data', data.screenshot)
    formData.append('file_type', data.type)
    formData.append('project_id', state.project.id)

    return await this.$http.post('/v1/uploader/point', formData)
  }
}

const mutations = {
  startProjectLoading (state) {
    state.isProjectLoading = true
  },
  stopProjectLoading (state) {
    state.isProjectLoading = false
  },
  /**
   * Редактирование открытого проекта и отправка этого проекта в актив
   * @param state
   * @param {Object} project объект с данными о проекте
   */
  onProjectOpen (state, project) {
    project.activeVariationIndex = 0

    // Установка активных материалов
    _.forEach(project.data.variations, function (variation) {
      let activeMaterialIndex = null

      _.forEach(variation.scene.materials, function (material, materialIndex) {
        if (!activeMaterialIndex && material?.active && material.active) {
          activeMaterialIndex = materialIndex
          variation.activeMaterialIndex = activeMaterialIndex
        } else {
          material.active = false
        }

        // Установка превью текстур
        _.forEach(material.layers, function (layer) {
          if (layer.settings.texture) {
            const previewTexture = _.find(variation.scene.images, ['id', layer.settings.texture.preview])

            if (!previewTexture) {
              layer.settings.texture.previewUrl = null
            } else {
              layer.settings.texture.previewUrl = previewTexture.url
            }
          }
        })
      })
      if (!activeMaterialIndex) {
        activeMaterialIndex = 0
        variation.activeMaterialIndex = activeMaterialIndex
        variation.scene.materials[0].active = true
      }
    })

    state.project = project
    state.activeVariation = state.project?.data?.variations[state.project.activeVariationIndex]

    this.commit('activeProject/changeActiveMaterial', state.activeVariation.activeMaterialIndex)
    this.dispatch('projectHistory/addSnapshot', state.activeVariation.scene, { root: true })

    setTimeout(function () {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  },

  /**
   * Очистка стора
   * @param state
   */
  clearActiveProject (state) {
    state.project = {}
    state.activeVariation = {}
    state.activeMaterial = {}
    state.activeLayer = {}
    state.currentCameraPosition = null
    state.hasUnsavedChanges = false
    state.editeditAccess = false
  },

  /**
   * Встраивание 3D визуализации в проект
   * @param state
   * @param textureList
   */
  embed3dVisualization (state, textureList) {
    const scene = document.getElementById('project-scene')

    scene.innerHTML = ''

    delete state.activeVariation.sceneInstance
    const dataScene = _.cloneDeep(state.activeVariation)

    setTimeout(() => {
      state.activeVariation.sceneInstance = new $viz3d({
        container: scene,
        dataScene,
        textureList: _.cloneDeep(textureList)
      }, () => {
        // Project scene is fully loaded

        state.activeVariation.settings.activePreviewIndex = 0
        if (state.activeVariation.scene.previews) {
          state.currentCameraPosition = state.activeVariation.scene.previews[0].camera.position
          state.activeVariation.sceneInstance.api.setCameraPosition(state.currentCameraPosition)
        }

        // TODO: fix wrong canvas height rendering
        window.dispatchEvent(new Event('resize'))

        this.commit('activeProject/stopProjectLoading')
      })
    }, 10)
  },

  clearSceneInstance (state) {
    state.activeVariation.sceneInstance.api.closeVis3D(() => {
      const scene = document.getElementById('project-scene')
      scene.innerHTML = ''
      delete state.activeVariation.sceneInstance
    })
  },

  setLayerIcon (state, { materialIndex, layerIndex, previewUrl }) {
    const texture = state.activeVariation.scene.materials[materialIndex].layers[layerIndex].settings.texture
    if (texture) texture.previewUrl = previewUrl
  },

  startRenderCover (state) {
    state.coverLoading.isCoverLoading = true
    state.coverLoading.coverUrlDone = false
  },

  urlCoverDone (state) {
    state.coverLoading.coverUrlDone = true
  },

  rerenderCoverDone (state) {
    state.coverLoading.isCoverLoading = false
  },

  /**
   * При изменении проекта запоминаем, что он был изменен для последующего вывода сообщения
   * о имеющихся несохраненных настройках
   * @param state
   */
  addedUnsavedChanges (state) {
    state.hasUnsavedChanges = true
  },

  /**
   * После сохранения убираем пометку несохраненного проекта
   * @param state
   */
  clearUnsavedChanges (state) {
    state.hasUnsavedChanges = false
  },

  updateEditedDate (state, date) {
    state.project.updated_at = date
  },

  switchHints (state) {
    state.isHintsOpen = !state.isHintsOpen
  },

  hideHints (state) {
    state.isHintsOpen = false
  },

  /**
   * Установка текущей позиции камеры
   * @param state
   */
  setCameraPosition (state) {
    state.currentCameraPosition = state.activeVariation.sceneInstance.api.getCameraPosition()
  },

  /**
   * Обновление позиции камеры активного превью
   * @param state
   */
  resetCameraPosition (state) {
    const activePreviewIndex = state.activeVariation.settings.activePreviewIndex
    state.currentCameraPosition = state.activeVariation.scene.previews[activePreviewIndex].camera.position
    state.activeVariation.sceneInstance.api.setCameraPosition(state.currentCameraPosition)
  },

  /**
   * Установка активного варианта
   * @param state
   * @param variationIndex
   */
  changeProjectVariation (state, variationIndex) {
    state.project.activeVariationIndex = variationIndex
    state.activeVariation = state.project.data.variations[variationIndex]
    this.commit('activeProject/embed3dVisualization')
  },

  /**
   * Установка нового активного материала
   * @param state
   * @param {number | string} materialIndex индекс нового активного материала
   */
  changeActiveMaterial (state, materialIndex) {
    state.activeVariation.activeMaterialIndex = materialIndex
    state.activeMaterial = state.activeVariation.scene.materials[materialIndex]
    this.commit('activeProject/changeActiveLayer', 0)
  },

  /**
   * Установка нового активного слоя
   * @param state
   * @param {number | string} layerIndex
   */
  changeActiveLayer (state, layerIndex) {
    state.activeMaterial.activeLayerIndex = layerIndex
    state.activeLayer = state.activeMaterial?.layers[layerIndex]
  },

  /**
   * Установка активного превью
   * @param state
   * @param previewIndex
   */
  changeVariationPreview (state, previewIndex) {
    state.activeVariation.settings.activePreviewIndex = previewIndex
  },

  changeProjectName (state, name) {
    state.project.name = name
  },


  addDesignUrl (state, payload) {
    if (payload.response.ok && payload.response?.body && payload.response.body.path) {
      const layer = state.activeVariation.scene.materials[payload.materialIndex].layers[payload.layerIndex]

      if (layer) {
        if (!layer.settings.designMap.uploadTime || (payload.uploadTime > layer.settings.designMap.uploadTime)) {
          layer.settings.designMap.designUrl = payload.response.body.path
          layer.settings.designMap.uploadTime = payload.uploadTime
        }
      }
    }
  },

  /**
   * Добавление/дублирование слоя
   * @param state
   * @param {Object} payload
   * @param {Number} payload.layerIndex индекс слоя, который будет скопирован
   * @param payload.image изображение для нового слоя
   */
  addLayer (state, payload) {
    const newLayer = _.cloneDeep(state.activeMaterial.layers[payload.layerIndex])
    newLayer.id = 'idl-' + (new Date()).getTime()

    state.activeMaterial.layers.push(newLayer)
    this.commit('activeProject/changeActiveLayer', state.activeMaterial.layers.length - 1)

    this.commit('activeProject/layerSettingsLocalChange', {
      settingsPath: 'designMap',
      value: payload.image,
      updateObject: true
    })
    this.commit('activeProject/layerSettingsLocalChange', {
      settingsPath: 'designMapOpacity',
      value: 1
    })

    this.commit('activeProject/adjustmentChange', {
      method: 'addLayer',
      args: [state.activeMaterial.id, _.cloneDeep(newLayer)]
    })
  },

  /**
   * Удаление слоя
   * @param state
   * @param {Number | String} layerIndex
   */
  removeLayer (state, layerIndex) {
    state.activeMaterial.layers.splice(layerIndex, 1)
    this.commit('activeProject/changeActiveLayer', layerIndex - 1)
    this.commit('activeProject/adjustmentChange', {
      method: 'removeLayer',
      args: [state.activeMaterial.id, layerIndex]
    })
  },

  /**
   * Добавление превью
   * @param state
   * @param {Object} payload
   * @param {Object} payload.cameraPosition позиция камеры по x, y, z
   * @param {String} payload.image Рендер превью в формате base64
   * @param {Object} payload.sizes
   * @param {Number} payload.sizes.width Ширина превью
   * @param {Number} payload.sizes.height Высота превью
   */
  addPreview (state, payload) {
    const preview = {
      id: 'preview-' + (new Date()).getTime(),
      camera: {
        position: payload.cameraPosition,
        rotate: 360,
        target: {
          position: { x: 1, y: 1, z: 1 },
          rotate: 360
        }
      },
      images: {
        url: payload.image,
        width: payload.sizes.width,
        height: payload.sizes.height,
        type: 'Png'
      }
    }
    state.activeVariation.scene.previews.unshift(preview)
    state.activeVariation.settings.activePreviewIndex = 0
  },

  /**
   * Установка новых данных для превью
   * @param state
   * @param {Object} payload
   * @param {Number} payload.previewIndex
   * @param {Object} payload.previewData объект с новыми данными для превью
   */
  changePreviewImage (state, payload) {
    state.activeVariation.scene.previews[payload.previewIndex] = _.defaults(payload.previewData, state.activeVariation.scene.previews[payload.previewIndex])
  },

  changeProjectCover (state, coverUrl) {
    state.project.cover = coverUrl
  },

  /**
   * Устанавливает всем превью, у которых изменился дизайн поле 'isDesignChanged'.
   * Необходимо для дальнейшего обновления превью
   * @param state
   */
  previewDesignChanged (state) {
    _.forEach(state.activeVariation.scene.previews, (preview) => {
      preview.isDesignChanged = true
    })
  },

  /**
   * Удаление превью
   * @param state
   * @param {Number} previewIndex
   */
  removePreview (state, previewIndex) {
    state.activeVariation.scene.previews.splice(previewIndex, 1)
  },

  /**
   * Применение функции из api для изменения визуализации
   * @param state
   * @param {Object} payload
   * @param {String} payload.method Api метод
   * @param {Array} payload.args Аргументы для метода
   */
  adjustmentChange (state, payload) {
    if (state.activeVariation?.sceneInstance && typeof state.activeVariation.sceneInstance.api[payload.method] === 'function') {
      state.activeVariation.sceneInstance.api[payload.method](...payload.args)
      this.commit('activeProject/previewDesignChanged')
    }
  },

  /**
   * Изменяет value в настройках текстуры активного слоя
   * @param state
   * @param {Object} payload
   * @param {String} payload.settingsPath путь до нужного объекта в activeLayer.settings
   * @param {String | Number} payload.value новое значение для value или новый объект, если указан параметр "updateObject"
   * @param {Boolean} payload.updateObject тут указывается нужно-ли обновлять объект целиком
   * @param {String} payload.key указываем какой ключ у объекта мы меняем, по умолчанию value
   */
  layerSettingsLocalChange (state, payload) {
    if (payload.updateObject) {
      _.set(state.activeLayer.settings, payload.settingsPath, _.clone(payload.value))
    } else {
      const settingsObject = _.get(state.activeLayer.settings, payload.settingsPath)
      // Vue.set(settingsObject, payload.key || 'value', payload.value)
      settingsObject[payload.key || 'value'] = payload.value
    }
  },

  /**
   * Применение текстуры из каталога
   * @param state
   * @param {Object} texture
   */
  applyTexture (state, texture) {
    if (state.isTextureLoading) return false

    state.isTextureLoading = true

    if (texture && texture.data.presets[0].id) {

      const textureCopy = _.cloneDeep(texture)
      const texturePreset = textureCopy.data.presets[0]

      _.forEach(texturePreset, (value, key) => {
        if (value.enabled === false) {
          const currentKeyValue = state.activeLayer.settings.texture[key]
          currentKeyValue.visible = value.visible

          if (currentKeyValue) {
            texturePreset[key] = currentKeyValue
          }
        }
      })

      state.activeLayer.settings.texture = texturePreset
      state.activeLayer.settings.textureId = textureCopy.id
      state.activeLayer.settings.id = texturePreset.id

      // TODO: add preset id
      state.activeVariation.sceneInstance.api.setTexture(state.activeMaterial.id, state.activeMaterial.activeLayerIndex, _.cloneDeep(textureCopy), texturePreset.id)

      state.isTextureLoading = false
      this.dispatch('projectHistory/addSnapshot', state.activeVariation.scene, { root: true })
      this.commit('activeProject/addedUnsavedChanges')
    }
  },

  setSceneData (state, payload) {
    state.activeVariation.scene = payload.scene

    this.commit('activeProject/changeActiveMaterial', payload.materialIndex)
    this.commit('activeProject/changeActiveLayer', payload.layerIndex)
    // this.commit('activeProject/adjustmentChange', {
    //   method: 'setTransformTexture',
    //   args: [state.activeMaterial.id, state.activeMaterial.activeLayerIndex, state.activeLayer.settings.texture.sizeTexture.value]
    // })
  },

  /**
   * Добавить или убрать лайк в активный проект
   * @param state
   * @param payload
   */
  changeActiveProjectLikeCount (state, payload) {
    if (state.project.id === payload.id) {
      state.project.you.liked = payload.isLike

      if (payload.isLike) {
        state.project.likes++
      } else {
        state.project.likes--
      }
    }
  },

  changeActiveProjectState (state, { projectId, newState, isSelected }) {
    if (state.project.id === projectId) {
      state.project.state = newState
      state.project.is_selected = isSelected
    }
  },

  changeAccess (state, access) {
    state.editAccess = access
  },

  switchAdditionalSetting (state, newSettingName) {
    if (newSettingName === state.additionalSettingTab) {
      state.additionalSettingTab = null
    } else {
      state.additionalSettingTab = newSettingName
    }
  },

  clearAdditionalSetting (state) {
    state.additionalSettingTab = null
  },

  /**
   * Высчитывает позицию слоя и отправляет в api
   * @param state
   * @param {Object} payload
   * @param payload.translate
   * @param payload.width
   */
  layerDrag (state, payload) {
    const activeLayerIndex = state.activeMaterial.activeLayerIndex

    const previousDesignOffsetX = state.activeLayer.previousDesignOffsetX || 0
    const previousDesignOffsetY = state.activeLayer.previousDesignOffsetY || 0

    const xPos = previousDesignOffsetX + payload.translate[0] / (payload.width / 2) * -1
    const yPos = previousDesignOffsetY + payload.translate[1] / (payload.width / 2)

    this.commit('activeProject/adjustmentChange', {
      method: 'setTransformDesign',
      args: [state.activeMaterial.id, activeLayerIndex, {
        tX: xPos,
        tY: yPos,
        scale: state.activeLayer.settings.uvDesignMap.scale,
        rotation: state.activeLayer.settings.uvDesignMap.rotation
      }]
    })

    state.activeLayer.settings.uvDesignMap.offset.x = xPos
    state.activeLayer.settings.uvDesignMap.offset.y = yPos
  },

  /**
   * Устанавливает последнее значения координат x и y.
   * Необходимо для корректных вычислений абсолютной позиции и дальнейшей отправки в api
   * @param state
   * @param lastEvent
   */
  layerDragEnd (state, lastEvent) {
    if (lastEvent) {
      const previousDesignOffsetX = state.activeLayer.previousDesignOffsetX || 0
      const previousDesignOffsetY = state.activeLayer.previousDesignOffsetY || 0

      state.activeLayer.previousDesignOffsetX = previousDesignOffsetX + (lastEvent.translate[0] / (lastEvent.width / 2) * -1)
      state.activeLayer.previousDesignOffsetY = previousDesignOffsetY + (lastEvent.translate[1] / (lastEvent.width / 2))
    }
  },

  /**
   * Вычисляет абсолютный скейл и отправляет в api
   * @param state
   * @param scale
   */
  layerScale (state, scale) {
    const activeLayerIndex = state.activeMaterial.activeLayerIndex

    // Устанавливаем 1, если значение не задано
    const previousDesignScale = state.activeLayer.previousDesignScale || 1

    const computedScale = previousDesignScale * (1 / scale[0])
    state.activeLayer.settings.uvDesignMap.scale = computedScale

    this.commit('activeProject/adjustmentChange', {
      method: 'setTransformDesign',
      args: [state.activeMaterial.id, activeLayerIndex, {
        tX: state.activeLayer.settings.uvDesignMap.offset.x,
        tY: state.activeLayer.settings.uvDesignMap.offset.y,
        scale: computedScale,
        rotation: state.activeLayer.settings.uvDesignMap.rotation
      }]
    })
  },

  /**
   * Устанавливает последнее значение скейла.
   * Необходимо для корректных вычислений абсолютного скейла и дальнейшей отправки в api
   * @param state
   * @param lastEvent
   */
  layerScaleEnd (state, lastEvent) {
    // Устанавливаем 1, если значение не задано
    const previousDesignScale = state.activeLayer.previousDesignScale || 1

    state.activeLayer.previousDesignScale = previousDesignScale * (1 / lastEvent.scale[0])
  },

  /**
   * Отправляет абсолютное значение поворота в api
   * @param state
   * @param absoluteRotate
   */
  layerRotate (state, absoluteRotate) {
    const activeLayerIndex = state.activeMaterial.activeLayerIndex

    state.activeLayer.settings.uvDesignMap.rotation = absoluteRotate * -1

    this.commit('activeProject/adjustmentChange', {
      method: 'setTransformDesign',
      args: [state.activeMaterial.id, activeLayerIndex, {
        tX: state.activeLayer.settings.uvDesignMap.offset.x,
        tY: state.activeLayer.settings.uvDesignMap.offset.y,
        scale: state.activeLayer.settings.uvDesignMap.scale,
        rotation: absoluteRotate * -1
      }]
    })
  },

  /**
   * Переключает отображение слоя
   * @param state
   * @param layerIndex
   */
  toggleLayerVisibility (state, layerIndex) {
    if (layerIndex !== 0) {
      const layer = state.activeMaterial.layers[layerIndex]
      const newState = !layer.visible

      layer.visible = newState
      this.commit('activeProject/adjustmentChange', {
        method: 'setVisibleLayer',
        args: [state.activeMaterial.id, layerIndex, newState]
      })
      this.dispatch('projectHistory/addSnapshot', state.activeVariation.scene, { root: true })
      this.commit('activeProject/addedUnsavedChanges')
    }
  },

  setActiveMobileTab (state, tabName) {
    state.activeMobileTab = tabName
  },

  closeMobileTab (state) {
    state.activeMobileTab = ''
  },

  addSavedFile (state, data) {
    state.isSavingFile.push(data)
  },

  clearSavedFile (state) {
    state.isSavingFile = [
      new Promise((resolve, reject) => {
        resolve()
      })
    ]
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
