const state = {
  user: null
};

const getters = {
  isAuthenticated (state) {
    return !_.isEmpty(state.user)
  },
  user (state) {
    return state.user
  },
  userRole(state) {
    if (_.isEmpty(state.user)) return -1
    return state.user.role_id
  },
};

const actions = {
  async LogIn({ commit, dispatch }, token) {
    return this.$http.get('/v1/in/me', {
      headers: {
        Authorization: token
      }
    }).then(response => {
      if (response.data.ok && response.data?.body) {
        this.$cookie.setCookie('userToken', token)
        this.$http.defaults.headers.common['Authorization'] = token
        
        commit('setUser', response.data.body)
        return response.data.body
      } else {
        throw new Error()
      }
    }).catch((e) => {
      console.log(e)
      throw new Error();
    })
  },
  async LogOut({commit}){
    commit('LogOut')
    this.$cookie.removeCookie('userToken')
    delete this.$http.defaults.headers.common['Authorization']
  },
  async updateUser ({commit}) {
    await this.$http.get('/v1/in/me').then(response => {
      if (response.data.ok && response.data?.body) {
        commit('setUser', response.data.body)
      } else {
        throw new Error()
      }
    })
  }
}

const mutations = {
  setUser(state, data){
    state.user = { ...data }

    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['identify', {
      id: data.yid
    }]);
  },
  LogOut(state){
    state.user = { ...null }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
