<template lang="pug">
viz-card-controller(
  name="Color"
  :is-active="isActive"
)
  .color-icon(
    :class="{'color-icon_white': adjustment.value === '#FFFFFF'}"
    :style="'background: ' + adjustment.value"
  )
</template>

<script>
import VizCardController from './vizCardController'
export default {
  name: 'vizColorController',

  components: { VizCardController },

  props: {
    isActive: Boolean
  },

  computed: {
    layer () {
      return this.$store.state.activeProject.activeLayer
    },
    adjustment () {
      return _.get(this.layer.settings, 'texture.color')
    }
  }
}
</script>

<style lang="scss" scoped>
  .color-icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    @include media-breakpoint-up(xl) {
      width: 28px;
      height: 28px;
      border-radius: 8px;
    }
    &_white {
      @include media-breakpoint-down(xl) {
        border: 1px solid #E2E5E9;
      }
    }
  }
</style>
