<template lang="pug">
.settings-wrap
  .settings
    slot(
      name="outside-main"
    )
    perfect-scrollbar.settings__main
      slot
</template>

<script>
export default {
  name: 'vizAsideCardsWrap'
}
</script>

<style lang="scss" scoped>
.settings-wrap {
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3;
  //justify-content: center;
  height: 100%;
}
.settings {
  padding: 25px 0;
  width: 260px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @include media-breakpoint-up(xxl) {
    width: 290px;
  }
  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px 20px;
    margin: -25px 0;

    &:deep {
      & > .ps__rail-y {
        //transform: scaleY(0.9);
      }
      .card {
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
