<template lang="pug">
viz-card(
  title="Upload design"
)
  drop-zone(
    @drop.prevent="drop"
    @change="selectedFile"
    :isUploading="isUploading"
  )
</template>

<script>
import {inject, ref} from 'vue'
import { useStore } from 'vuex'
import VizCard from './vizCard'
import DropZone from './DropZone'
import BaseButton from "../BaseButton";
import axiosProgress from "@/functions/axiosProgress";
import getBase64 from "@/functions/getBase64";
import axios from "axios";
export default {
  name: 'vizAddDesign',

  components: { BaseButton, DropZone, VizCard },

  setup () {
    const toast = inject('toast')
    const store = useStore()
    let dropzoneFile = ref('')
    let isUploading = ref(false)
    let uploadingProgress = ref(0)

    const saveFile = () => {
      const formData = new FormData()
      formData.append('file_data', dropzoneFile.value)
      formData.append('file_type', 'design')
      formData.append('project_id', store.state.activeProject.project.id)

      const materialIndex = store.state.activeProject.activeVariation.activeMaterialIndex
      const layerIndex = store.state.activeProject.activeMaterial.activeLayerIndex
      const currentHistoryStep = store.state.projectHistory.currentSnapshotIndex

      const uploadTime = (new Date()).getTime()

      const savedFile = axios.post('/v1/uploader/point', formData)
        .then((response) => {
          store.commit('activeProject/addDesignUrl', {
            response: response.data,
            materialIndex,
            layerIndex,
            uploadTime
          })
          store.commit('projectHistory/addDesignUrl', {
            response: response.data,
            materialIndex,
            layerIndex,
            uploadTime
          })
        })
        .catch((err) => {
          console.error(err)

          store.commit('activeProject/changeActiveMaterial', materialIndex)
          store.commit('activeProject/removeLayer', 1)
          store.commit('projectHistory/clearHistory')
          store.dispatch('projectHistory/addSnapshot', store.state.activeProject.activeVariation.scene)
          store.commit('activeProject/clearSavedFile')
          toast.error('Error. Try uploading the design again')
        })

      store.commit('activeProject/addSavedFile', savedFile)
    }

    // const onProgress = (percent) => {
    //   uploadingProgress.value = percent
    // }

    const drop = (e) => {
      fileAdded(e.dataTransfer.files[0])
      e.dataTransfer.value = ''
    }

    const selectedFile = (e) => {
      fileAdded(e.target.files[0])
      e.target.value = ''
    }

    const fileAdded = (file) => {
      if (!isUploading.value) {
        // limit 20Mb
        if (file.size > 20000000) {
          toast.error('Sorry, the maximum file size is 20MB')
          return false
        }
        if (!['image/png', 'image/jpeg'].includes(file.type)) {
          toast.error('Sorry, this file type is not supported. You can upload a png or jpg image')
          return false
        }

        dropzoneFile.value = file
        isUploading.value = true

        getBase64(new File([file], file.name)).then(data => {
          store.dispatch('activeProject/applyDesign', data)
          store.commit('activeProject/clearAdditionalSetting')
          isUploading.value = false
          saveFile()
        })
      }
    }

    return {
      dropzoneFile,
      drop,
      selectedFile,
      isUploading,
      uploadingProgress
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
