import {
  MathUtils,
} from '../../../../../../build/three.module.js';

function setTransformTexture(materialId, layerIndex, scale=1, tX=0, tY=0, rotation=0, cX=0, cY=0){

  const materialIndex = this.dataScene.scene.materials.findIndex(mtl => mtl.id === materialId);
  const material = this.dataScene.scene.materials[materialIndex];
  const vScale = material.materialTextureSize / Number(scale);

  material
    .layers[Number(layerIndex)]
    .settings
    .texture.textureColor.__texture.uv.setUvTransform(
      tX, tY,
      vScale, vScale,
      MathUtils.degToRad(rotation),
      cX, cY
    );

  material
    .layers[Number(layerIndex)]
    .settings
    .texture.textureRGB.__texture.uv.setUvTransform(
      tX, tY,
      vScale, vScale,
      MathUtils.degToRad(rotation),
      cX, cY
    );

  if(material.layers[Number(layerIndex)].settings.texture.normalMap.__texture){
    material
      .layers[Number(layerIndex)]
      .settings
      .texture.normalMap.__texture.uv.setUvTransform(
        tX, tY,
        vScale, vScale,
        MathUtils.degToRad(rotation),
        cX, cY
      );
  }

};

function setSizeTexture(materialId, layerIndex, scale=1){

  const materialIndex = this.dataScene.scene.materials.findIndex(mtl => mtl.id === materialId);
  const material = this.dataScene.scene.materials[materialIndex];
  const vScale = material.materialTextureSize / Number(scale);
  const rotation = MathUtils.degToRad(
    material.layers[layerIndex].settings.texture.rotation ?
    material.layers[layerIndex].settings.texture.rotation.value :
    0
  );

  material
    .layers[Number(layerIndex)]
    .settings
    .texture.textureColor.__texture.uv.setUvTransform(
      0, 0, // tX, tY,
      vScale, vScale,
      rotation,
      0, 0, // cX, cY
    );

  material
    .layers[Number(layerIndex)]
    .settings
    .texture.textureRGB.__texture.uv.setUvTransform(
      0, 0, // tX, tY,
      vScale, vScale,
      rotation,
      0, 0, // cX, cY
    );

  if(material.layers[Number(layerIndex)].settings.texture.normalMap.__texture){
    material
      .layers[Number(layerIndex)]
      .settings
      .texture.normalMap.__texture.uv.setUvTransform(
        0, 0, // tX, tY,
        vScale, vScale,
        rotation,
        0, 0, // cX, cY
      );
  }


}

function setRotationTexture(materialId, layerIndex, value=0){

  const materialIndex = this.dataScene.scene.materials.findIndex(mtl => mtl.id === materialId);
  const material = this.dataScene.scene.materials[materialIndex];

  material.layers[0].settings.texture.rotation.value = value;
  const valRotation = MathUtils.degToRad(Number(value));

  //layer.settings.uvTextureMap.scale.value = material.materialTextureSize / val;
  let scale = material.materialTextureSize / material.layers[Number(layerIndex)].settings.texture.sizeTexture.value;

  material
    .layers[Number(layerIndex)]
    .settings
    .texture.textureColor.__texture.uv.setUvTransform(
      0, 0,
      scale, scale,
      valRotation
    ); //, 0.2, 0.2);

  material
    .layers[Number(layerIndex)]
    .settings
    .texture.textureRGB.__texture.uv.setUvTransform(
      0, 0,
      scale, scale,
      valRotation
    ); //, 0.2, 0.2);

  if(material.layers[Number(layerIndex)].settings.texture.normalMap.__texture){
    material
      .layers[Number(layerIndex)]
      .settings
      .texture.normalMap.__texture.uv.setUvTransform(
        0, 0,
        scale, scale,
        valRotation
      ); //, 0.2, 0.2);
  }

}

export {
  setTransformTexture,
  setSizeTexture,
  setRotationTexture,
};
