<template lang="pug">
.dropdown(
  v-click-away="onClickAway"
)
  .dropdown__activator(
    @click="toggle"
    :class="isOpened ? 'is-opened' : ''"
  )
    slot(
      name="activator"
    )
  transition(name="dropdown__content")
    .dropdown__content(
      v-show="isOpened"
    )
      .dropdown__content-inner.has-scrollbar
        slot
</template>

<style lang="scss" scoped>
  .dropdown {
    position: relative;
    height: 42px;
    display: flex;
    align-items: center;

    &__activator {
      cursor: pointer;
      position: relative;
    }

    &__content {
      background: $white;
      box-shadow: 0 10px 20px 0 rgb(0,0,0,0.1);

      z-index: 9999;
      position: absolute;
      top: 100%;
      right: 0;
      margin: 30px 0 0 0;
      padding: 10px;

      border-radius: 26px;
      width: auto;

      &-enter-active,
      &-leave-active {
        transition: all 0.2s;
      }
      &-enter-from,
      &-leave-to {
        opacity: 0;
        transform: translateY(-10px);
      }

      &-inner {
        max-height: calc(100vh - 114px);
      }
    }
  }
</style>

<script>
import { mixin as VueClickAway } from 'vue3-click-away'

export default {
  name: 'TheHeaderDropdown',
  mixins: [VueClickAway],
  components: {
  },

  props: {
    isLoggedIn: Boolean
  },
  computed: {
  },
  data: () => ({
    isOpened: false
  }),
  methods: {
    toggle() {
      this.isOpened = !this.isOpened
    },
    onClickAway(event) {
      this.isOpened = false
    }
  },
}
</script>
