<template lang="pug">
base-modal(
  v-model="isOpen"
  name="publish-project"
  :content-style="{maxWidth: '516px'}"
)
  .publish-project(
    v-if="project"
  )
    .publish-project__main
      .publish-project__image-wrap
        div.publish-project__loader(
          v-if="isCoverLoading"
        )
          .publish-project__loader-circle
          .publish-project__loader-text Creating preview

        load-image(
          v-show="!isCoverLoading"
          @onLoad="$store.commit('activeProject/rerenderCoverDone')"
        )
          template(
            #image
          )
            img.publish-project__image(
              v-if="coverUrlDone"
              :src="project.cover"
              :alt="project.name"
            )

      .publish-project__content
        .publish-project__name-wrap
          input.publish-project__name(
            :class="{'error': nameError}"
            @keydown="nameError = false"
            :value="project.name"
            @change="changeProjectName($event, project.id)"
          )
          .publish-project__name-error(
            v-if="nameError"
          ) Create new name

        ul.publish-project__data
          li.publish-project__data-item
            .publish-project__data-title Creator
            .publish-project__data-desc {{ project?.owner?.full_name }}

          li.publish-project__data-item(
            v-if="project?.created_at"
          )
            .publish-project__data-title Created
            .publish-project__data-desc {{ formatDate(project.created_at) }}

          li.publish-project__data-item(
            v-if="project.updated_at"
          )
            .publish-project__data-title Edited
            .publish-project__data-desc {{ formatDate(project.updated_at) }}
    .publish-project__footer.d-flex.justify-content-between
      base-button(
        color="gray"
        @click="isOpen = false"
      )
        arrow-left(
          :size="18"
          :style="{margin: '0 6px 0 0'}"
        )
        span Back to the editor
      base-button(
        color="white-outline"
        @click="publishProject(project)"
      ) Publish
</template>

<script>
import BaseModal from '../BaseModal'
import BaseButton from '../BaseButton'
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
import moment from 'moment'
import LoadImage from "@/components/utils/LoadImage";
export default {
  name: 'PublishProject',

  components: {LoadImage, BaseButton, BaseModal, ArrowLeft },

  data () {
    return {
      isOpen: false,
      nameError: false
    }
  },

  computed: {
    project () {
      return this.$store.state.activeProject.project
    },
    isCoverLoading () {
      return this.$store.state.activeProject.coverLoading.isCoverLoading
    },
    coverUrlDone () {
      return this.$store.state.activeProject.coverLoading.coverUrlDone
    }

  },

  methods: {
    changeProjectName (event, projectId) {
      const name = event.target.value

      if (name) {
        this.$store.dispatch('common/changeProjectName', { name, projectId })
      }
    },
    publishProject (project) {
      if (project.name === 'My new project name') {
        this.nameError = true
      } else {
        this.$store.dispatch('activeProject/publishDuck')
        this.isOpen = false

        // Hubspot track
        window._hsq = window._hsq || [];
        _hsq.push(['trackCustomBehavioralEvent', {name: 'pe20302049_published_duck',
          properties: {
            yi_timestamp: new Date().toISOString().split('T')[0],
            VID: this.$store.getters['auth/user'].yid,
            duck_url: location.href
          }
        }]);
      }
    },
    formatDate (date) {
      return moment(date).format('MMMM DD, YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>

  .publish-project {
    &__main {
      display: flex;
      margin-bottom: 65px;
    }
    &__image-wrap {
      width: 157px;
      height: 194px;
      overflow: hidden;
      margin-right: 20px;
      flex-shrink: 0;
      align-self: flex-start;
      border-radius: 8px;
      background: $gray;
      position: relative;
    }
    &__image {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
    }
    &__name-wrap {
      position: relative;
    }
    &__name-error {
      color: $red;
      position: absolute;
      font-size: 12px;
      bottom: -21px;
      left: 0;
      line-height: 1.25;
    }
    &__name {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.888889px;
      color: #000000;
      border: none;
      border-bottom: 1px solid $gray;
      padding: 0 0 7px 0;
      width: 100%;
      &.error {
        border-color: $red;
      }
      &:focus {
        outline: none;
      }
    }
    &__data {
      margin: 20px 0 0 0;
      padding: 0;
      list-style: none;
      &-item {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &-title {
        font-size: 10px;
        color: $gray-dark;
      }
      &-desc {
        font-weight: 700;
        font-size: 12px;
      }
    }
    &__loader {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 0;
      top: 0;
      text-align: center;
      &-text {
        font-size: 10px;
        font-weight: bold;
        letter-spacing: -0.4px;
        color: $gray-dark;
      }
      &-circle {
        margin-bottom: 12px;
        display: block;
        width: 18px;
        height: 18px;
        position: relative;
        animation: loading 1s linear infinite;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -3px;
          top: -3px;
          right: -3px;
          bottom: -3px;
          background-image: conic-gradient(from 0.37turn, #f5f5f7, #4494fe 0.18turn, #4040ff 0.29turn, #0036ff 0.39turn, #4040ff 0.53turn, #4494fe 0.65turn, #f5f5f7 0.8turn, #f5f5f7);
          border-radius: 50%;
        }
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          background: $gray;
          border-radius: 50%;
        }
      }
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
