<template lang="pug">
.copy-link-btn-wrap(
  @click="copyUrl"
)
  slot
</template>

<script>
export default {
  name: "CopyCurrentLink",
  emits: ['onCopyUrl'],

  data () {
    return {
      currentUrl: ''
    }
  },

  created () {
    this.currentUrl = window.location.href
  },

  methods: {
    copyUrl () {
      let tempInput = document.createElement('input')
      tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
      tempInput.value = this.currentUrl
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.$emit('onCopyUrl')
    }
  }
}
</script>

<style scoped>

</style>
