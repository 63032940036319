<template lang="pug">
.menu
  v-dropdown-menu
    button.menu__button
      BaseIcon(
        data="@icon/burger-circles.svg"
        width="4"
        height="16"
      )
    template(
      #popper
    )
      .menu__content
        ul.menu__list
          li.menu__list-item(
            v-for="(option, index) in options"
            :key="index"
            @click="option.onClick"
          ) {{ option.text }}

</template>

<script>
import VDropdownMenu from '@/components/VDropdownMenu'
export default {
  name: 'BurgerMenu',
  components: { VDropdownMenu },
  props: {
    options: Array
  }
}
</script>

<style lang="scss" scoped>


.menu {
  &__button {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    
    background: rgba(0,0,0,0);
    transition: background .2s; 
    :deep {
      svg {
        display: block;
      }
    }

    &:hover {
      background: rgba(0,0,0,0.05);
    }
  }
  &__content {
    width: 144px;
    padding: 12px 12px;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    border-radius: 6px;
    padding: 12px 12px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.46px;

    transition: background .2s;
    &:hover {
      background: $gray;
    }
  }
}
</style>
