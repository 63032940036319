//import {OrbitControls} from './OrbitControls.js';
import {OrbitControls} from '../../../../jsm/controls/OrbitControls.js';
//import {TransformControls} from './TransformControls.js';
//import {ObjectControls} from './ObjectControls.js';

export const control = function(){

  this.controls = {};
  this.controls.orbitControl = new OrbitControls(this.camera, this.DOM.drawing);
  this.controls.orbitControl.enablePan = false;
  this.controls.orbitControl.enabled = true;
  this.controls.orbitControl.damping = 0.2;
  this.controls.orbitControl.minDistance = this.dataScene.scene.models[0].camera.control.minDistance;
  this.controls.orbitControl.maxDistance = this.dataScene.scene.models[0].camera.control.maxDistance;
  //this.controls.orbitControl.maxPolarAngle = 90 * Math.PI / 180; // radians
  this.controls.orbitControl.autoRotate = false;
  this.controls.orbitControl.enableKeys = false;
  this.controls.orbitControl.update();

};
