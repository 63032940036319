<template lang="pug">
label.file-uploader
  input.file-uploader__input(
    type="file"
    @change="selectedFile"
  )
  slot
</template>

<script>
import axios from "axios";
import getBase64 from "@/functions/getBase64";

export default {
  name: "vizInputDesign",

  data () {
    return {
      currentFile: null,
      dropzoneFile: '',
      isUploading: false
    }
  },

  methods: {
    selectedFile (e) {
      this.fileAdded(e.target.files[0])
      e.target.value = ''
    },
    fileAdded (file) {
      if (!this.isUploading) {
        if (['image/png', 'image/jpeg'].includes(file.type)) {
          this.dropzoneFile = file
          this.isUploading = true

          getBase64(new File([file], file.name)).then(data => {
            this.$store.dispatch('activeProject/applyDesign', data)
            this.$store.commit('activeProject/clearAdditionalSetting')
            this.isUploading = false
            this.saveFile()
          })
        } else {
          this.$toast.error('Sorry, this file type is not supported. You can upload a png or jpg image')
        }
      }
    },
    saveFile () {
      const formData = new FormData()

      formData.append('file_data', this.dropzoneFile)
      formData.append('file_type', 'design')
      formData.append('project_id', this.$store.state.activeProject.project.id)

      const materialIndex = this.$store.state.activeProject.activeVariation.activeMaterialIndex
      const layerIndex = this.$store.state.activeProject.activeMaterial.activeLayerIndex

      const uploadTime = (new Date()).getTime()

      const savedFile = this.$http.post('/v1/uploader/point', formData)
        .then((response) => {
          this.$store.commit('activeProject/addDesignUrl', {
            response: response.data,
            materialIndex,
            layerIndex,
            uploadTime
          })
          this.$store.commit('projectHistory/addDesignUrl', {
            response: response.data,
            materialIndex,
            layerIndex,
            uploadTime
          })
        })
        .catch((err) => {
          console.error(err)

          this.$store.commit('activeProject/changeActiveMaterial', materialIndex)
          this.$store.commit('activeProject/removeLayer', 1)
          this.$store.commit('projectHistory/clearHistory')
          this.$store.dispatch('projectHistory/addSnapshot', this.$store.state.activeProject.activeVariation.scene)
          this.$store.commit('activeProject/clearSavedFile')
          this.$toast.error('Error. Try uploading the design again')
        })

      this.$store.commit('activeProject/addSavedFile', savedFile)
    }
  }
}
</script>

<style lang="scss" scoped>
.file-uploader {
  &__input {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
</style>
