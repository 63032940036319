<template lang="pug">
.viz-header(
  :class="{'viz-header_no-edit-access': !editAccess}"
)
  .viz-header__gallery
    .visualizer-logo.viz-header__logo.d-xl-block.d-none
      router-link.visualizer-logo__link(
        to="/"
      )
      BaseIcon.visualizer-logo__icon(
        data="@icon/visualizer-logo.svg"
        width="42"
        height="42"
        :original="true"
      )
    base-button(
      to="/"
      color="white"
      :style="{width: '112px'}"
    )
      span.d-xl-none
        BaseIcon(
          data="@icon/chevron_left.svg"
          width="22"
          height="22"
          :style="{marginLeft: '-7px'}"
        )
      | Gallery

  mq-responsive(
    target="lg-"
  )
    .viz-header__item
      viz-mobile-help

  template(
    v-if="editAccess"
  )
    template(
      v-if="activeProject.state === 0"
    )
      .viz-header__item.viz-header__item_name
        input.viz-header__name-input(
          v-tooltip.bottom="{theme: 'hint', content: 'Project name', shown: isHintsOpen, triggers: []}"
          :value="projectName"
          @change="changeProjectName"
        )

      mq-responsive(
        :style="{display: 'flex'}"
        target="xl+"
      )
        .viz-header__item
          base-button(
            @click="$store.dispatch('activeProject/saveProject', {disableMessage: true})"
          ) Save as draft
        .viz-header__item
          base-button(
            @click="onPublish"
            :style="{width: '112px'}"
          ) Publish

        .history-action.viz-header__item
          button.history-action__item(
            :class="{'disabled': $store.state.projectHistory.currentSnapshotIndex === 0}"
            @click="$store.dispatch('projectHistory/snapshotBack').then()"
          )
            undo(
              :size="24"
              fill-color="#ffffff"
            )
          button.history-action__item(
            :class="{'disabled': $store.state.projectHistory.currentSnapshotIndex === $store.state.projectHistory.snapshots.length - 1}"
            @click="$store.dispatch('projectHistory/snapshotForward').then()"
          )
            redo(
              :size="24"
              fill-color="#ffffff"
            )


    .viz-header__item(
      v-else
    )
      base-button.unpublish-btn(
        @click="onDelete"
        :style="{width: '112px'}"
      ) Delete
  .viz-header__item.viz-header__item_create-duck-btn(
    v-else
  )
    base-button(
      @click="$store.dispatch('projects/createProject', { id: 25, name: 'My new project name', isMobile: mq.smMinus, browser: $sniffr.browser })"
    ) Create your duck

  .viz-header__item.viz-header__item_moderation.d-md-block.d-none(
    v-if="(userRole === 2 || userRole === 3) && activeProject.state !== 0"
  )
    project-moderation(
      :project="activeProject"
    )

  .viz-header__item(
    :style="{marginLeft: 'auto'}"
    v-if="activeProject.last_message"
  )
    viz-rejected-message(
      :message="activeProject.last_message"
    )
</template>

<script>
import Undo from 'vue-material-design-icons/Undo.vue'
import Redo from 'vue-material-design-icons/Redo.vue'
import VizLogo from "../icons/VizLogo";
import BaseButton from "../BaseButton";
import AccountSwitchOutline from 'vue-material-design-icons/AccountSwitchOutline.vue'
import VizRejectedMessage from "@/components/player/vizRejectedMessage";
import ProjectModeration from "@/components/ProjectModeration"
import { MqResponsive } from "vue3-mq"
import VizMobileHelp from "@/components/player/vizMobileHelp";

export default {
  name: 'vizHeader',

  inject: ["mq"],

  components: {VizRejectedMessage, VizMobileHelp, ProjectModeration, AccountSwitchOutline, BaseButton, VizLogo, Undo, Redo, MqResponsive },

  computed: {
    isHintsOpen () {
      return this.$store.state.activeProject.isHintsOpen
    },
    activeVariation () {
      return this.$store.state.activeProject.activeVariation
    },
    projectName () {
      return this.$store.state.activeProject.project.name
    },
    userRole () {
      return Number(this.$store.getters['auth/userRole'])
    },
    activeProject () {
      return this.$store.state.activeProject.project
    },
    editAccess () {
      return this.$store.state.activeProject.editAccess
    }
  },

  methods: {
    onPublish () {
      if (this.$store.state.activeProject.hasUnsavedChanges) {
        this.$store.commit('activeProject/startRenderCover')
        this.$store.dispatch('activeProject/saveProject', {disableMessage: true})
      }
      this.$vfm.show('publish-project')
    },
    onDelete () {
      this.$vfm.show('delete-project', {projectId: this.activeProject.id})
    },
    changeProjectName (val) {
      const name = val.target.value

      if (name) {
        this.$store.commit('activeProject/changeProjectName', name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .viz-header {
    position: absolute;
    display: flex;
    left: 0;
    top: 20px;
    align-items: center;
    padding-right: 88px;
    padding-left: 16px;
    width: 100%;
    @include media-breakpoint-up(xl) {
      width: calc(100% - 45px);
      padding-right: 260px;
      padding-left: 0;
      left: 45px;
      top: 25px;
    }
    @include media-breakpoint-up(xxl) {
      padding-right: 290px;
    }
    &_no-edit-access {
      @include media-breakpoint-down(xl) {
        padding-right: 16px;
      }
    }
    &__gallery {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        margin-right: auto;
      }
      @include media-breakpoint-up(xl) {
        margin-right: 32px;
      }
    }
    &__item {
      margin-left: 8px;
      @include media-breakpoint-up(xl) {
        margin-left: 12px;
      }
      &_name {
        @include media-breakpoint-down(xl) {
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          margin: 0;
        }
      }
      &_moderation {
        margin-right: 0;
        margin-left: auto;
        @include media-breakpoint-up(xl) {
          margin: auto;
        }
      }
      &_create-duck-btn {
        @include media-breakpoint-down(xl) {
          margin-left: auto;
          order: 4;
        }
        @include media-breakpoint-down(md) {
          margin-left: 8px;
        }
      }
    }
    &__logo {
      margin-right: 12px;
      &:deep {
        svg {
          display: block;
        }
      }
    }
    &__name-input {
      height: 42px;
      background: $white;
      border: none;
      padding: 0 12px;
      letter-spacing: -0.622222px;
      border-radius: 9px;
      &:focus, &:focus-visible {
        outline: $blue solid 2px;
      }
    }
  }
  .history-action {
    display: flex;
    &__item {
      margin-right: 3px;
      width: 42px;
      height: 42px;
      background: $black;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      transition: 0.2s;
      &.disabled {
        pointer-events: none;
        background: $white;
        &:deep {
          svg {
            fill: $gray-dark
          }
        }
      }
      &:deep {
        svg {
          display: block;
          transition: 0.2s;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background: $blue;
      }
    }
  }
  .unpublish-btn {
    &:hover {
      background: $red;
    }
  }
</style>
