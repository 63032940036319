<template lang="pug">
.card-wrap
  .card-like
    button.card-like__btn(
      :class="{ 'card-like__btn_nolike': !liked }"
      @click="onLike"
    )
      BaseIcon(
        data="@icon/heart.svg"
        width="24"
        height="22"
        color="currentColor"
        stroke="#A6A6A6"
      )
    .card-like__count {{ likeCount }}
  burger-menu.card-menu(
    :options="menuOptions"
    v-if="menuOptions.length !== 0"
  )

  router-link.card(
    :to='`/p/${item.id}`'
  )
    .card__thumb
      img(
        :src="item.cover"
        :alt="item.name"
      )
      .card__status(
        v-if="userProject"
        :class="status.statusClass"
      ) {{ status.title }}
    .card__title {{ item.name }}
    .card__info(
      v-if="item.owner"
    )
      .card__avatar
        img(
          :src="item.owner.avatar"
        )
      .card__data
        .card__owner {{ item.owner.full_name }}
        .card__role 3D Visual
</template>

<script>
import ProjectModeration from "@/components/ProjectModeration";
import store from "@/store";
import VDropdownMenu from "@/components/VDropdownMenu";
import BurgerMenu from "@/components/BurgerMenu";
export default {
  name: 'ProjectItem',

  components: {BurgerMenu, VDropdownMenu, ProjectModeration},

  props: {
    item: Object,
    userProject: Boolean,
    noModerate: Boolean
  },

  // data () {
  //   return {
  //   }
  // },

  computed: {
    likeCount () {
      return this.item.likes
    },
    liked () {
      if (this.$store.getters['auth/isAuthenticated']) {
        return this.item?.you?.liked
      }
    },
    userRole () {
      return Number(this.$store.getters['auth/userRole'])
    },
    status () {
      switch (this.item.state) {
        case 0:
          return {
            title: 'Draft',
            statusClass: 'color-red'
          }
        case 1:
          return {
            title: 'Moderation',
            statusClass: 'color-blue'
          }
        case 2:
          return {
            title: 'Published'
          }
        case 3:
          return {
            title: 'Rejected',
            statusClass: 'color-red'
          }
        default:
          return {
            title: ''
          }
      }
    },
    menuOptions () {
      const menuOptions = []

      if (this.userProject) {
        if (this.item.state === 0) {
          menuOptions.push({
            text: 'Publish',
            onClick: () => {
              this.$router.push(`/p/${this.item.id}`)
            }
          })
        }

        menuOptions.push({
          text: 'Delete',
          onClick: () => {
            this.$vfm.show('delete-project', {projectId: this.item.id})
          }
        })
      } else if ((this.userRole === 2 || this.userRole === 3) && this.item.state !== 0 ) {
        if (!this.item.is_selected) {
          menuOptions.push(
            {
              text: 'Selected 12',
              onClick: () => {
                this.$store.dispatch('common/onChangeState', { projectId: this.item.id, newState: 2, isSelected: true })
              }
            }
          )
        } else {
          menuOptions.push(
            {
              text: 'Remove from Selected 12',
              onClick: () => {
                this.$store.dispatch('common/onChangeState', { projectId: this.item.id, newState: 2, isSelected: false, removeSelected: true })
              }
            }
          )
        }

        if (this.item.state === 1 || this.item.state === 3) {
          menuOptions.push(
            {
              text: 'Approve',
              onClick: () => {
                this.$store.dispatch('common/onChangeState', { projectId: this.item.id, newState: 2, isSelected: false })
              }
            }
          )
        }

        if (this.item.state === 1 || this.item.state === 2) {
          menuOptions.push(
            {
              text: 'Reject',
              onClick: () => {
                this.$vfm.show('reject-project', {projectId: this.item.id})
              }
            }
          )
        }
      }

      return menuOptions
    }
  },

  methods: {
    onLike () {
      this.$store.dispatch('likes/onProjectLike', {id: this.item.id, isLike: !this.liked})
    }
  }
}
</script>

<style lang="scss" scoped>

.card-wrap {
  position: relative;
  &:hover {
    .card {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      z-index: 15;
      color: $blue;
    }
  }
}
.card {
  transition: box-shadow .4s;
  cursor: pointer;
  display: block;
  position: relative;
  z-index: 1;
  min-height: 1px;
  border-radius: 18px;
  padding: 10px;
  font-size: 14px;

  @include media-breakpoint-down(md) {
    padding: 6px;
    font-size: 13px;
    line-height: 1.2;
  }

  &__thumb {
    -webkit-mask-image: -webkit-radial-gradient(#fff,#000);
    position: relative;
    z-index: 5;
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    background-color: $gray;
    user-select: none;

    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 124.464%;
    }

    img {
      width: 100%;
      display: block;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  &__title {
    transition: color .2s ease;
    font-weight: 700;
    margin: 9px 0 0;

    color: inherit;

    position: relative;
    z-index: 5;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    font-size: 12px;
    line-height: 1.35;
    font-weight: 700;
    margin: 6px 0 0;
    overflow: hidden;
    vertical-align: top;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @include media-breakpoint-down(md) {
      font-size: 11px;
      line-height: 1.4;
    }
  }

  &__avatar {
    position: relative;
    z-index: 5;
    margin: -1px 8px 0 0;

    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100%;
    border: 1px solid #fff;
    background: #f5f5f7;
    -webkit-mask-image: -webkit-radial-gradient(#fff,#000);

    img {
      width: 100%;
      height: auto;
    }

    @include media-breakpoint-down(md) {
      margin: 0 6px 0 0;
    }
  }

  &__data {
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  &__owner {
    color: $black;

    position: relative;
    z-index: 5;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__role {
    position: relative;
    z-index: 5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    color: #656565;
  }
}
.card-like {
  position: absolute;
  top: 20px;
  left: 22px;
  z-index: 20;

  width: 42px;
  height: 42px;
  border-radius: 100%;
  background: $white;
  color: #1E1D2B;

  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    top: 18px;
    left: 18px;
  }

  &__btn {
    margin: 2px 0 0 0;
    cursor: pointer;
    display: block;
    padding: 0;
    border: none;
    background: transparent;
    color: #1E1D2B;
    transition: color .2s;

    .svg-icon {
      display: block;
      pointer-events: none;
    }
    path {
      transition: fill .2s, stroke .2s;
    }
    &_nolike {
      color: transparent;

      :deep {
        path {
          stroke: #A6A6A6;
          stroke-width: 1px;
        }
      }
  
      &:hover {
        color: $blue;
        :deep {
          path {
            stroke: transparent;
          }
        }
      }
    }
  }

  &__count {
    background: $yellow;
    border-radius: 999px;
    font-weight: 700;
    position: absolute;
    top: 2px;
    left: 100%;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.13px;
    padding: 3px 7px;
    margin: 0 0 0 -17px;
  }
}
.card-moderation {
  position: absolute;
  top: 20px;
  right: 22px;
  z-index: 20;
}
.card__status {
  z-index: 20;
  font-size: 9px;
  font-weight: bold;
  position: absolute;
  right: 28px;
  bottom: 17px;
  color: $black;
  &.color-red {
    color: $red;
  }
  &.color-blue {
    color: $blue;
  }
}
:deep .card-menu {
  position: absolute;
  top: 28px;
  right: 22px;
  z-index: 20;
}

</style>
