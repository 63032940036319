<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="join-now"
  @modalClose="isOpen = false"
)
  template(
    v-slot:default="{ params }"
  )
    h2.standard-modal__title {{ joinNowContent[params.regRequiredType]?.title }}
    div {{ joinNowContent[params.regRequiredType]?.subtitle }}

  template(
    #buttons
  )
    base-button(
      color="gray"
      to="/login"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Join now
    base-button(
      to="/login"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Sign in
</template>

<script>
import BaseButton from '@/components/BaseButton'
import StandardModal from '@/components/modals/StandardModal'
export default {
  name: "JoinNowModal",

  inject: ["mq"],
  components: { StandardModal, BaseButton },

  data () {
    return {
      isOpen: false,
      joinNowContent: {
        'create-duck': {
          title: 'Registration required',
          subtitle: 'To start creating your own duck you need to register or log in to your account'
        },
        'like-project': {
          title: 'Registration required',
          subtitle: 'To participate in the voting, please log in to your account, or register'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
