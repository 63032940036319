import axios from "axios"

function axiosProgress (url, data, onProgress) {
  let config = {
    onUploadProgress(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) /
        progressEvent.total)

      // execute the callback
      if (onProgress) onProgress(percentCompleted)

      return percentCompleted
    }
  }
  return axios.post(url, data, config)
    .then(response => response)
    .catch(error => error)
}

export default axiosProgress
