<script>
import { PopperWrapper } from 'floating-vue'
export default {
  ...PopperWrapper,
  name: 'VDropdownMenu',
  vPopperTheme: 'dropdown-menu'
}
</script>

<style lang="scss">
.v-popper--theme-dropdown-menu.v-popper__popper {
  top: -6px;

  .v-popper__inner {
    background: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);
    border-radius: 16px;
  }
  .v-popper__arrow-container {
    display: none;
  }
}
</style>