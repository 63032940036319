<template lang="pug">
h1 Top 12 authors created the <br>most popular ducks

Flicking.row.row_scroller.leaders(
  :options="{ moveType: 'freeScroll', bound: true, align: 'prev', bounce: 0, autoResize: true, disableOnInit: true }"
  ref="leaders"
  @wheel="onWheel($event, this.$refs.leaders)"
  @ready="onFlickChange($event, this.$refs.leaders)"
  @after-resize="onFlickChange($event, this.$refs.leaders)"
)
  .col-4.col-lg-3.col-xl-2.leaders__col(
    v-for="(leader, leaderIndex) in leaders"
    :key="leaderIndex"
  )
    router-link.leader.d-flex.align-items-center(
      :to='`/p/${leader.id}`'
    )
      .leader__place {{ leaderIndex+1 }}
      .leader__avatar
        .leader__avatar-inner
          img(
            :src="leader.owner.avatar"
          )
      .leader__info
        .leader__name {{ leader.owner.full_name }}
        .leader__likes {{ leader.likes }} Likes
</template>

<script>
import Flicking from "@egjs/vue3-flicking"

export default {
  name: 'Leaders',
  inject: ["mq"],
  components: {
    Flicking
  },

  data () {
    return {
      leaders: [],
      isSliderEnabled: false
    }
  },

  mounted () {
    this.$http.get('/v1/showcase/projects?order=author&page=1&count=12')
      .then((result) => {
        this.leaders = result.data.body.items
      })
  },

  methods: {
    onWheel(e, flick){
      if (e.deltaX !== 0 && this.mq.mdMinus && this.isSliderEnabled) {
        e.preventDefault();
        flick.control.controller.axes.setTo({ flick: flick.camera.position + e.deltaX }, 0);
      }
    },
    onFlickChange(e, flick){
      if (this.mq.mdPlus && this.isSliderEnabled) {
        this.isSliderEnabled = false
        flick.disableInput().moveTo(0, 0)
      } else if (!this.mq.mdPlus && !this.isSliderEnabled) {
        this.isSliderEnabled = true
        flick.enableInput().moveTo(0, 0)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.leaders {
  &.row {
    padding-top: 15px;
    padding-bottom: 35px;

    @include media-breakpoint-down(xxl) {
      padding-bottom: 25px;
    }
  }

  &__col {
    &:nth-child(1) {
      .leader__avatar:before {
        background: rgb(247,135,0);
        background: linear-gradient(0deg, #F78700 0%, #FFF600  100%);
      }
    }
    &:nth-child(2) {
      .leader__avatar:before {
        background: rgb(247,135,0);
        background: linear-gradient(0deg, #38BF01 0%, #A8FF0F 100%);
      }
    }
    &:nth-child(3) {
      .leader__avatar:before {
        background: rgb(247,135,0);
        background: linear-gradient(0deg, #3023AE 0%, #C86DD7 100%);
      }
    }
  }
}

.leader {
  user-select: none;
  -webkit-user-drag: none;

  transition: background .4s, box-shadow .4s, color .2s;
  background: $gray;
  border-radius: 12px;
  cursor: pointer;
  min-height: 84px;

  padding: 10px 12px;
  margin: 0 0 20px 0;

  @include media-breakpoint-down(md) {
    margin: 0;
  }

  &:hover {
    color: $blue;
    background: $white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(md) {
      background: $gray;
      box-shadow: none;
    }
  }

  &__place {
    line-height: 1;
    font-weight: $font-weight-bold;
    color: $blue;
    letter-spacing: -0.53px;
    width: 18px;
    text-align: center;
    margin: 0 8px 0 -2px;
    flex-shrink: 0;
  }

  &__avatar {
    position: relative;
    width: 44px;
    height: 44px;
    
    margin: 0 6px 0 0;
    flex-shrink: 0;
    padding: 1px;
    border-radius: 100%;
    overflow: hidden;

    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    &-inner {
      border: 2px solid $gray;
      position: relative;
      z-index: 4;
      border-radius: 100%;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__info {
    overflow: hidden;
    margin: 4px 0 0 0;
  }

  &__name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-bottom: 2px;
    transition: color 0.2s;

  }
  &__likes {
    color: #656565;
    line-height: 1.3;
  }


}
</style>
