<template lang="pug">
.card
  .card__head
    slot(
      name="headBefore"
    )
    .card__title(
      v-if="title"
    ) {{ title }}
    slot(
      name="headAfter"
    )
    button.card__close(
      v-if="closeButton"
      @click="$emit('closeCard')"
    )
      arrow-collapse-right(
        :size="18"
      )
  .card__top-content
    slot(
      name="top"
    )
  mq-responsive.card__scroll-wrap(
    target="xl+"
  )
    perfect-scrollbar.card__container(
      ref="scrollbar"
      :watchOptions="true"
      :options="{suppressScrollX: true, wheelPropagation: currentWheelPropagation, swipeEasing: true}"
      @ps-y-reach-end="scrollReach"
      @ps-y-reach-start="scrollReach"
      @ps-scroll-y="onScroll"
    )
      slot
  mq-responsive.card__scroll-wrap(
    target="lg-"
  )
    .card__container.card__container_mobile
      slot
</template>

<script>
import ArrowCollapseRight from 'vue-material-design-icons/ArrowCollapseRight.vue'
import { MqResponsive } from "vue3-mq"
export default {
  name: 'vizCard',

  components: { ArrowCollapseRight, MqResponsive },
  emits: ['closeCard'],

  data () {
    return {
      currentWheelPropagation: true
    }
  },

  props: {
    title: String,
    closeButton: Boolean,
    stepBack: Boolean,
    scrollTop: Number
  },

  methods: {
    scrollReach () {
      this.currentWheelPropagation = true
    },
    onScroll () {
      this.currentWheelPropagation = false
    }
  },

  watch: {
    scrollTop (topPosition) {
      this.$refs.scrollbar.$el.scrollTop = topPosition
    }
  }
}
</script>

<style lang="scss" scoped>

  .card {
    background: $white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(xl) {
      max-height: 460px;
      overflow: hidden;
    }
    @include media-breakpoint-up(xl) {
      padding: 10px;
      border-radius: 8px;
    }
    &__scroll-wrap {
      flex-grow: 1;
      overflow: hidden;
      padding: 16px;
      margin: -16px;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(xl) {
        padding-right: 10px;
        margin-right: -10px;
        display: block;
      }
    }
    &__head {
      margin-bottom: 10px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 24px;
      position: relative;
      z-index: 2;
      @include media-breakpoint-up(xl) {
        margin-bottom: 6px;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      margin: 0 -10px;
      height: 100%;

      @include media-breakpoint-down(xl) {
        overflow-y: auto;
        padding: 0 16px;
        margin: 0 -16px;
      }
    }
    &__title {
      font-weight: bold;
      line-height: 17px;
      letter-spacing: -0.533333px;
      font-size: 16px;
      @include media-breakpoint-up(xl) {
        font-size: 14px;
      }
    }
    &__close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      border: none;
      background: inherit;
      transition: 0.2s;
      margin-left: auto;
      &:deep {
        svg {
          display: block;
        }
        path {
          transition: 0.2s;
        }
      }
      &:hover {
        background: $gray;
        &:deep {
          path {
            fill: $blue;
          }
        }
      }
    }
  }
</style>
