<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="reject-project"
  @modalClose="isOpen = false"
)
  template(
    #default
  )
    h2.standard-modal__title Moderation denied
    div Choose how you want to refuse moderation
    textarea.reject-textarea(
      placeholder="Text comment"
      v-model="rejectMessage"
    )

  template(
    v-slot:buttons="{ params }"
  )
    base-button(
      :style="{flexGrow: 1}"
      color="gray"
      @click="revisionProject(params.projectId)"
      :size="mq.smMinus ? 'large': 'medium'"
    ) For revision
    base-button(
      :style="{flexGrow: 1}"
      color="gray"
      @click="refuseProject(params.projectId)"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Refuse
</template>

<script>
import BaseButton from '../BaseButton'
import StandardModal from '@/components/modals/StandardModal'
export default {
  name: 'RejectProject',

  inject: ["mq"],
  components: { StandardModal, BaseButton },

  data () {
    return {
      isOpen: false,
      rejectMessage: ''
    }
  },

  methods: {
    revisionProject (projectId) {
      this.$store.dispatch('common/onChangeState', {
        projectId: projectId,
        newState: 0,
        isSelected: false,
        message: this.rejectMessage
      }).then(() => {
        this.isOpen = false
      })
    },
    refuseProject (projectId) {
      this.$store.dispatch('common/onChangeState', {
        projectId: projectId,
        newState: 3,
        isSelected: false,
        message: this.rejectMessage
      }).then(() => {
        this.isOpen = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reject-textarea {
  background: #FFFFFF;
  border: 1px solid $gray;
  border-radius: 16px;
  width: 100%;
  height: 100px;
  padding: 13px 24px;
  resize: none;
  margin-top: 24px;
  display: block;
  margin-bottom: -12px;
  transition: 0.2s;
  &:focus, &:focus-visible {
    outline: none;
    border: 1px solid $blue;
  }
}
</style>
