<template lang="pug">
standard-modal(
  v-model="isOpen"
  name="unable-to-create"
  @modalClose="isOpen = false"
)
  h2.standard-modal__title Unable to create
  div The creation function is only available on desktop or tablet. We are working to ensure that you can take advantage of this opportunity in the future.

  template(
    #buttons
  )
    base-button(
      @click="isOpen = false"
      :size="mq.smMinus ? 'large': 'medium'"
    ) Ok
</template>

<script>
import StandardModal from "@/components/modals/StandardModal";
import BaseButton from "@/components/BaseButton";
export default {
  name: "UnableToCreate",
  inject: ["mq"],
  components: {BaseButton, StandardModal},
  data () {
    return {
      isOpen: false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
