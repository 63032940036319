<template lang="pug">
.container
  TheNavigation

.container
  h1.h1-title Rules

  accordion-wrap(
    :active-item="0"
  )
    accordion-item
      template(
        #accordion-trigger
      )
        div How to enter
      div.rules-content
        p
          strong No purchase necessary. To enter:
        p 1. Log in with Yellow Images account <br/>
          | 2. Download 2D duck cutline and create your own design or use a ready-to-use image <br/>
          | 3. Upload design layout in JPG or PNG format with max resolution 3000x3000 px <br/>
          | 4. Name your ducks. Every user is allowed to create and put for a voting up to 3 designs <br/>
          | 5. Entrants should submit final work between <strong>12:00pm PST April 1, 2022 to 12:00pm PST December 1, 2022</strong>

        p All approved works will be uploaded to the public gallery and participate in voting. This contest will be interpreted according to Canadian law.

    accordion-item
      template(
        #accordion-trigger
      )
        div How to vote
      div.rules-content
        p
          | 1. Log in with Yellow Images account <br/>
          | 2. Browse through the public gallery and hit “Like” under the duck that you are voting for <br/>
          | 3. Voters should submit final decision between <strong>12:00pm PST April 1, 2022 to 12:00pm PST January 1, 2023</strong>

    accordion-item
      template(
        #accordion-trigger
      )
        div The prize
      div.rules-content
        p
          strong There will be five (5) prizes awarded. Winners will receive the following prizes:
        p
          strong Tech Prizes:
        ul
          li One (1) 12.9-inch iPad Pro Wi-Fi 1 TB with Apple Pencil (approximate retail value $2500 cad)
          li One (1) 10.9-inch iPad Air Wi-Fi 256 GB (approximate retail value $1,150 cad)
        p
          strong Yellow Images Memberships:
        ul
          li Three (3) Annual Premium Yellow Images Memberships (approximate retail value $500 cad/each)
        p
          strong Souvenirs:
        ul
          li Three (3) memorable duck souvenirs (no retail value)
        p Prize must be accepted as awarded, it is not transferable and cannot be exchanged with Yellow Images for cash. If winner is unable to accept the Prize for any reason, Prize will be forfeited and no substitute prize will be provided. No telephone contact or correspondence will be entered into except with the winning entrant. Yellow Images reserves the right to substitute the prize which is, in their sole opinion, a prize of equivalent value and/or to terminate or withdraw this contest at any time. The chances of winning are dependent upon the total number of eligible entries received.

    accordion-item
      template(
        #accordion-trigger
      )
        div Eligibility
      div.rules-content
        p To be eligible to enter and win a prize, entrants should submit an entry during the contest period. This contest is open to everyone who have reached the age of majority according to the laws of the territory where they reside.

    accordion-item
      template(
        #accordion-trigger
      )
        div Selection
      div.rules-content
        p Two (2) winners will be selected by the public voting based on the numbers of “Likes” and One (1) winner will be selected by the Yellow Images’ contest judges.
        p
          strong Selection will be conducted until 12:00 pm PST January 2, 2023
        p
          strong All prizes will be sent to the contest winners by December 31, 2023
        p In case of a tie, Yellow Images’ judges select the winner. Entrants agree to abide by the contest rules and the decisions of the contest judges, which are final. The selected entrants will be notified by email within four business days of the selection. If the selected entrants cannot be reached within five (5) business days, or if the selected entrants do not meet all of the contest rules, another entrant will be selected from the remaining eligible entries. The winning entrants of the prize must sign a standard Release form confirming compliance with the Contest Rules and releasing Yellow Images and its directors, officers, partnership interest holders, representatives, advertising and promotional agencies from any and all liability arising out of, pursuant to, or as a result of the carrying out of the contest including liability arising from the acceptance of and usage of the prizes as awarded. Failure to comply with the Contest Rules or to sign and return the Release form within 5 days of receipt will result in disqualification and another entry will be drawn.

    accordion-item
      template(
        #accordion-trigger
      )
        div Works
      div.rules-content
        p All works become the property of Yellow Images. Yellow Images assumes no responsibility for lost, stolen, delayed, damaged, or misdirected entries or for any failure of the website, for any problems or technical malfunction of any computer online systems, servers, access providers, computer equipment, software, failure of any e-mail or entry to be received by Yellow Images on account of technical problems or traffic congestion on the Internet or at any website, or any combination thereof including any injury or damage to an entrant's or any other person's computer related to or resulting from this contest.
        p Yellow Images reserves the right, at its sole discretion, to change, cancel or suspend this contest should a virus, bug, or other cause beyond the reasonable control of Yellow Images and its advertising and promotional agencies, corrupt the security or proper administration of the contest or for any other reason.
        p Entries are subject to verification and will be declared invalid if they are illegible, mechanically reproduced, mutilated, forged, falsified, altered, or tampered with or unlawful in any way. Entries submitted by unauthorized means or by spamming will be disqualified.
        p In the event that it has been determined that an entry has been made in a manner not sanctioned by these rules and/or an entrant has submitted more than the number of entries permitted by these rules, the entrant and all of his/her entries will be disqualified. Entrants agree to abide by the contest rules and the decisions of the contest judges, which are final. This contest is subject to all applicable laws and regulations.

    accordion-item
      template(
        #accordion-trigger
      )
        div Privacy
      div.rules-content
        p By entering and participating in the contest, entrants acknowledge that they are providing their personal information to Yellow Images. By entering the contest, entrants’ consent to the use of their <strong>names, city of residence, photograph, voice and/or image for publicity purposes in all media used by Yellow Images</strong>, without payment or compensation. Entrants agree to Yellow Images’ use of the entrants’ personal information that may include contacting you regarding limited time offers, promotional offers and/or surveys.

</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue'
import AccordionWrap from "@/components/accordion/accordion-wrap";
import AccordionItem from "@/components/accordion/accordion-item";
import {useHead} from "@vueuse/head";
import {ROUTE_HEAD} from "@/data/serverRoutersData";

export default {
  name: 'Rules',
  components: {
    AccordionItem,
    AccordionWrap,
    TheNavigation
  },
  setup () {
    useHead({
      title: ROUTE_HEAD.rules.title
    })
  }
  // data() {
  //   return {
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.h1-title {
  font-size: 28px;
  margin-bottom: 40px;
  letter-spacing: -0.93px;
  @include media-breakpoint-up(md) {
    font-size: 48px;
  }
}
.rules-content {
  max-width: 775px;
  :deep {
    p:last-child {
      margin-bottom: 0;
    }
    ul {
      list-style: disc;
      margin-bottom: 20px;
      padding-left: 16px;
    }
  }
}
</style>
