import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import common from './modules/common'
import auth from './modules/auth'
import likes from './modules/likes'
import catalog from './modules/catalog'
import textures from './modules/textures'
import projects from './modules/projects'
import activeProject from './modules/activeProject'
import projectHistory from './modules/projectHistory'

export default createStore({
  modules: {
    common,
    auth,
    likes,
    catalog,
    textures,
    projects,
    activeProject,
    projectHistory
  },
  plugins: [createPersistedState({
    paths: ['auth.user']
  })]
})
