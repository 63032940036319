//import * as THREE from './../../../../../../build/three.module.js';

export const windowresize = function(){

  const canvas = this.DOM.drawing.getElementsByTagName("canvas")[0];
  canvas.style.width = this.DOM.drawing.clientWidth+"px";
  canvas.style.height = this.DOM.drawing.clientHeight+"px";

  // window.app3dStats.canvas.push({
  //   width: this.DOM.drawing.clientWidth,
  //   height: this.DOM.drawing.clientHeight,
  // });

  this.camera.aspect = 
    this.DOM.drawing.clientWidth / 
    this.DOM.drawing.clientHeight;

  this.camera.updateProjectionMatrix();

  this.renderer.setSize(
    this.DOM.drawing.clientWidth,
    this.DOM.drawing.clientHeight
  );

};
