import {
  Group,
  AmbientLight,
  HemisphereLight,
  // HemisphereLightHelper,
  PointLight,
  // PointLightHelper,
  // CameraHelper,
} from './../../../../../build/three.module.js';

// добавление источников свете в сцену
export const addingLightsToScene = function(){

  const globalLights = new Group();
  globalLights.name = 'globalLights';

  const dataLights = this.dataScene.scene.globalLights;
  const alData = dataLights.find(light => light.type === 'AmbientLight');
  const hslData = dataLights.find(light => light.type === 'HemisphereLight');

  const ambientLight = new AmbientLight(alData.color, alData.intensity); // soft white light
  ambientLight.name = 'AmbientLight';
  ambientLight.position.x = alData.position.x;
  ambientLight.position.y = alData.position.y;
  ambientLight.position.z = alData.position.z;
  globalLights.add(ambientLight);

  const hemisphereLight = new HemisphereLight(hslData.skycolor, hslData.groundColor, hslData.intensity);
  hemisphereLight.name = 'HemisphereLight';
  hemisphereLight.position.x = hslData.position.x;
  hemisphereLight.position.y = hslData.position.y;
  hemisphereLight.position.z = hslData.position.z;
  globalLights.add(hemisphereLight);
  //if(this.dataQuery && this.dataQuery.dev){
  //  const hemisphereLightHelper = new HemisphereLightHelper(hemisphereLight, .3, 0x222222);
  //  globalLights.add(hemisphereLightHelper);
  //}

  const pointLight = new PointLight(0xffffff, 6, 100);
  pointLight.name = 'PointLight';
  pointLight.position.set(4, 4, 4);
  pointLight.castShadow = false; //true;
  pointLight.power = 300;
  pointLight.intensity = 6;
  pointLight.decay = 1;
  pointLight.shadow.mapSize.width = 1024; // default
  pointLight.shadow.mapSize.height = 1024; // default
  pointLight.shadow.camera.near = 0.5; // default
  pointLight.shadow.camera.far = 500; // default
  globalLights.add(pointLight);
  /*
  if(this.dataQuery && this.dataQuery.dev){
    const pointLightHelper = new PointLightHelper(pointLight, .3, 0x222222);
    globalLights.add(pointLightHelper);
    const helperCH = new CameraHelper(pointLight.shadow.camera);
    //  globalLights.add(helperCH);
  }
  */

 this.scene.add(globalLights);

};
