<template lang="pug">
.project-scene(
  id="project-scene"
  @mouseup="$store.commit('activeProject/setCameraPosition')"
  @dblclick="dblclickOnProject"
  :class="{'project-scene_view-mode': !$store.state.activeProject.editAccess || $store.state.activeProject.project.state !== 0}"
)
</template>

<script>

export default {
  name: 'vizScene',
  methods: {
    dblclickOnProject() {
      const hoverMaterial = this.$store.state.activeProject.activeVariation.sceneInstance.api.getMeshHoverMaterial()
      if (hoverMaterial.id) {
        const materialIndex = _.findIndex(this.$store.state.activeProject.activeVariation.scene.materials, ['id', hoverMaterial.id])
        if (materialIndex !== -1) {
          this.$store.commit('activeProject/changeActiveMaterial', materialIndex)
          if (this.$store.state.activeProject.activeMaterial.layers.length > 1) {
            this.$store.commit('activeProject/changeActiveLayer', 1)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.project-scene {
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  @include media-breakpoint-up(xl) {
    width: calc(100% + 170px);
    left: -170px;
  }

  &_view-mode {
    @include media-breakpoint-down(xl) {
      left: -400px;
      width: calc(100% + 400px);
    }
    @include media-breakpoint-down(lg) {
      left: 0;
      width: 100%;
      top: -230px;
      height: calc(100% + 230px);
    }
    @include media-breakpoint-down(md) {
      top: -190px;
      height: calc(100% + 190px);
    }
  }

  &:deep {
    .drawing {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
