const state = () => ({
  items: []
})

const getters = {}

const actions = {
  getCatalog ({ commit }) {
    this.$http.get('/v1/showcase/products')
      .then(response => {
        commit('SET_CATALOG', response.data.body.items)
      })
  }
}

const mutations = {
  SET_CATALOG (state, catalogItems) {
    state.items = catalogItems
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
