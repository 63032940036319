<template lang="pug">
viz-input-design
  .design-uploader-btn
    img.design-uploader-image(
      v-if="designLayer && designLayer.settings.designMap && designPreview"
      :src="designPreview"
      :alt="designLayer.name"
    )
</template>

<script>
import VizInputDesign from "@/components/player/vizInputDesign";
import isBase64 from "@/functions/isBase64";
import getImageLink from "@/functions/getImageLink";
export default {
  name: "vizMobileDesignUploader",
  components: {VizInputDesign},
  computed: {
    designLayer () {
      return this.$store.state.activeProject.activeMaterial.layers[1]
    },
    designPreview () {
      if (!isBase64(this.designLayer.settings.designMap.url)) {
        return getImageLink(this.designLayer.settings.designMap.url, '@2', 'webp')
      } else {
        return this.designLayer.settings.designMap.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.design-uploader-btn {
  padding: 0;
  width: 56px;
  height: 56px;
  border-radius: 16px;
  background: $white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.design-uploader-image {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
