<template lang="pug">
label.toggle-btn-label
  input(
    type="checkbox"
    v-model="modelValue"
    @change="$emit('update:modelValue', $event.target.checked)"
  )
  .toggle-btn
</template>

<script>
export default {
  name: "ToggleBtn",
  props: {
    modelValue: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"]
}
</script>

<style lang="scss" scoped>
.toggle-btn-label {
  display: block;
  input {
    display: none;
  }
  input:checked + .toggle-btn {
    background: $blue;
    &::before {
      left: 27px;
    }

    @include media-breakpoint-up(xl) {
      &::before {
        left: 24px;
      }
    }
  }
}
.toggle-btn {
  cursor: pointer;
  width: 56px;
  height: 32px;
  background: #E2E5E9;
  position: relative;
  border-radius: 100px;
  transition: .2s ease;
  &::before {
    content: '';
    width: 26px;
    height: 26px;
    display: block;
    position: absolute;
    left: 3px;
    top: 3px;
    background: $white;
    border-radius: 50%;
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.10);
    transition: .2s ease;
  }
  @include media-breakpoint-up(xl) {
    width: 46px;
    height: 24px;
    &::before {
      width: 20px;
      height: 20px;
      left: 2px;
      top: 2px;
    }
  }
}
</style>
