<template lang="pug">
.controller(
  :class="{'controller_active': isActive}"
)
  .controller__container
    .controller__name {{ name }}
    .controller__value-wrap
      slot
</template>

<script>
export default {
  name: 'vizCardController',

  props: {
    name: String,
    isActive: Boolean
  }
}
</script>

<style lang="scss" scoped>

  .controller {
    cursor: pointer;
    @include media-breakpoint-up(xl) {
      background: $gray;
      border-radius: 3px;
      padding: 7px 20px;
      margin-top: 2px;
      &:first-child {
        margin-top: 0;
      }
    }

    &_active {
      @include media-breakpoint-up(xl) {
        background: $blue;
      }
    }

    &__name {
      font-weight: bold;
      letter-spacing: -0.419048px;
      color: $black;
      margin-right: 20px;
      @include media-breakpoint-up(xl) {
        font-size: 11px;
        line-height: 13px;
        margin-right: 0;
      }
    }

    &_active &__name {
      @include media-breakpoint-up(xl) {
        color: $white;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>
