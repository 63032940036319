import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { VueCookieNext } from 'vue-cookie-next'

import { Vue3Mq } from 'vue3-mq'
import FloatingVue from 'floating-vue'
import VueFinalModal from 'vue-final-modal'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toaster from '@meforma/vue-toaster'
import VueSocialSharing from 'vue-social-sharing'
import VueHammer from '@/plugins/VueHammer'
import MasonryWall from '@yeger/vue-masonry-wall'
import Sniffr from '@/plugins/sniffr'
import AOS from "aos"
import { createHead } from '@vueuse/head'

import isBase64 from "@/functions/isBase64"
import 'floating-vue/dist/style.css'
import 'aos/dist/aos.css'

/* jQuery */
global.jQuery = require('jquery')
window.$ = global.jQuery

/* Lodash */
// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'
global._ = require('lodash')
window._ = global._

Image.prototype.load = function(url, onProgress){
  const thisImg = this,
        xhr = new XMLHttpRequest()

  thisImg.completedPercentage = 0;

  if (url && !isBase64(url)) {
    const urlHashStart = url.indexOf('?')
    if(urlHashStart === -1) {
      url += '?_'
    } else {
      url = url.slice(0, urlHashStart) + '?_'
    }
  }


  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer'
  // xhr.withCredentials = true;

  xhr.onload = function(e) {
    var h = xhr.getAllResponseHeaders(),
    m = h.match( /^Content-Type\:\s*(.*?)$/mi ),
    mimeType = m[ 1 ] || 'image/png';
    // Remove your progress bar or whatever here. Load is done.

    var blob = new Blob( [ this.response ], { type: mimeType } );
    thisImg.src = window.URL.createObjectURL( blob );
    // if ( callback ) callback( this );
  }
  xhr.onprogress = function(e) {
    if ( e.lengthComputable ) {
      thisImg.completedPercentage = parseInt( ( e.loaded / e.total ) * 100 );
    }

    if (onProgress) onProgress(thisImg.completedPercentage)
  }
  xhr.onloadstart = function() {
    // Display your progress bar here, starting at 0
    thisImg.completedPercentage = 0;
  };

  xhr.onloadend = function() {
    // You can also remove your progress bar here, if you like.
    thisImg.completedPercentage = 100;
  }
  xhr.send()
}

Image.prototype.completedPercentage = 0

/* 3d viewer library */
import lib3d from './plugins/lib3d.js'

/* SVG icons */
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'

/* App init */
const app = createApp(App)

app.AOS = new AOS.init({
  anchorPlacement: 'center-bottom',
  duration: 500,
  once: true,
  startEvent: 'load'
})

app.use(AOS)

app.use(store)
app.use(router)
app.use(lib3d)
app.use(VueFinalModal)
app.use(PerfectScrollbar)
app.use(VueSocialSharing)
app.use(MasonryWall)
app.use(Sniffr)

const head = createHead()
app.use(head)

app.use(VueHammer, {
  threshold: 40
})

app.use(Toaster, {
  position: 'top',
  duration: 3000,
  useDefaultCss: false
}).provide('toast', app.config.globalProperties.$toast)

app.use(FloatingVue, {
  themes: {
    'dropdown-menu': {
      $extend: 'dropdown',
      placement: 'bottom-end',
      distance: -24,
      $resetCss: true,
      popperHideTriggers: ['click']
    },
    'hint': {
      $extend: 'tooltip',
      '$resetCss': true,
      distance: 14
    },
    'simple-title': {
      $extend: 'hint',
      distance: 4,
      placement: 'top',
      delay: {
        show: 800,
        hide: 0
      }
    }
  }
})
app.use(Vue3Mq, {
  breakpoints: {
    xxs: 0,
    xs: 480,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1628
  }
})

app.use(VueSvgIconPlugin, {tagName: 'BaseIcon'})

app.use(VueAxios, axios)
VueCookieNext.config({
  expire: Infinity,
  secure: true,
  sameSite: 'Lax',
})

store.$http = axios
store.$cookie = VueCookieNext
router.$cookie = VueCookieNext
app.use(VueCookieNext)

// axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
const userToken = VueCookieNext.getCookie('userToken')
if (userToken) {
  axios.defaults.headers.common['Authorization'] = userToken
}

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch('auth/LogOut')
    }

    if (error.response.status !== 409) {
      app.$toast.error(error.message)
    }

    // switch (error.response.status) {
    //   case 409:
    //     if (error.response?.data?.error?.code !== "CONTEST_LIMIT") {
    //       router.push("/").then(() => {
    //         app.$toast.error('Access denied')
    //       })
    //     }
    //     break
    //   default:
    //     app.$toast.error(error.message)
    //     break
    // }

    return Promise.reject(error)
  }
)


app.mount('#app')
