<template lang="pug">
.container
  .row
    .col-sm-2.d-flex(
      v-for="item in catalog"
      :key="item.id"
    )
      .catalog-card
        .catalog-card__title {{ item.name }}
        img.catalog-card__image(
          v-if="item.cover"
          :src='item.cover'
          :alt="item.name"
        )
        BaseButton(
          :to='`/c/${item.id}`'
        ) Create project
        .text-center
          span.catalog-card__copy-link(
            @click="copyProjectLink(item.id)"
          ) Copy product link
</template>

<script>
import BaseButton from "@/components/BaseButton";
export default {
  name: "Catalog",
  inject: ["mq"],
  components: { BaseButton },

  data () {
    return {
      currentDomain: ''
    }
  },

  computed: {
    catalog () {
      return this.$store.state.catalog.items
    }
  },

  created () {
    const url = window.location.href
    let domain = (new URL(url))

    this.currentDomain = `${domain.protocol}//${domain.hostname}`
  },

  mounted () {
    this.$store.dispatch('catalog/getCatalog')
  },

  methods: {
    copyProjectLink (productId) {
      let tempInput = document.createElement('input')
      tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
      tempInput.value = `${this.currentDomain}/c/${productId}`
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.$emit('onCopyUrl')

      this.$toast.success('Link copied')
    }
  }
}
</script>

<style lang="scss" scoped>

.catalog-card {
  background: $gray;
  border-radius: 8px;
  padding: 23px 10px;
  margin: 0 0 20px 0;
  transition: .4s, color .2s;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  &__title {
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: auto;
  }
  &__copy-link {
    margin-top: 5px;
    display: inline-block;
    transition: 0.2s;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    &:hover {
      color: $blue;
    }
  }
  &__image {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .button {
    width: 100%;
  }
}
</style>
