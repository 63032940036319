function getMeshHoverMaterial(){
  const _this = this;
  if(_this.hoverObject.object){
    const mesh = _this.dataScene.scene.models[0].meshes.find(mesh => mesh.meshName === _this.hoverObject.object);
    const materialId = mesh.materialId || null; 
    if(materialId){
      const material = _this.dataScene.scene.materials.find(mtl => mtl.id === materialId);
      return {
        id: material.id,
        name: material.name,
      };
    }else{
      return {error: 'no find materialId'};
    }
  }else{
    return {error: 'no hover object'};
  }
}

export {
  getMeshHoverMaterial,
};