<template lang="pug">
viz-card(
  title="PSD template"
)
  template(
    #headAfter
  )
    BaseIcon.show-more-btn(
      data="@icon/chevron_up.svg"
      width="18"
      height="18"
      :original="true"
      @click="showContent = !showContent"
      :class="{'disable': !showContent}"
    )

  transition(
    name="template-slide-down"
    @enter="start"
    @after-enter="end"
    @before-leave="start"
    @after-leave="end"
  )
    .template-slide-down(
      v-show="showContent"
    )
      .template-slide-down__text To make it easier for you to create your own unique design, we have prepared a template for you.

  base-button.template-btn(
    :href="$store.getters['activeProject/getImageById']($store.state.activeProject.activeMaterial.templateDesignPSD)"
    download
  ) Download template
</template>

<script>
import VizCard from "@/components/player/vizCard";
import BaseButton from "@/components/BaseButton";
export default {
  name: "vizPsdTemplate",
  components: {BaseButton, VizCard},
  data () {
    return {
      showContent: true
    }
  },
  methods: {
    start (el) {
      el.style.height = el.scrollHeight + "px"
    },
    end (el) {
      el.style.height = ""
    }
  }
}
</script>

<style lang="scss" scoped>
.template-btn {
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
  letter-spacing: -0.42px;
}
.show-more-btn {
  margin-left: auto;
  cursor: pointer;
  transition: 0.3s ease;
  &.disable {
    transform: rotate(180deg);
  }
}

.template-slide-down {
  &__text {
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 12px;
    color: #656565;
    letter-spacing: -0.4px;
    line-height: 16px;
  }

  &-enter-active,
  &-leave-active {
    will-change: height, opacity;
    transition: height .3s ease, opacity .3s ease;
    overflow: hidden;
  }

  &-enter-from,
  &-leave-to {
    height: 0 !important;
    opacity: 0;
  }
}


</style>
