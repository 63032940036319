import { createToaster } from '@meforma/vue-toaster'

const STATE_SUCCESS_ANSWER = {
  state_0: 'Duck sent to draft',
  state_2: 'Duck accepted',
  state_3: 'Duck rejected'
}

const toaster = createToaster({
  duration: 3000,
  useDefaultCss: false
})

const state = {
}

const getters = {
}

const actions = {
  changeProjectName ({ commit, rootState }, { projectId, name }) {
    commit('projects/changeProjectName', { projectId, name }, {root: true})
    if (rootState.activeProject.project) {
      commit('activeProject/changeProjectName', name, {root: true})
    }
  },

  async onChangeState ({ commit }, { projectId, newState, isSelected, removeSelected, message }) {
    if (isSelected) {
      await this.$http.post(`/v1/manage/projects/${projectId}/action/contest_public`).then(() => {
        toaster.success('Duck selected')
      })
    } else if (removeSelected) {
      await this.$http.post(`/v1/manage/projects/${projectId}/action/contest_public_remove`).then(() => {
        toaster.success('Duck unselected')
      })
    } else {
      const moderateData = {
        to_state: newState
      }
      if (message) {
        moderateData.message = message
      }

      await this.$http.post(`/v1/manage/projects/${projectId}/action/contest_moderate`, JSON.stringify(moderateData)).then(() => {
        toaster.success(STATE_SUCCESS_ANSWER['state_' + newState])
      })
    }

    commit('projects/changeProjectState', { projectId, newState, isSelected }, { root: true })
    commit('activeProject/changeActiveProjectState', { projectId, newState, isSelected }, { root: true })
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
