<template lang="pug">
.viz-color-picker
  sketch(
    v-model="currentColor"
    :preset-colors="presetColors"
  )
</template>

<script>
import {Sketch} from '@ckpack/vue-color'

export default {
  name: 'vizColorPicker',

  components: { Sketch },

  data () {
    return {
      debounce: null,
      presetColors: ['#F72685', '#B5179E', '#720AB7', '#560BAD', '#480DA8', '#3A0CA3',
        '#3F37C9', '#4361EE', '#4895EF', '#4DC9F0', '#00B0FF',
        '#17FFFF', '#00B8D4', '#01BFA5', '#00C853', '#1B5C20', '#75FF02',
        '#C6FF00', '#FFFF02', '#FFAB01', '#FF6D01', '#FF3D01',
        '#9B5DE5', '#F15AB5', '#FEE440', '#00BBF9', '#04F5D4', '#000000',
        '#616161', '#9E9E9E', '#BDBDBD', '#EEEEEE', '#FFFFFF'],
      currentColor: _.get(this.$store.state.activeProject.activeLayer.settings, 'texture.color').value
    }
  },

  computed: {
    materialId () {
      return this.$store.state.activeProject.activeMaterial.id
    },
    layerIndex () {
      return this.$store.state.activeProject.activeMaterial.activeLayerIndex
    }
  },

  watch: {
    currentColor () {
      this.$store.commit('activeProject/layerSettingsLocalChange', {
        settingsPath: 'texture.color',
        value: this.currentColor.hex
      })
      this.$store.commit('activeProject/adjustmentChange', {
        method: 'setLayerColor',
        args: [this.materialId, this.layerIndex, this.currentColor.hex]
      })

      if (this.debounce) clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.$store.commit('activeProject/addedUnsavedChanges')
        this.$store.dispatch('projectHistory/addSnapshot', this.$store.state.activeProject.activeVariation.scene)
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
  .viz-color-picker {
    &:deep {
      .vc-sketch {
        width: 100%;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
      }
      .vc-sketch-presets {
        border: none;
        margin: 0;
        padding: 0;
        &::before {
          content: 'Swatches';
          display: block;
          margin-bottom: 13px;
          font-weight: bold;
          font-size: 14px;
          line-height: 1;
          letter-spacing: -0.342857px;
          color: $black;
          @include media-breakpoint-up(xl) {
            font-size: 10px;
          }
        }
      }
      .vc-sketch-presets-color {
        border-radius: 0;
        box-shadow: none;
        width: 9.09%;
        height: auto;
        margin: 0;
        &::before {
          content: '';
          display: block;
          padding-top: 100%;
        }
        &:last-child {
          border: 1px solid #E2E5E9;
        }
      }
      .vc-sketch-alpha-wrap {
        display: none;
      }
      .vc-sketch-color-wrap {
        display: none;
      }
      .vc-sketch-sliders {
        padding: 0;
        margin: 14px 0;
      }
      .vc-sketch-hue-wrap {
        height: 4px;
      }
      .vc-hue-picker {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translateX(-4px);
        margin-top: -2px;
      }
      .vc-sketch-saturation-wrap {
        padding-bottom: 46%;
        border-radius: 3px;
        @include media-breakpoint-up(xl) {
          padding-bottom: 100%;
        }
      }
      .vc-saturation-circle {
        width: 5px;
        height: 5px;
        transform: translate(-2.5px, -2.5px);
      }
      .vc-sketch-field {
        display: none;

        @include media-breakpoint-up(xl) {
          padding: 0;
          display: block;
          .vc-sketch-field--single {
            display: none;
          }
          .vc-input__label {
            visibility: hidden;
            position: relative;
            padding: 0;
            text-align: left;
            width: 100%;
            &::after {
              content: 'Hexadecimal';
              visibility: visible;
              display: block;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .vc-input__input {
            width: 45px;
            padding: 0;
            box-shadow: none;
            flex-shrink: 0;
            background: inherit;
          }
          .vc-editable-input {
            display: flex;
            flex-direction: row-reverse;
            padding: 10px 6px;
            background: $gray;
            border-radius: 3px;
            margin-bottom: 14px;
            position: relative;
            &::after {
              content: '#';
              font-size: 10px;
              display: block;
              position: absolute;
              right: 52px;
              top: 10px;
            }
          }
        }
      }
    }
  }
</style>
