<template>
  <span :aria-hidden="!title"
        :aria-label="title"
        class="material-design-icon"
        role="img"
        v-bind="$attrs"
        @click="$emit('click', $event)">
    <svg
      :fill="fillColor"
      class="material-design-icon__svg"
      :width="size"
      :height="size"
      viewBox="0 0 28 28">
      <path d="M5.83333 2.33333H22.1667C23.45 2.33333 24.5 3.38333 24.5 4.66667V21C24.5 22.2833 23.45 23.3333 22.1667 23.3333H17.5L14 26.8333L10.5 23.3333H5.83333C4.53833 23.3333 3.5 22.2833 3.5 21V4.66667C3.5 3.38333 4.53833 2.33333 5.83333 2.33333ZM16.5317 21H22.1667V4.66667H5.83333V21H11.4683L12.145 21.6767L14 23.5317L15.8433 21.6883L16.5317 21ZM12.8333 19.8333V17.5H15.1667V19.8333H12.8333ZM16.3333 10.5C16.3333 9.21667 15.2833 8.16667 14 8.16667C12.7167 8.16667 11.6667 9.21667 11.6667 10.5H9.33333C9.33333 7.92167 11.4217 5.83333 14 5.83333C16.5783 5.83333 18.6667 7.92167 18.6667 10.5C18.6667 11.9967 17.745 12.8022 16.8476 13.5865C15.9962 14.3305 15.1667 15.0554 15.1667 16.3333H12.8333C12.8333 14.2085 13.9325 13.366 14.8989 12.6253C15.6569 12.0442 16.3333 11.5258 16.3333 10.5Z">
        <title v-if="title">{{ title }}</title>
      </path>
      </svg>
  </span>
</template>

<script>
export default {
  name: "LiveHelp",
  props: {
    title: {
      type: String,
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>
