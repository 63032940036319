<template lang="pug">
.scene
  .scene-wrap(
    :class="{'scene-wrap_hints-open': isHintsOpen}"
  )
    transition(name="scene__items")
      div.scene__items(
        :class="{'scene__items_is-saving': isSaving}"
        v-if="!loading"
      )
        viz-scene.scene__wrap-3d(
          :class="isProjectInitializing ? 'scene__wrap-3d_hide' : 'scene__wrap-3d_vis'"
        )
        viz-header

        mq-responsive(
          target="xl+"
        )
          viz-menu
          viz-settings(
            v-if="editAccess && project.state === 0"
          )
          viz-view-player-settings(
            v-else
          )

        mq-responsive(
          target="lg-"
        )
          template(
            v-if="editAccess && project.state === 0"
          )
            viz-settings-ipad
            viz-mobile-footer
          viz-mobile-view-player(
            v-else
          )



    transition(name="project-loader-wrap")
      .project-loader(
        v-if="isProjectInitializing || isStaticLoader"
        :class="{'project-loader_hide-bg': isStaticLoader}"
      )
        .project-loader__card(
          :class="{'project-loader__card_static': isStaticLoader}"
        )
          .project-loader__icon
          .project-loader__text-wrap(
            v-if="isSaving"
          )
            .project-loader__text Saving project
          .project-loader__text-wrap(
            v-else-if="screenshotOnProgress"
          )
            .project-loader__text Creating screenshot
          .project-loader__text-wrap(
            v-for="(text, index) in preloaderText"
            v-else
          )
            transition(name="project-loader")
              .project-loader__text(
                v-if="index === preloaderTextIndex"
              ) {{ text }}
    has-unsaved-changes(
      :next="next"
    )
</template>

<script>
import VizScene from '../components/player/vizScene'
import VizSettings from '../components/player/vizSettings'
import VizHeader from "../components/player/vizHeader";
import VizMenu from "../components/player/vizMenu";
import VizViewPlayerSettings from "../components/player/vizViewPlayerSettings";
import {$vfm} from "vue-final-modal";
import HasUnsavedChanges from "@/components/modals/HasUnsavedChanges";
import { MqResponsive } from 'vue3-mq'
import VizSettingsIpad from "@/components/player/vizSettingsIpad";
import VizMobileFooter from "@/components/player/vizMobileFooter";
import VizMobileViewPlayer from "@/components/player/vizMobileViewPlayer";
import PageNotFound from "@/views/PageNotFound"
import {useHead} from "@vueuse/head";
import {ROUTE_HEAD} from "@/data/serverRoutersData";
export default {
  name: 'VizPlayer',

  inject: ["mq"],

  components: {
    VizMobileViewPlayer,
    VizMobileFooter,
    VizSettingsIpad, HasUnsavedChanges, VizViewPlayerSettings, VizMenu, VizHeader, VizSettings, VizScene, MqResponsive },

  data () {
    return {
      next: function () {
        console.log('empty next function')
      },
      preloaderText: [
        'Creating scene',
        'Prepare materials',
        'Almost done'
      ],
      preloaderTextIndex: 0,
      timerId: null
    }
  },

  computed: {
    isHintsOpen () {
      const hints = this.$store.state.activeProject.isHintsOpen

      if (hints) {
        setTimeout(() => {
          document.addEventListener('click', this.hideHints)
        }, 300)
      } else {
        document.removeEventListener('click', this.hideHints)
      }

      return hints
    },
    project () {
      return this.$store.state.activeProject.project
    },
    loading () {
      return !this.project?.id
    },
    editAccess () {
      return this.$store.state.activeProject.editAccess
    },
    isProjectInitializing () {
      const isProjectLoading = this.$store.state.activeProject.isProjectLoading

      if (isProjectLoading) {
        this.timerId = setInterval(() => {
          this.preloaderTextIndex = this.preloaderTextIndex !== 2 ? this.preloaderTextIndex + 1 : 0
        }, 3000);
      } else {
        clearInterval(this.timerId)
      }

      return isProjectLoading
    },
    isSaving () {
      return this.$store.state.activeProject.saveOnProgress
    },
    screenshotOnProgress () {
      return this.$store.state.activeProject.screenshotOnProgress
    },
    isStaticLoader () {
      return !!(this.isSaving || this.screenshotOnProgress)
    }
  },

  created () {
    this.setUpProject(this.$route.params.id)
  },

  beforeRouteUpdate (to, from, next) {
    console.log('beforeRouteUpdate')
    next()
    this.setUpProject(to.params.id)
  },

  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave', to)
    if (this.$store.state.activeProject.hasUnsavedChanges) {
      this.next = next
      $vfm.show('project-unsaved')
    } else {
      this.$store.commit('activeProject/clearSceneInstance')
      next()
    }
  },

  mounted () {
    document.addEventListener('keyup', this.keyupHistory)
    window.addEventListener('resize', this.changeAccessOnResize)

    window.addEventListener('beforeunload', (e) => {
      const isDownloadLink = e.target.activeElement.hasAttribute('download')
      if (this.$store.state.activeProject.hasUnsavedChanges && this.$route.matched[0]?.components?.default?.name === 'VizPlayer' && !isDownloadLink) {
        e = e || window.event

        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = 'Are you sure you want to leave the page? Unsaved changes may be lost.'
        }

        // For Safari
        return 'Are you sure you want to leave the page? Unsaved changes may be lost.'
      }
    })
  },

  destroyed () {
    document.removeEventListener('keyup', this.keyupHistory)
    window.removeEventListener('resize', this.changeAccessOnResize)
  },

  methods: {
    hideHints () {
      this.$store.commit('activeProject/hideHints')
    },
    changeAccessOnResize () {
      if (this.project) {
        if (this.$store.getters['auth/user']?.yid === this.project.owner.yid && this.mq.mdPlus) {
          this.$store.commit('activeProject/changeAccess', true)
        } else {
          this.$store.commit('activeProject/changeAccess', false)
        }
      }
    },
    keyupHistory (event) {
      if (event.ctrlKey && event.code === 'KeyZ') {
        this.$store.dispatch('projectHistory/snapshotBack')
      } else if (event.ctrlKey && event.code === 'KeyY') {
        this.$store.dispatch('projectHistory/snapshotForward')
      }
    },
    async setUpProject (projectId) {
      if (this.$sniffr.browser.name === 'safari' && this.$sniffr.browser.version[0] < 14) {
        this.$router.replace('/')
        this.$vfm.show('old-browser')
        return
      }
      let projectContestId
      this.$store.commit('activeProject/startProjectLoading')
      this.$store.commit('activeProject/clearActiveProject')
      this.$store.commit('projectHistory/clearHistory')
      await this.$http(`/v1/showcase/projects/${projectId}`)
        .then(response => {
          projectContestId = response.data.body.contest_id
          this.$store.commit('activeProject/onProjectOpen', response.data.body)
          if (this.$store.getters['auth/user']?.yid === this.project.owner.yid && this.mq.mdPlus) {
            this.$store.commit('activeProject/changeAccess', true)
          }
        })
        .catch(() => {
          this.$router.replace({ path: '/404', component: PageNotFound })
        })

      const texturesProjectId = projectContestId === 1 ? projectId : null

      await this.$store.dispatch('textures/getTextureCatalog', texturesProjectId).then((result) => {
        const allTextures = this.$store.getters['textures/getAllProjectTextures']
        this.$store.commit('activeProject/embed3dVisualization', allTextures)
        this.$store.dispatch('activeProject/setProjectMaterialIcons')
      })

      this.$store.dispatch('textures/getMaterialNames')
    }
  },
  setup () {
    useHead({
      title: ROUTE_HEAD.duckPage.title
    })
  }
}
</script>

<style lang="scss" scoped>
  .scene {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    @include media-breakpoint-down(xl) {
      background: $gray;
    }
    @include media-breakpoint-up(xl) {
      padding: 0 16px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0 62px;
    }

    &__items {
      width: 100%;
      height: 100%;
      &-enter-active {
        transition: 0.3s ease;
      }
      &-enter-from {
        opacity: 0;
      }
      &_is-saving {
        transition: 0.3s ease;
        opacity: 0.3;
        pointer-events: none;
      }
    }
    &__wrap-3d {
      transition: 0.3s ease;
      opacity: 0;
      transform: scale(0.95);
      &_vis {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .scene-wrap {
    height: 100%;
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      z-index: 2;
      background: rgba(245, 245, 247, 0.6);
      left: 0;
      top: 0;
      position: absolute;
      transition: 0.3s;
    }
    &_hints-open::before {
      opacity: 1;
      pointer-events: auto;
    }
    @include media-breakpoint-up(xl) {
      background: #F6F6F8;
      border-radius: 13px;
    }
  }
  .project-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    background: rgba(245, 245, 247, 0.9);
    left: 0;
    top: 0;
    &_hide-bg {
      background: none;
      pointer-events: none;
    }
    &__card {
      padding: 19px 24px;
      border-radius: 16px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
      background-color: $white;
      width: 200px;
      display: flex;
      align-items: center;
      &_static {
        width: auto;
        .project-loader__text {
          position: static;
        }
      }
    }
    &__icon {
      display: block;
      width: 24px;
      height: 24px;
      position: relative;
      animation: loading 1s linear infinite;
      margin-right: 8px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: conic-gradient(from 0.37turn, #ffffff, #4494fe 0.18turn, #4040ff 0.29turn, #0036ff 0.39turn, #4040ff 0.53turn, #4494fe 0.65turn, #ffffff 0.8turn, #ffffff);
        border-radius: 50%;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        background: $white;
        border-radius: 50%;
        transform: scale(0.75);
      }
    }
    &__text-wrap {
      position: relative;
      height: 14px;
    }
    &__text {
      position: absolute;
      left: 0;
      top: 0;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1;
      font-weight: bold;
      letter-spacing: -0.4px;
    }
    &-wrap {
      &-leave-active {
        transition: 0.5s ease;
      }
      &-leave-to {
        opacity: 0;
      }
    }

    &-enter-active,
    &-leave-active {
      transition: transform 0.5s, opacity 0.3s;
    }
    &-enter-from {
      opacity: 0;
      transform: translateY(18px);
    }
    &-leave-to {
      opacity: 0;
      transform: translateY(-18px);
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
