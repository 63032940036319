import {setLayerColor} from './functions/setLayerColor/index.js';
import {setTextureMapOpacity} from './functions/setTextureMap/index.js';
import {setDesignMap} from './functions/setDesignMap/index.js';
import {addLayer} from './functions/addLayer/index.js';
import {removeLayer} from './functions/removeLayer/index.js';
import {updateDesignImage} from './functions/updateDesignImage/index.js';
import {setReflectivity} from './functions/setReflectivity/index.js';
import {setReflectivityTexture} from './functions/setReflectivityTexture/index.js';
import {setMetalness} from './functions/setMetalness/index.js';
import {setMetalnessTexture} from './functions/setMetalnessTexture/index.js';
import {setRoughness} from './functions/setRoughness/index.js';
import {setRoughnessTexture} from './functions/setRoughnessTexture/index.js';
import {setNormalMap} from './functions/setNormalMap/index.js';
import {setTransformDesign} from './functions/setTransformDesign/index.js';
import {
  setTransformTexture,
  setSizeTexture,
  setRotationTexture,
} from './functions/setTransformTexture/index.js';
import {setVisibleLayer} from './functions/setVisibleLayer/index.js';
import {setTexture} from './functions/setTexture/index.js';

import {getScreenshot} from './functions/getScreenshot/index.js';
import {getCameraPosition} from './functions/getCameraPosition/index.js';
import {setCameraPosition} from './functions/setCameraPosition/index.js';

import {setAOTextureOpacity} from './functions/setAOTextureOpacity/index.js';

import {rerenderScene} from './functions/rerenderScene/index.js';
import {getMeshHoverMaterial} from './functions/getMeshHoverMaterial/index.js';

import {closeVis3D} from './functions/closeVis3D/index.js';

export const api = function(){

  this.api = {};

  // установка фонового цвета слоя
  this.api.setLayerColor = setLayerColor.bind(this);

  this.api.addLayer = addLayer.bind(this);
  this.api.removeLayer = removeLayer.bind(this);
  this.api.updateDesignImage = updateDesignImage.bind(this);

  this.api.setTextureMapOpacity = setTextureMapOpacity.bind(this);

  this.api.setDesignMap = setDesignMap.bind(this);

  this.api.setReflectivity = setReflectivity.bind(this);
  this.api.setReflectivityTexture = setReflectivityTexture.bind(this);

  this.api.setMetalness = setMetalness.bind(this);
  this.api.setMetalnessTexture = setMetalnessTexture.bind(this);

  this.api.setRoughness = setRoughness.bind(this);
  this.api.setRoughnessTexture = setRoughnessTexture.bind(this);

  this.api.setNormalMap = setNormalMap.bind(this);

  this.api.setTransformDesign = setTransformDesign.bind(this);
  this.api.setSizeTexture = setSizeTexture.bind(this);
  this.api.setRotationTexture = setRotationTexture.bind(this);

  this.api.setTransformTexture = setTransformTexture.bind(this);
  this.api.setVisibleLayer = setVisibleLayer.bind(this);
  this.api.getScreenshot = getScreenshot.bind(this);
  this.api.getCameraPosition = getCameraPosition.bind(this);
  this.api.setCameraPosition = setCameraPosition.bind(this);

  this.api.setAOTextureOpacity = setAOTextureOpacity.bind(this);

  this.api.setTexture = setTexture.bind(this);
  this.api.rerenderScene = rerenderScene.bind(this);

  this.api.getMeshHoverMaterial = getMeshHoverMaterial.bind(this);

  this.api.closeVis3D = closeVis3D.bind(this);

};
